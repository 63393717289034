export const getTrimmedData = (formId) => {
  const formElement = document.getElementById(formId);
  const formData = new FormData(formElement);
  const formDataArray = Array.from(formData.entries());

  const trimmedFormData = new FormData();
  formDataArray.map(([key, value]) => trimmedFormData.append(key, value.trim()));

  return Object.fromEntries(trimmedFormData);
};
