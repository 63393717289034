import React from 'react';

const Footer = () => {
  return (
    <footer className="block py-4">
      <div className="container mx-auto px-4">
        <hr className="mb-4 border-b-1 border-blueGray-200" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4">
            <div className="text-sm text-blueGray-500 font-semibold py-1 text-center md:text-left flex">
              {`© ${new Date().getFullYear()} `}
              <p className="text-blueGray-500 text-sm font-semibold ml-2">Garažų eksploatavimo bendrija Eismas</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
