import React from 'react';
import HeaderCell from '../../table/HeaderCell';

const UtilitiesTableHeader = () => {
  return (
    <thead>
      <tr>
        <HeaderCell>Data</HeaderCell>
        <HeaderCell>Nuo</HeaderCell>
        <HeaderCell>Iki</HeaderCell>
        <HeaderCell>Skirtumas</HeaderCell>
        <HeaderCell>Suma</HeaderCell>
        <HeaderCell customWrap={'whitespace-normal'}>Mokėjimo įskaitymas</HeaderCell>
      </tr>
    </thead>
  );
};

export default UtilitiesTableHeader;
