import Parse from 'parse';
import { changePassword, getCurrentUser, insertUser, removeUser } from './userService';
import { ELECTRICITY, WATER } from '../consts/types';
import { fetchAllRecords } from './queryHandler';
import { CLIENTS, TAXES } from '../consts/tables';
import { getRoleByName } from './rolesService';
import { ADMIN_ROLE } from '../consts/roles';
import { getUserByUsername } from './userService';

export const getClient = async (id) => {
  const user = await getCurrentUser();
  const objectId = id || user.data.objectId;
  const client = await new Parse.Query(CLIENTS).get(objectId);
  return {
    id: client.id,
    garage: client.attributes.garage,
    squares: client.attributes.squares,
    owner: client.attributes.owner,
    address: client.attributes.address,
    phone: client.attributes.phone,
    email: client.attributes.email,
    withoutMeter: client.attributes.be_skaitliuko,
    memberSince: client.attributes.narys
  };
};

export const getAllClients = async (skip, pageSize, filters) => {
  const query = new Parse.Query(CLIENTS);
  if (filters.garageNumber) {
    query.equalTo('garage', filters.garageNumber);
  }

  if (filters.owner) {
    const containsPattern = new RegExp(filters.owner, 'i');
    query.matches('owner', containsPattern);
  }

  if (filters.electricityDebt) {
    const taxesQuery = new Parse.Query(TAXES).equalTo('type', ELECTRICITY).greaterThan('suma', 0).equalTo('status', 0);
    query.matchesKeyInQuery('objectId', 'owner', taxesQuery);
  }

  if (filters.waterDebt) {
    const taxesQuery = new Parse.Query(TAXES).equalTo('type', WATER).greaterThan('suma', 0).equalTo('status', 0);
    query.matchesKeyInQuery('objectId', 'owner', taxesQuery);
  }

  if (filters.member) {
    query.exists('narys');
  }

  if (filters.debtFrom) {
    query.greaterThan('totalDebt', filters.debtFrom);
  }

  const totalClients = await query.count();
  const clientsResult = await query.ascending('sort').skip(skip).limit(pageSize).find();
  const clients = await Promise.all(
    clientsResult.map(async (client) => {
      return {
        id: client.id,
        garage: client.attributes.garage,
        owner: client.attributes.owner,
        address: client.attributes.address,
        phone: client.attributes.phone,
        email: client.attributes.email,
        squares: client.attributes.squares,
        memberSince: client.attributes.narys,
        doesNotHaveMeter: client.attributes.be_skaitliuko,
        totalDebt: client.attributes.totalDebt,
        electricityDebt: client.attributes.electricityDebt,
        waterDebt: client.attributes.waterDebt
      };
    })
  );

  return { clients, totalClients };
};

export const getAllClientsForExport = async () => {
  const query = new Parse.Query(CLIENTS);
  const clients = await fetchAllRecords(query);

  return clients.map((client) => ({
    phone: client.attributes.phone,
    member: client.attributes.narys,
    id: client.id,
    owner: client.attributes.owner
  }));
};

export const getAllClientsForMessage = async () => {
  const query = new Parse.Query(CLIENTS);
  const clients = await fetchAllRecords(query);
  const usersWithDetails = await Promise.all(
    clients.map(async (user) => {
      const owner = await getUserByUsername(user.attributes.garage);
      return {
        id: user.id,
        garage: user.attributes.garage,
        owner: user.attributes.owner,
        userId: owner ? owner.id : null
      };
    })
  );
  return usersWithDetails;
};

export const getAllClientsWithUnconfirmedInformation = async (skip, pageSize) => {
  const query = Parse.Query.or(
    new Parse.Query(CLIENTS).notEqualTo('owner2', ''),
    new Parse.Query(CLIENTS).notEqualTo('address2', ''),
    new Parse.Query(CLIENTS).notEqualTo('phone2', ''),
    new Parse.Query(CLIENTS).notEqualTo('email2', '')
  );

  const totalClients = await query.count();
  const clientsResult = await query.ascending('sort').skip(skip).limit(pageSize).find();
  const clients = clientsResult.map((client) => {
    return {
      id: client.id,
      currentOwner: client.attributes.owner,
      currentAddress: client.attributes.address,
      currentPhone: client.attributes.phone,
      currentEmail: client.attributes.email,
      newOwner: client.attributes.owner2,
      newAddress: client.attributes.address2,
      newPhone: client.attributes.phone2,
      newEmail: client.attributes.email2
    };
  });

  return { clients, totalClients };
};

export const setNewClientInformationForApproval = async (clientData) => {
  let columnsUpdated = 0;
  const client = await new Parse.Query(CLIENTS).get(clientData.id);

  if (client.attributes.owner !== clientData.owner) {
    columnsUpdated++;
    client.set('owner2', clientData.owner.trim());
  }

  if (client.attributes.address !== clientData.address) {
    columnsUpdated++;
    client.set('address2', clientData.address.trim());
  }

  if (client.attributes.phone !== clientData.phone) {
    columnsUpdated++;
    client.set('phone2', clientData.phone.trim());
  }

  if (client.attributes.email !== clientData.email) {
    columnsUpdated++;
    client.set('email2', clientData.email.trim());
  }

  await client.save();

  if (clientData.password) {
    await changePassword(clientData.password);
  }

  return columnsUpdated;
};

export const updateClient = async (clientData) => {
  const client = await new Parse.Query(CLIENTS).get(clientData.id);

  client.set('owner', clientData.owner);
  client.set('address', clientData.address);
  client.set('phone', clientData.phone);
  client.set('email', clientData.email);
  client.set('squares', clientData.squares);
  client.set('be_skaitliuko', clientData.withoutMeter);
  client.set('narys', clientData.memberSince);

  await client.save();
};

export const removeClient = async (id) => {
  const query = new Parse.Query(CLIENTS);
  const client = await query.get(id);
  await removeUser(id);
  await client.destroy();
};

export const doesClientExist = async (id) => {
  const query = new Parse.Query(CLIENTS).equalTo('objectId', id);
  const client = await query.first();

  return client !== undefined;
};

export const insertClient = async (client) => {
  const ClientObject = Parse.Object.extend(CLIENTS);
  const newClient = new ClientObject();

  newClient.set('garage', client.garage);
  newClient.set('owner', client.owner);
  newClient.set('address', client.address);
  newClient.set('email', client.email);
  newClient.set('phone', client.phone);
  newClient.set('squares', client.squares);
  newClient.set('be_skaitliuko', client.withoutMeter);
  newClient.set('sort', client.sort);

  const userId = await insertUser(newClient);
  const administratorRole = await getRoleByName(ADMIN_ROLE);

  const newACL = new Parse.ACL();
  const user = await getUserByUsername(userId);
  if (user) {
    newACL.setReadAccess(user.id, true);
    newACL.setWriteAccess(user.id, false);
  }
  newACL.setRoleReadAccess(administratorRole, true);
  newACL.setRoleWriteAccess(administratorRole, true);
  newClient.setACL(newACL);

  await newClient.save();
};

export const anyClientsWithUnconfirmedInformation = async () => {
  const query = Parse.Query.or(
    new Parse.Query(CLIENTS).notEqualTo('owner2', ''),
    new Parse.Query(CLIENTS).notEqualTo('address2', ''),
    new Parse.Query(CLIENTS).notEqualTo('phone2', ''),
    new Parse.Query(CLIENTS).notEqualTo('email2', '')
  );

  const clientsWithUnconfirmedInfoCount = await query.count();
  return clientsWithUnconfirmedInfoCount > 0;
};

export const confirmInformation = async (clientId, column) => {
  const client = await new Parse.Query(CLIENTS).get(clientId);
  const newValue = client.get(`${column}2`);

  client.set(column, newValue);
  client.set(`${column}2`, '');
  await client.save();
};

export const rejectInformation = async (clientId, column) => {
  const client = await new Parse.Query(CLIENTS).get(clientId);

  client.set(`${column}2`, '');
  await client.save();
};
