import React, { useEffect, useState } from 'react';
import Modal from '../modal/Modal';
import ModalHeader from '../modal/ModalHeader';
import ModalBody from '../modal/ModalBody';
import ModalFooter from '../modal/ModalFooter';
import { SALE, BUY, RENT, OTHER } from '../../consts/postCategories';

const PostUpdateModal = ({ isOpen, post, closeModal, handlePostUpdate }) => {
  const [formData, setFormData] = useState({
    name: '',
    contact: '',
    message: ''
  });

  useEffect(() => {
    if (post) {
      setFormData({ ...post });
    }
  }, [post]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = () => {
    const clientData = {
      ...formData
    };
    handlePostUpdate(clientData);
  };

  if (!post) {
    return <></>;
  }

  return (
    isOpen && (
      <Modal>
        <ModalHeader>{`Skelbimo ID:${post.objectId} redagavimas`}</ModalHeader>
        <ModalBody>
          <div className="container mx-auto">
            <div className="flex flex-wrap mb-2">
              <div className="w-1/3 px-4 flex items-center justify-end">
                <label htmlFor="category" className="text-gray-700 text-sm mr-2">
                  Kategorija
                </label>
              </div>
              <div className="w-full px-4 flex-1 flex items-center justify-start">
                <select
                  id="category"
                  name="category"
                  value={formData.category || ''}
                  onChange={handleChange}
                  className={`px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full`}
                >
                  <option value={SALE}>{SALE}</option>
                  <option value={BUY}>{BUY}</option>
                  <option value={RENT}>{RENT}</option>
                  <option value={OTHER}>{OTHER}</option>
                </select>
              </div>
            </div>
            <div className="flex flex-wrap mb-2">
              <div className="w-1/3 px-4 flex items-center justify-end">
                <label htmlFor="name" className="text-gray-700 text-sm mr-2">
                  Savininkas
                </label>
              </div>
              <div className="w-full px-4 flex-1 flex items-center justify-start">
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name || ''}
                  onChange={handleChange}
                  className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                />
              </div>
            </div>
            <div className="flex flex-wrap mb-2">
              <div className="w-1/3 px-4 flex items-center justify-end">
                <label htmlFor="contact" className="text-gray-700 text-sm mr-2">
                  Kontaktai
                </label>
              </div>
              <div className="w-full px-4 flex-1 flex items-center justify-start">
                <input
                  type="text"
                  id="contact"
                  name="contact"
                  value={formData.contact || ''}
                  onChange={handleChange}
                  className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                />
              </div>
            </div>
            <div className="flex flex-wrap mb-2">
              <div className="w-1/3 px-4 flex items-center justify-end">
                <label htmlFor="message" className="text-gray-700 text-sm mr-2">
                  Skelbimo turinys
                </label>
              </div>
              <div className="w-full px-4 flex-1 flex items-center justify-start">
                <textarea
                  type="text"
                  id="message"
                  name="message"
                  value={formData.message || ''}
                  onChange={handleChange}
                  style={{ height: '200px' }}
                  className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="text-red-500 background-transparent font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
            type="button"
            onClick={closeModal}
          >
            Uždaryti
          </button>
          <button
            className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
            type="button"
            onClick={handleSubmit}
          >
            Saugoti
          </button>
        </ModalFooter>
      </Modal>
    )
  );
};

export default PostUpdateModal;
