import { useState } from 'react';

const useConfirmationModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);

  const handleModalClick = (data) => {
    setShowModal(true);
    setData(data);
  };

  const closeModal = () => {
    setShowModal(false);
    setData(null);
  };

  return {
    showModal,
    data,
    handleModalClick,
    closeModal
  };
};

export default useConfirmationModal;
