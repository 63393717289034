import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { calculateDebt } from '../../api/debtsService';
import { round } from '../../helpers/numberHelpers';

const initialState = {
  atStartOfYear: 0,
  debtPerYear: [],
  debtTotal: 0,
  overpayment: 0
};

export const getDebt = createAsyncThunk('debt/get', async (id) => {
  return await calculateDebt(id);
});

const debtSlice = createSlice({
  name: 'debt',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDebt.fulfilled, (state, action) => {
      state.atStartOfYear = action.payload.atStartOfYear;
      state.debtPerYear = action.payload.debtPerYear.map((debt) => {
        debt.total = round(debt.total, 2);
        debt.totalNoChange = debt.totalNoChange && round(debt.totalNoChange, 2);
        debt.firstHalf = round(debt.firstHalf, 2);
        debt.secondHalf = round(debt.secondHalf, 2);
        debt.usedPaymentAmount = debt.usedPaymentAmount && round(debt.usedPaymentAmount, 2);
        debt.lateInterest = debt.lateInterest && round(debt.lateInterest, 2);

        return debt;
      });

      state.overpayment = round(action.payload.overpayment, 2);
      const debtTotal = action.payload.debtPerYear.reduce((accumulator, debt) => {
        return accumulator + debt.total;
      }, 0);
      state.debtTotal = round(debtTotal, 2);
    });
  }
});

export default debtSlice.reducer;
