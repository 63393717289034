import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/debtAndPayments/Header';
import PaymentHistory from '../../containers/dashboard/PaymentHistory';
import Debt from '../../containers/dashboard/Debt';

const DebtAndPaymentsPage = () => {
  const { clientId } = useParams();

  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-8">
        <Header id={clientId} />
        <Debt id={clientId} />
        <PaymentHistory id={clientId} />
      </div>
    </div>
  );
};

export default DebtAndPaymentsPage;
