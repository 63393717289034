import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import BodyCell from '../table/BodyCell';
import { PAYMENT } from '../../consts/types';
import { Link } from 'react-router-dom';
import { getOrderByPaymentId } from '../../api/ordersService';

const ArchiveTableBody = () => {
  const { selectedDataType, data } = useSelector((state) => state.archive);
  const [orderNumbers, setOrderNumbers] = useState({});

  useEffect(() => {
    const fetchOrderNumbers = async () => {
      const numbers = {};

      for (const d of data) {
        const order = await getOrderByPaymentId(d.id);
        if (order && order.orderNumber) {
          numbers[d.id] = order.orderNumber;
        } else {
          numbers[d.id] = 'Nėra';
        }
      }

      setOrderNumbers(numbers);
    };

    fetchOrderNumbers();
  }, [data]);

  return (
    <tbody>
      {data.map((d) => (
        <tr key={d.id} className="odd:bg-white even:bg-gray-50 border-b">
          <BodyCell>
            <span className={orderNumbers[d.id] !== 'Nėra' ? 'font-bold' : ''}>{orderNumbers[d.id]}</span>
          </BodyCell>
          <BodyCell>
            <Link
              to={`/dashboard/clients/${d.owner}/debt-and-payments`}
              className="text-lightBlue-500 hover:text-lightBlue-600"
            >
              {d.garage ? d.garage : d.owner}
            </Link>
          </BodyCell>
          <BodyCell>{d.addedDate}</BodyCell>
          <BodyCell>
            <strong>{d.amount}</strong>
          </BodyCell>
          <BodyCell>{d.information} </BodyCell>
          {selectedDataType === PAYMENT && <BodyCell>{d.isBank ? 'Bankinis' : 'Grynais'}</BodyCell>}
        </tr>
      ))}
    </tbody>
  );
};

export default ArchiveTableBody;
