import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isAuthenticated } from '../../api/userService';

const LandingNavbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <Link
            className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            to="/"
          >
            <img className="h-10 max-w-full" src="/eismas.png" alt="EISMAS" />
          </Link>
          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
            style={{ color: '#563d09' }}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
        <div
          className={
            'lg:flex flex-grow items-center lg:bg-transparent lg:bg-opacity-0 lg:shadow-none' +
            (navbarOpen ? ' bg-white block rounded shadow-lg p-5' : ' hidden')
          }
          id="example-navbar-warning"
        >
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            <li className="flex items-center">
              <Link
                className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
                to="/about"
              >
                <span style={{ color: '#493006' }}>APIE MUS</span>
              </Link>
            </li>
            <li className="flex items-center">
              <Link
                className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
                to="/contacts"
              >
                <span style={{ color: '#493006' }}>KONTAKTAI</span>
              </Link>
            </li>
            <li className="flex items-center">
              <Link
                className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
                to="/gallery"
              >
                <span style={{ color: '#493006' }}>FOTOGALERIJA</span>
              </Link>
            </li>
            <li className="flex items-center">
              <Link
                className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
                to="/ads-list"
              >
                <span style={{ color: '#493006' }}>SKELBIMAI</span>
              </Link>
            </li>
            {isAuthenticated() && (
              <li className="flex items-center">
                <Link
                  className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
                  to="/dashboard"
                >
                  <span style={{ color: '#493006' }}>PASKYRA</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default LandingNavbar;
