import React from 'react';
import { useSelector } from 'react-redux';

const TotalSum = () => {
  const { selectedYear, selectedDataType, total } = useSelector((state) => state.archive);

  return (
    selectedYear &&
    selectedDataType && (
      <div className="text-blueGray-700 text-sm m-2">{`Nuo metų pradžios: ${
        total !== undefined ? `${total}€` : ''
      }`}</div>
    )
  );
};

export default TotalSum;
