import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ELECTRICITY, PAYMENT } from '../../consts/types';
import { selectDataType } from '../../redux/slices/archiveSlice';
import Dropdown from '../dashboard/Dropdown';

const ArchiveTypeDropdown = () => {
  const types = [
    { value: PAYMENT, text: 'Mokėjimai' },
    { value: ELECTRICITY, text: 'Elektra' }
  ];
  const { selectedDataType } = useSelector((state) => state.archive);
  const dispatch = useDispatch();

  return (
    <Dropdown
      items={types}
      text={
        selectedDataType ? `Archyvo tipas (${types.find((x) => x.value === selectedDataType).text})` : 'Archyvo tipas'
      }
      handleSelectChange={(type) => dispatch(selectDataType({ type }))}
    />
  );
};

export default ArchiveTypeDropdown;
