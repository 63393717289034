import React from 'react';

const Footer = () => {
  return (
    <footer className="relative bg-blueGray-200 pt-8 pb-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap text-center lg:text-left">
          <div className="w-full lg:w-6/12 px-4" style={{ marginBottom: '50px' }}>
            <h4 className="text-3xl font-semibold mb-3">Palaikykime ryšį!</h4>
            <h5 className="text-lg mt-0 mb-2 text-blueGray-600">Susisiekite su mumis, atsakysime darbo dienomis.</h5>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-6/12 px-4 ml-auto" style={{ marginBottom: '50px' }}>
                <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                  Telefonas informacijai
                </span>
                <ul className="list-unstyled">
                  <li>+370 679 61434</li>
                </ul>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">El. paštas</span>
                <ul className="list-unstyled">
                  <li>
                    <a href="mailto:gebeismas@gmail.com">gebeismas@gmail.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-blueGray-300" style={{ marginTop: '80px' }} />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-blueGray-500 font-semibold py-1">
              © {new Date().getFullYear()} Garažų eksploatavimo bendrija Eismas.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
