import React from 'react';
import { GREEN_FONT, RED_FONT } from '../../../consts/fontColors';
import BodyCell from '../../table/BodyCell';
import { useSelector } from 'react-redux';
import LinkButton from '../../dashboard/LinkButton';

const UtilitiesTableBody = ({ data, acceptPayment }) => {
  const { isAdmin } = useSelector((state) => state.role);

  const getStatusCellContent = (status, id) => {
    if (status) {
      return 'Sumokėta';
    }

    if (isAdmin) {
      return (
        <LinkButton
          additionalClasses={'hover:underline text-blue-500 hover:text-black cursor-pointer'}
          onClick={() => acceptPayment(id)}
        >
          Įskaityti mokėjimą
        </LinkButton>
      );
    }

    return 'NEĮSKAITYTAS';
  };

  return (
    <tbody>
      {data.map((d) => {
        return (
          <tr key={d.id} className="odd:bg-white even:bg-gray-50 border-b">
            <BodyCell>{d.date}</BodyCell>
            <BodyCell>{d.firstReading}</BodyCell>
            {d.secondReading ? (
              <>
                <BodyCell>{d.secondReading}</BodyCell>
                <BodyCell>{d.secondReading - d.firstReading}</BodyCell>
                <BodyCell>
                  <strong>{`${d.total}€`}</strong>
                </BodyCell>
                <BodyCell customAlignment={'text-left'} customFont={d.status ? GREEN_FONT : RED_FONT}>
                  {getStatusCellContent(d.status, d.id)}
                </BodyCell>
              </>
            ) : (
              <>
                <BodyCell />
                <BodyCell />
                <BodyCell />
                <BodyCell />
              </>
            )}
          </tr>
        );
      })}
    </tbody>
  );
};

export default UtilitiesTableBody;
