import React from 'react';

const UpperImage = () => {
  return (
    <section className="relative block h-500-px">
      <div className="absolute top-0 w-full h-full bg-center bg-cover" style={{ backgroundImage: `url(/landing.jpg)` }}>
        <span id="overlay" className="w-full h-full absolute" style={{ background: '#ecd988', opacity: '0.75' }}></span>
      </div>
      <div
        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
        style={{ transform: 'translateZ(0)' }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon className="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </section>
  );
};

export default UpperImage;
