import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFormattedDateTime } from '../../helpers/dateHelpers';
import { getAllPayments } from '../../api/paymentsService';
import { round } from '../../helpers/numberHelpers';

const initialState = {
  payments: [],
  currentPage: 1,
  pageSize: 10,
  totalPages: 0,
  isLoading: false,
  error: undefined
};

export const getPayments = createAsyncThunk('payments/getPayments', async (id, thunkAPI) => {
  const currentState = thunkAPI.getState();
  const page = currentState.payments.currentPage;
  const pageSize = currentState.payments.pageSize;

  const skip = (page - 1) * pageSize;
  const result = await getAllPayments(id, skip, pageSize);
  const { payments, totalPayments } = result;

  const totalPages = Math.ceil(totalPayments / pageSize);

  return { payments, totalPages };
});

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setPageSize: (state, { payload }) => {
      state.pageSize = payload.pageSize;
      state.currentPage = 1;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload.page;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPayments.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.totalPages = action.payload.totalPages;
        state.payments = action.payload.payments.map((payment) => {
          payment.addedDate = getFormattedDateTime(payment.addedDate);
          payment.updatedDate = getFormattedDateTime(payment.updatedDate);
          payment.amount = round(payment.amount, 2);

          return payment;
        });
      })
      .addCase(getPayments.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const { setPageSize, setCurrentPage } = paymentsSlice.actions;
export default paymentsSlice.reducer;
