import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getClientTaxesFor } from '../../api/taxesService';
import { round } from '../../helpers/numberHelpers';
import { getFormattedDateOnly } from '../../helpers/dateHelpers';
import { WATER } from '../../consts/types';

const initialState = {
  waterTaxes: []
};

export const getWaterDebt = createAsyncThunk('waterDebt/get', async (id) => {
  return await getClientTaxesFor(id, WATER);
});

const waterDebtSlice = createSlice({
  name: 'waterDebt',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWaterDebt.fulfilled, (state, action) => {
      state.waterTaxes = action.payload.map((tax) => {
        tax.total = round(tax.total, 2);
        tax.date = getFormattedDateOnly(tax.date);
        return tax;
      });
    });
  }
});

export default waterDebtSlice.reducer;
