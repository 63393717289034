export const BASE_TAXES = 'baziniai';
export const UTILITIES_PRICES = 'nustatymai';
export const TAXES = 'mokesciai';
export const CLIENTS = 'klientai';
export const LATE_PAYMENTS = 'delspinigiai';
export const MESSAGES = 'messages';
export const ORDERS = 'orderiai';
export const PAYMENTS = 'mokejimai';
export const FILES = 'files';
export const POSTS = 'posts';
