import { round, toWords } from '../numberHelpers';

export const generateOrderCopy = (order) => {
  const euros = Math.floor(order.total);
  let cents = round((order.total - euros) * 100, 2);
  const eurosInWords = toWords(euros);
  const capitalizedEurosInWords = `${eurosInWords.charAt(0).toUpperCase()}${eurosInWords.slice(1)}`;
  const date = new Date(order.date);
  const formattedDate = date.toISOString().split('T')[0];

  if (cents < 10) {
    cents = `0${cents}`;
  }

  return {
    margin: [10, 5],
    table: {
      widths: ['*'],
      body: [
        [
          {
            text: 'GEB "EISMAS" Pramonės pr. 19, Kaunas',
            fontSize: 10,
            alignment: 'center',
            border: [false, false, false, true]
          }
        ],
        [
          {
            text: 'Prekės ar paslaugas parduodančio ūkio subjekto pavadinimas (kiti duomenys)',
            alignment: 'center',
            fontSize: 8,
            border: [false, false, false, false]
          }
        ],
        [
          {
            margin: [0, 5, 0, 0],
            table: {
              widths: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
              body: [
                [
                  { text: '2', alignment: 'center' },
                  { text: '3', alignment: 'center' },
                  { text: '5', alignment: 'center' },
                  { text: '3', alignment: 'center' },
                  { text: '5', alignment: 'center' },
                  { text: '6', alignment: 'center' },
                  { text: '6', alignment: 'center' },
                  { text: '2', alignment: 'center' },
                  { text: '0', alignment: 'center' },
                  { text: '', alignment: 'center' },
                  { text: '', alignment: 'center' },
                  { text: '', alignment: 'center' },
                  { text: '', alignment: 'center' },
                  { text: '', alignment: 'center' }
                ]
              ]
            },
            border: [false, false, false, false]
          }
        ],
        [
          {
            text: '(Ūkio subjekto arba PVM mokėtojo kodas)',
            margin: [60, 0, 0, 0],
            fontSize: 8,
            border: [false, false, false, false]
          }
        ],
        [
          {
            text: 'PINIGŲ PRIĖMIMO KVITAS',
            margin: [0, 10, 0, 0],
            bold: true,
            fontSize: 14,
            alignment: 'center',
            border: [false, false, false, false]
          }
        ],
        [
          {
            text: `Serija GEB Nr. ${order.orderNumber}`,
            margin: [0, 5, 0, 0],
            fontSize: 10,
            alignment: 'center',
            border: [false, false, false, false]
          }
        ],
        [
          {
            text: formattedDate, // Change formatting based on locale
            margin: [0, 10, 0, 10],
            fontSize: 10,
            alignment: 'center',
            border: [false, false, false, false]
          }
        ],
        [
          {
            margin: [0, 0, 0, 0],
            table: {
              widths: [75, '*'],
              body: [
                [
                  {
                    rowSpan: 2,
                    text: 'Mokėjimo pagrindas',
                    fontSize: 10,
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                  {
                    text: order.description,
                    alignment: 'justify',
                    fontSize: 10,
                    border: [false, false, false, false]
                  }
                ],
                [
                  { text: '', border: [false, false, false, false] },
                  {
                    text: '(prekių/paslaugų pavadinimai, kiekiai, matavimo vienetai, vieneto kaina)',
                    fontSize: 8,
                    alignment: 'center',
                    border: [false, true, false, false]
                  }
                ]
              ]
            },
            border: [false, false, false, false]
          }
        ],
        [
          {
            margin: [0, 20, 0, 0],
            table: {
              widths: [75, '*', 10, 100],
              body: [
                [
                  {
                    rowSpan: 2,
                    text: 'Sumokėta suma',
                    fontSize: 10,
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                  {
                    text: `${capitalizedEurosInWords} € ${cents} ct.`,
                    alignment: 'justify',
                    fontSize: 10,
                    border: [false, false, false, false]
                  },
                  { text: '', border: [false, false, false, false] },
                  {
                    rowSpan: 2,
                    text: `${euros} Eur. ${cents} ct.`,
                    fontSize: 10,
                    alignment: 'center',
                    border: [true, true, true, true],
                    margin: [0, 8, 0, 0]
                  }
                ],
                [
                  { text: '', border: [false, false, false, false] },
                  {
                    text: '(už prekes ar paslaugas sumokėta suma žodžiu)',
                    fontSize: 8,
                    alignment: 'center',
                    border: [false, true, false, false]
                  },
                  { text: '', border: [false, false, false, false] },
                  { text: '', border: [false, false, false, false] }
                ]
              ]
            },
            border: [false, false, false, false]
          }
        ],
        [
          {
            margin: [0, 20, 0, 0],
            table: {
              widths: [75, '*'],
              body: [
                [
                  {
                    rowSpan: 2,
                    text: 'Sumokėjau',
                    fontSize: 10,
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                  {
                    text: ' ', // has to be whitespace
                    fontSize: 10,
                    border: [false, false, false, false]
                  }
                ],
                [
                  { text: '', border: [false, false, false, false] },
                  {
                    text: '(vardas, pavardė, parašas)',
                    fontSize: 8,
                    alignment: 'center',
                    border: [false, true, false, false]
                  }
                ]
              ]
            },
            border: [false, false, false, false]
          }
        ],
        [
          {
            margin: [0, 10, 0, 0],
            table: {
              widths: [75, '*'],
              body: [
                [
                  {
                    rowSpan: 2,
                    text: 'Pinigus gavau',
                    fontSize: 10,
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                  {
                    text: ' ', // has to be whitespace
                    fontSize: 10,
                    border: [false, false, false, false]
                  }
                ],
                [
                  { text: '', border: [false, false, false, false] },
                  {
                    text: '(pardavėjo pareigų pavadinimas, parašas, vardas ir pavardė)',
                    fontSize: 8,
                    alignment: 'center',
                    border: [false, true, false, false]
                  }
                ]
              ]
            },
            border: [false, false, false, false]
          }
        ]
      ]
    }
  };
};
