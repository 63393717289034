import React, { useEffect, useState } from 'react';
import ClientUpdateForm from '../../components/clientUpdate/ClientUpdateForm';
import { getClient, setNewClientInformationForApproval } from '../../api/clientsService';

const ClientUpdate = () => {
  const [client, setClient] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [isError, setIsError] = useState(false);
  const [resultMessage, setResultMessage] = useState('');

  useEffect(() => {
    const fetchClient = async () => {
      return await getClient();
    };

    fetchClient().then((data) =>
      setClient({ id: data.id, owner: data.owner, address: data.address, phone: data.phone, email: data.email })
    );
  }, []);

  const handleInputChange = (event) => {
    const property = event.target.name;
    const value = event.target.value;

    setClient({ ...client, [property]: value });
  };

  const handleUpdate = async () => {
    setPasswordError('');
    setIsError(false);
    setResultMessage('');

    if (client.password && client.password !== client.passwordRepeat) {
      setPasswordError('Nesutampa įvesti slaptažodžiai');
      return;
    }

    try {
      setIsLoading(true);

      const columnsUpdated = await setNewClientInformationForApproval(client);

      if (columnsUpdated) {
        if (client.password) {
          setResultMessage('Naujas slaptažodis išsaugotas ir duomenų atnaujinimo prašymas išsiųstas');
        } else {
          setResultMessage('Duomenų atnaujinimo prašymas išsiųstas');
        }
      } else if (client.password) {
        setResultMessage('Naujas slaptažodis išsaugotas');
      }
    } catch (error) {
      setIsError(true);
      setResultMessage('Serverio klaida');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 className="font-semibold text-base text-blueGray-700">Savininko duomenų keitimas</h3>
              </div>
            </div>
          </div>
          {client && (
            <ClientUpdateForm
              client={client}
              handleInputChange={handleInputChange}
              onSubmit={handleUpdate}
              isLoading={isLoading}
              passwordError={passwordError}
              isError={isError}
              resultMessage={resultMessage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientUpdate;
