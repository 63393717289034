import React from 'react';
import LoadingSpinner from '../dashboard/LoadingSpinner';

const ClientUpdateForm = ({
  client,
  handleInputChange,
  onSubmit,
  isLoading,
  passwordError,
  isError,
  resultMessage
}) => {
  const { owner, address, phone, email } = client;

  return (
    <div className="container mx-auto">
      <div className="flex flex-wrap mb-2">
        <div className="w-1/4 px-4 flex items-center justify-end">
          <label htmlFor="owner" className="text-gray-700 text-sm mr-2">
            Savininkas
          </label>
        </div>
        <div className="w-1/4 px-4">
          <input
            type="text"
            id="owner"
            name="owner"
            value={owner}
            onChange={(e) => handleInputChange(e)}
            className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
          />
        </div>
      </div>
      <div className="flex flex-wrap mb-2">
        <div className="w-1/4 px-4 flex items-center justify-end">
          <label htmlFor="address" className="text-gray-700 text-sm mr-2">
            Adresas
          </label>
        </div>
        <div className="w-1/4 px-4">
          <input
            type="text"
            id="address"
            name="address"
            value={address}
            onChange={(e) => handleInputChange(e)}
            className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
          />
        </div>
      </div>
      <div className="flex flex-wrap mb-2">
        <div className="w-1/4 px-4 flex items-center justify-end">
          <label htmlFor="phone" className="text-gray-700 text-sm mr-2">
            Telefonas
          </label>
        </div>
        <div className="w-1/4 px-4">
          <input
            type="text"
            id="phone"
            name="phone"
            value={phone}
            onChange={(e) => handleInputChange(e)}
            className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
          />
        </div>
      </div>
      <div className="flex flex-wrap mb-2">
        <div className="w-1/4 px-4 flex items-center justify-end">
          <label htmlFor="email" className="text-gray-700 text-sm mr-2">
            E. paštas
          </label>
        </div>
        <div className="w-1/4 px-4">
          <input
            type="text"
            id="email"
            name="email"
            value={email}
            onChange={(e) => handleInputChange(e)}
            className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
          />
        </div>
      </div>
      <div className="flex flex-wrap mb-2">
        <div className="w-1/4 px-4 flex items-center justify-end">
          <label htmlFor="password" className="text-gray-700 text-sm mr-2">
            Naujas slaptažodis
          </label>
        </div>
        <div className="w-1/4 px-4">
          <input
            type="password"
            id="password"
            name="password"
            onChange={(e) => handleInputChange(e)}
            className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
          />
        </div>
      </div>
      <div className="flex flex-wrap mb-2">
        <div className="w-1/4 px-4 flex items-center justify-end">
          <label htmlFor="passwordRepeat" className="text-gray-700 text-sm mr-2">
            Pakartokite naują slaptažodį
          </label>
        </div>
        <div className="w-1/4 px-4">
          <input
            type="password"
            id="passwordRepeat"
            name="passwordRepeat"
            onChange={(e) => handleInputChange(e)}
            className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
          />
        </div>
      </div>
      {passwordError && (
        <div className="flex flex-wrap mb-2" style={{ marginLeft: '25%' }}>
          <div className="w-1/4 px-4 flex items-center justify-start">
            <label className="text-red-500 text-sm">{passwordError}</label>
          </div>
        </div>
      )}
      {resultMessage && (
        <div className="flex flex-wrap mb-2 mt-4">
          <div className="w-1/2 px-4 flex items-center justify-center">
            <label className={`text-sm ${isError ? 'text-red-500' : 'text-green-500'}`}>{resultMessage}</label>
          </div>
        </div>
      )}
      <div className="flex flex-wrap mb-2 mt-4">
        <div className="w-1/2 px-4 flex items-center justify-center">
          <button
            className={`hover:shadow-md text-white font-bold uppercase text-xs px-4 py-2 rounded shadow outline-none focus:outline-none mr-2 ${
              isLoading ? 'bg-lightBlue-300' : 'bg-lightBlue-500 active:bg-lightBlue-600'
            }`}
            type="button"
            onClick={() => onSubmit()}
            disabled={isLoading}
          >
            Keisti
          </button>
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
};

export default ClientUpdateForm;
