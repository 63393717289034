import React from 'react';
import HeaderCell from '../table/HeaderCell';

const ClientsTableHeader = () => {
  return (
    <thead>
      <tr>
        <HeaderCell>Garažo Nr.</HeaderCell>
        <HeaderCell customWrap={'whitespace-normal'}>Savininkas</HeaderCell>
        <HeaderCell>Adresas</HeaderCell>
        <HeaderCell customWrap={'whitespace-wrap'}>Telefonas, El. paštas</HeaderCell>
        <HeaderCell>
          Bendras plotas m<sup>2</sup>
        </HeaderCell>
        <HeaderCell customWrap={'whitespace-normal'}>Skola šiai dienai</HeaderCell>
        <HeaderCell>Veiksmai</HeaderCell>
      </tr>
    </thead>
  );
};

export default ClientsTableHeader;
