import React from 'react';
import TaxesTableHeader from './TaxesTableHeader';
import TaxesTableBody from './TaxesTableBody';

const TaxesTable = ({ handleDeleteClick }) => {
  return (
    <table className="items-center w-full bg-transparent border-collapse">
      <TaxesTableHeader />
      <TaxesTableBody handleDeleteClick={handleDeleteClick} />
    </table>
  );
};

export default TaxesTable;
