import React, { createRef, useEffect, useState } from 'react';
import { createPopper } from '@popperjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const Dropdown = ({ items, text, handleSelectChange }) => {
  const [selectedValue, setSelectedValue] = useState();
  const [dropdownShow, setDropdownShow] = useState(false);
  const btnDropdownRef = createRef();
  const popoverDropdownRef = createRef();

  useEffect(() => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: 'bottom-start'
    });
  }, [btnDropdownRef, popoverDropdownRef]);

  const handleSelect = (value) => {
    handleSelectChange(value);
    setSelectedValue(value);
    setDropdownShow(false);
  };

  return (
    <>
      <button
        style={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}
        className="text-blueGray-700 font-bold uppercase text-sm px-3 py-2 border border-blueGray-300 rounded shadow hover:shadow-lg outline-none focus:outline-none m-2 bg-white active:bg-blueGray-100"
        type="button"
        ref={btnDropdownRef}
        onClick={() => {
          dropdownShow ? setDropdownShow(false) : setDropdownShow(true);
        }}
      >
        <span>{text}</span>
        <span className="ml-3">
          <FontAwesomeIcon icon={faCaretDown} />
        </span>
      </button>
      <div
        ref={popoverDropdownRef}
        className={`${
          dropdownShow ? 'block' : 'hidden'
        } bg-white text-base z-50 float-left list-none text-center rounded shadow-lg`}
        style={{ maxHeight: '25vh', overflowY: 'scroll' }}
      >
        {items.map((item) => (
          <button
            key={item.value}
            className={`text-sm py-2 px-5 font-normal block w-full whitespace-no-wrap hover:bg-blueGray-100 ${
              item.value === selectedValue && 'bg-blueGray-100'
            }`}
            onClick={() => handleSelect(item.value)}
          >
            {item.text}
          </button>
        ))}
      </div>
    </>
  );
};

export default Dropdown;
