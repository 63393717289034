import React from 'react';
import Posts from '../../containers/dashboard/Posts';

const PostsPage = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <Posts />
      </div>
    </div>
  );
};

export default PostsPage;
