import React from 'react';
import { useSelector } from 'react-redux';
import BodyCell from '../table/BodyCell';
import ConfirmButton from '../buttons/ConfirmButton';
import RejectButton from '../buttons/RejectButton';

const ClientsUnconfirmedInfoTableBody = ({ onConfirm, onReject }) => {
  const { clientsWithUnconfirmedInfo } = useSelector((state) => state.clientsWithUnconfirmedInfo);
  const getNewValueCell = (id, column, value) => {
    if (value) {
      return (
        <>
          <div style={{ marginBottom: '0.5rem' }}>{value}</div>
          <div style={{ whiteSpace: 'nowrap' }}>
            <ConfirmButton onClick={() => onConfirm(id, column)} />
            <RejectButton onClick={() => onReject(id, column)} />
          </div>
        </>
      );
    }
  };

  return (
    <tbody>
      {clientsWithUnconfirmedInfo.map((c) => {
        return (
          <tr key={c.id} className="odd:bg-white even:bg-gray-50 border-b">
            <BodyCell customWrap={'whitespace-normal'}>{c.id}</BodyCell>
            <BodyCell
              customAlignment={'text-right'}
              customWrap={'whitespace-normal'}
              customStyle={{ verticalAlign: 'top' }}
            >
              {c.currentOwner}
            </BodyCell>
            <BodyCell
              customAlignment={'text-left'}
              customWrap={'whitespace-normal'}
              customStyle={{ verticalAlign: 'top' }}
            >
              {getNewValueCell(c.id, 'owner', c.newOwner)}
            </BodyCell>
            <BodyCell
              customAlignment={'text-right'}
              customWrap={'whitespace-normal'}
              customStyle={{ verticalAlign: 'top' }}
            >
              {c.currentAddress}
            </BodyCell>
            <BodyCell
              customAlignment={'text-left'}
              customWrap={'whitespace-normal'}
              customStyle={{ verticalAlign: 'top' }}
            >
              {getNewValueCell(c.id, 'address', c.newAddress)}
            </BodyCell>
            <BodyCell
              customAlignment={'text-right'}
              customWrap={'whitespace-normal'}
              customStyle={{ verticalAlign: 'top' }}
            >
              {c.currentPhone}
            </BodyCell>
            <BodyCell
              customAlignment={'text-left'}
              customWrap={'whitespace-normal'}
              customStyle={{ verticalAlign: 'top' }}
            >
              {getNewValueCell(c.id, 'phone', c.newPhone)}
            </BodyCell>
            <BodyCell
              customAlignment={'text-right'}
              customWrap={'whitespace-normal'}
              customStyle={{ verticalAlign: 'top' }}
            >
              {c.currentEmail}
            </BodyCell>
            <BodyCell
              customAlignment={'text-left'}
              customWrap={'whitespace-normal'}
              customStyle={{ verticalAlign: 'top' }}
            >
              {getNewValueCell(c.id, 'email', c.newEmail)}
            </BodyCell>
          </tr>
        );
      })}
    </tbody>
  );
};

export default ClientsUnconfirmedInfoTableBody;
