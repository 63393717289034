import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getClientTaxesFor } from '../../api/taxesService';
import { round } from '../../helpers/numberHelpers';
import { getFormattedDateOnly } from '../../helpers/dateHelpers';
import { ELECTRICITY } from '../../consts/types';

const initialState = {
  electricityTaxes: []
};

export const getElectricityDebt = createAsyncThunk('electricityDebt/get', async (id) => {
  return await getClientTaxesFor(id, ELECTRICITY);
});

const electricityDebtSlice = createSlice({
  name: 'electricityDebt',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getElectricityDebt.fulfilled, (state, action) => {
      state.electricityTaxes = action.payload.map((tax) => {
        tax.total = round(tax.total, 2);
        tax.date = getFormattedDateOnly(tax.date);
        return tax;
      });
    });
  }
});

export default electricityDebtSlice.reducer;
