import React from 'react';

const MessageContent = ({ message }) => {
  const { subject, body } = message;
  const formattedBody = body.replace(/<br\s*\/?>/gi, '\r\n');

  return (
    <div>
      <div style={{ marginBottom: '1rem', fontWeight: 'bold' }}>{subject ? subject : 'Pranešimas be temos'}</div>
      <div style={{ whiteSpace: 'pre-line' }}>{formattedBody}</div>
    </div>
  );
};

export default MessageContent;
