import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CURRENT_YEAR } from '../../helpers/dateHelpers';
import { selectYear } from '../../redux/slices/archiveSlice';
import Dropdown from '../dashboard/Dropdown';

const YearsDropdown = () => {
  const FIRST_YEAR = 2012;
  const years = Array.from({ length: CURRENT_YEAR - FIRST_YEAR + 1 }, (_, i) => FIRST_YEAR + i).map((year) => ({
    value: year,
    text: year
  }));
  const { selectedYear } = useSelector((state) => state.archive);
  const dispatch = useDispatch();

  return (
    <Dropdown
      items={years}
      text={selectedYear ? `Metai (${selectedYear})` : 'Metai'}
      handleSelectChange={(year) => dispatch(selectYear({ year }))}
    />
  );
};

export default YearsDropdown;
