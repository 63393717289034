import React from 'react';
import UtilityCostsSettings from '../../containers/dashboard/UtilityCostsSettings';
import BaseTaxesSettings from '../../containers/dashboard/BaseTaxesSettings';
import LatePaymentsSettings from '../../containers/dashboard/LatePaymentsSettings';
import EmailSignatureSetting from '../../containers/dashboard/EmailSignatureSetting';
import AdminPasswordSetting from '../../containers/dashboard/AdminPasswordSetting';

const SettingsPage = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <UtilityCostsSettings />
        <BaseTaxesSettings />
        <LatePaymentsSettings />
        <EmailSignatureSetting />
        <AdminPasswordSetting />
      </div>
    </div>
  );
};

export default SettingsPage;
