import React from 'react';
import PageTab from '../../components/dashboard/PageTab';
import PageTabTitle from '../../components/dashboard/PageTabTitle';
import PageTabBody from '../../components/dashboard/PageTabBody';
import { changePassword } from '../../api/userService';
import PasswordChange from '../../components/settings/PasswordChange';

const AdminPasswordSetting = () => {
  const handlePasswordChange = async (newPassword) => {
    await changePassword(newPassword);
  };

  return (
    <PageTab>
      <PageTabTitle>Slaptažodžio keitimas</PageTabTitle>
      <PageTabBody>
        <PasswordChange onSubmit={handlePasswordChange} />
      </PageTabBody>
    </PageTab>
  );
};

export default AdminPasswordSetting;
