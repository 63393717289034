import React, { useCallback, useEffect, useState } from 'react';
import Pagination from '../../components/dashboard/Pagination';
import PaymentHistoryTable from '../../components/paymentHistory/PaymentHistoryTable';
import { useDispatch, useSelector } from 'react-redux';
import { getPayments, setCurrentPage, setPageSize } from '../../redux/slices/paymentsSlice';
import { getOrders } from '../../redux/slices/ordersSlice';
import { removePayment } from '../../api/paymentsService';
import { openPdf } from '../../helpers/pdfHelpers/pdfHelpers';
import { insertOrder } from '../../api/ordersService';
import ConfirmationModal from '../../components/dashboard/ConfirmationModal';
import { PAYMENT } from '../../consts/types';
import AddPaymentModal from '../../components/paymentHistory/AddPaymentModal';
import { getDebt } from '../../redux/slices/debtSlice';
import useConfirmationModal from '../../customHooks/useConfirmationModal';
import LinkButton from '../../components/dashboard/LinkButton';
import { useNavigate } from 'react-router-dom';

const PaymentHistory = ({ id }) => {
  const {
    showModal: showDeleteModal,
    data: paymentForDelete,
    handleModalClick: handleDeleteClick,
    closeModal: closeDeleteModal
  } = useConfirmationModal();
  const {
    showModal: showOrderGenerationModal,
    data: payment,
    handleModalClick: handleOrderGenerationClick,
    closeModal: closeOrderGenerationModal
  } = useConfirmationModal();
  const [isOpen, setIsOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const { isLoading, currentPage, totalPages, pageSize } = useSelector((state) => state.payments);
  const { isAdmin } = useSelector((state) => state.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchData = useCallback(() => {
    dispatch(getPayments(id));

    if (id) {
      dispatch(getOrders(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePaymentDelete = async () => {
    await removePayment(paymentForDelete);
    dispatch(getPayments(id));
    dispatch(getDebt(id));
    closeDeleteModal();
  };

  const handleCashOrderClick = async (payment, order) => {
    if (!order) {
      handleOrderGenerationClick(payment);
    } else {
      await showPdf(payment, order);
    }
  };

  const handleNewOrder = async () => {
    setIsCreating(true);
    const newOrder = { paymentId: payment.id, amount: payment.amount, owner: payment.owner };
    const addedOrder = await insertOrder(newOrder);
    setIsCreating(false);
    await showPdf(payment, addedOrder);
    window.location.reload();
  };

  const showPdf = async (payment, order) => {
    const description = `${payment.information} ${`Garažo Nr. ${order.owner} mokestis.`}`;
    order = { ...order, description };

    await openPdf(order);
    dispatch(getOrders(payment.owner));

    closeOrderGenerationModal();
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage({ page }));
    fetchData();
  };

  const handlePageSizeChange = (pageSize) => {
    dispatch(setPageSize({ pageSize }));
    fetchData();
  };

  return (
    <div className="relative min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded overflow-x-auto">
      <div className="rounded-t mb-0 px-4 py-3 border-0">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 className="font-semibold text-base text-blueGray-700">Mokėjimų istorija</h3>
          </div>
        </div>
      </div>
      {isAdmin && (
        <>
          <button
            className="bg-blue-400 text-white hover:bg-blue-500 active:bg-blue-600 font-bold uppercase text-sm px-2 py-2 rounded outline-none focus:outline-none m-1"
            type="button"
            onClick={() => setIsOpen(true)}
          >
            Pridėti naują mokėjimą
          </button>
          <AddPaymentModal
            clientId={id}
            isOpen={isOpen}
            closeModal={() => setIsOpen(false)}
            additionalHandleOnSubmit={() => {
              dispatch(getPayments(id));
              dispatch(getDebt(id));
            }}
          />
          <LinkButton
            additionalClasses={'text-lightBlue-500 text-left px-4 py-2'}
            onClick={() => navigate(`/dashboard/clients/${id}/payment-history`)}
          >
            Visa mokėjimų istorija
          </LinkButton>
        </>
      )}
      <PaymentHistoryTable handleCashOrderClick={handleCashOrderClick} handleDeleteClick={handleDeleteClick} />
      <Pagination
        data={{ isLoading, currentPage, totalPages, pageSize }}
        onPageChange={handlePageChange}
        onPageSizechange={handlePageSizeChange}
      />

      {showDeleteModal && (
        <ConfirmationModal
          description={'Ar tikrai norite ištrinti duomenis apie įmoką?'}
          handleConfirm={() => handlePaymentDelete()}
          handleClose={() => closeDeleteModal()}
        />
      )}
      {showOrderGenerationModal && (
        <ConfirmationModal
          description={`Ar generuoti kasos pajamų orderį sumai ${payment.amount}€?`}
          isLoading={isCreating}
          handleConfirm={() => handleNewOrder()}
          handleClose={() => closeOrderGenerationModal()}
        />
      )}
    </div>
  );
};

export default PaymentHistory;
