import Parse from 'parse';

export const getFileContent = async (filename) => {
  let data;
  await Parse.Cloud.run('getFileContent', { filename }).then(
    (response) => {
      data = response;
    },
    (error) => {
      console.error(error);
    }
  );
  return data;
};

export const upsertFile = async (filename, content) => {
  await Parse.Cloud.run('upsertFile', { filename, content }).then((error) => {
    console.error(error);
  });
};
