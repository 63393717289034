import React from 'react';
import Header from './ClientsTableHeader';
import Body from './ClientsTableBody';

const ClientsTable = ({ onEditClick, onDeleteClick }) => {
  return (
    <div className="ml-1 mr-1">
      <table className="items-center w-full bg-transparent border-collapse">
        <Header />
        <Body onEditClick={onEditClick} onDeleteClick={onDeleteClick} />
      </table>
    </div>
  );
};

export default ClientsTable;
