import React from 'react';
import HeaderCell from '../table/HeaderCell';
import { PAYMENT } from '../../consts/types';
import { useSelector } from 'react-redux';

const ArchiveTableHeader = () => {
  const { selectedDataType } = useSelector((state) => state.archive);

  return (
    <thead>
      <tr>
        <HeaderCell customWidth="w-1">Orderio Nr.</HeaderCell>
        <HeaderCell>Garažo Nr.</HeaderCell>
        <HeaderCell customWrap={'whitespace-normal'}>Įmokos įvedimo į sistemą laikas</HeaderCell>
        <HeaderCell>Suma, €</HeaderCell>
        <HeaderCell customWrap={'whitespace-normal'}>
          {selectedDataType === PAYMENT ? 'Pastaba' : 'Paslauga'}
        </HeaderCell>
        {selectedDataType === PAYMENT && <HeaderCell>Tipas</HeaderCell>}
      </tr>
    </thead>
  );
};

export default ArchiveTableHeader;
