import React from 'react';

const ModalHeader = ({ children }) => {
  return (
    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
      <h3 className="text-3xl font-semibold">{children}</h3>
    </div>
  );
};

export default ModalHeader;
