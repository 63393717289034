import React, { useEffect, useState } from 'react';

const PasswordChange = ({ onSubmit }) => {
  const [inputs, setInputs] = useState({ password: '', passwordRepeat: '' });
  const [disabled, setDisabled] = useState(true);
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    if (!inputs.password || !inputs.passwordRepeat) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    setPasswordError('');
    if (inputs.password && inputs.password !== inputs.passwordRepeat) {
      setPasswordError('Nesutampa įvesti slaptažodžiai');
    }
  }, [inputs]);

  const handleChange = (event) => {
    const property = event.target.name;
    const value = event.target.value;

    setInputs({ ...inputs, [property]: value });
  };

  return (
    <div className="container mx-auto">
      <div className="flex flex-wrap mb-2">
        <div className="w-1/6 px-4 flex items-center justify-end">
          <label htmlFor="password" className="text-gray-700 text-sm mr-2">
            Naujas slaptažodis
          </label>
        </div>
        <div className="w-1/6 px-4">
          <input
            type="password"
            id="password"
            name="password"
            onChange={handleChange}
            className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
          />
        </div>
      </div>
      <div className="flex flex-wrap mb-2">
        <div className="w-1/6 px-4 flex items-center justify-end">
          <label htmlFor="passwordRepeat" className="text-gray-700 text-sm mr-2 ">
            Pakartokite naują slaptažodį
          </label>
        </div>
        <div className="w-1/6 px-4">
          <input
            type="password"
            id="passwordRepeat"
            name="passwordRepeat"
            onChange={handleChange}
            className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
          />
        </div>
      </div>
      {passwordError && (
        <div className="flex flex-wrap mb-2">
          <div className="w-1/6"></div>
          <div className="w-auto px-4 flex-1">
            <label className="text-sm text-red-500">{passwordError}</label>
          </div>
        </div>
      )}
      <div className="flex flex-wrap mb-2 mt-4">
        <div className="w-1/3 px-4 flex items-center justify-center">
          <button
            className={`hover:shadow-md text-white font-bold uppercase text-xs px-4 py-2 rounded shadow outline-none focus:outline-none mr-2 ${
              passwordError || disabled ? 'bg-lightBlue-300' : 'bg-lightBlue-500 active:bg-lightBlue-600'
            }`}
            type="button"
            onClick={() => onSubmit(inputs.password)}
            disabled={passwordError || disabled}
          >
            Saugoti
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordChange;
