import React from 'react';
import LinkButton from '../dashboard/LinkButton';
import useConfirmationModal from '../../customHooks/useConfirmationModal';
import ConfirmationModal from '../dashboard/ConfirmationModal';

const DeleteLastRecord = ({ deleteLastPayment }) => {
  const { showModal, handleModalClick, closeModal } = useConfirmationModal();

  return (
    <>
      <LinkButton additionalClasses={'text-left m-4'} customStyle={{ width: 'fit-content' }} onClick={handleModalClick}>
        Panaikinti paskutinį dar neįskaitytą parodymą
      </LinkButton>
      {showModal && (
        <ConfirmationModal
          description={'Ar tikrai norite panaikinti paskutinį dar neįskaitytą parodymą?'}
          handleConfirm={() => {
            deleteLastPayment();
            closeModal();
          }}
          handleClose={() => closeModal()}
        />
      )}
    </>
  );
};

export default DeleteLastRecord;
