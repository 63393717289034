import Parse from 'parse';
import { fetchAllRecords } from './queryHandler';
import { getCurrentUser, getUserById } from './userService';
import { PAYMENTS } from '../consts/tables';
import { getRoleByName } from './rolesService';
import { ADMIN_ROLE } from '../consts/roles';
import { getUserByUsername } from './userService';

export const getPaidSum = async (id) => {
  const query = new Parse.Query(PAYMENTS).equalTo('owner', id).equalTo('active', true);
  const payments = await fetchAllRecords(query);

  const sum = payments.reduce((accumulator, payment) => {
    return accumulator + payment.attributes.suma;
  }, 0);

  return sum;
};

export const getAllPayments = async (id, skip, pageSize) => {
  const ownerId = id || (await getCurrentUser()).data.objectId;
  const query = new Parse.Query(PAYMENTS).equalTo('owner', ownerId);

  const totalPayments = await query.count();
  const result = await query.descending('added').skip(skip).limit(pageSize).find();
  const payments = result.map((payment) => {
    return {
      id: payment.id,
      addedDate: payment.attributes.added,
      updatedDate: payment.attributes.updated,
      amount: payment.attributes.suma,
      information: payment.attributes.pastaba,
      isBank: payment.attributes.bank,
      type: payment.attributes.type,
      owner: payment.attributes.owner
    };
  });

  return { payments, totalPayments };
};

export const getAllPaymentsForArchive = async (skip, pageSize, year) => {
  const startDate = new Date(year, 0, 1);
  const endDate = new Date(year + 1, 0, 1);
  const query = new Parse.Query(PAYMENTS)
    .greaterThanOrEqualTo('added', startDate)
    .lessThan('added', endDate)
    .descending('added');

  const totalPayments = await query.count();
  const result = await query.skip(skip).limit(pageSize).find();
  const payments = result.map((payment) => {
    return {
      id: payment.id,
      addedDate: payment.attributes.added,
      amount: payment.attributes.suma,
      information: payment.attributes.pastaba,
      isBank: payment.attributes.bank,
      type: payment.attributes.type,
      owner: payment.attributes.owner,
      garage: payment.attributes.garage
    };
  });

  return { payments, totalPayments };
};

export const insertPayment = async (payment) => {
  const PaymentObject = Parse.Object.extend(PAYMENTS);
  const newPayment = new PaymentObject();
  const owner = await getUserById(payment.owner);

  const currentDateTime = new Date();
  newPayment.set('added', currentDateTime);
  newPayment.set('updated', currentDateTime);
  newPayment.set('suma', payment.amount);
  newPayment.set('pastaba', payment.information);
  newPayment.set('owner', payment.owner);
  newPayment.set('bank', payment.isBank);
  newPayment.set('active', payment.isBank);

  if (owner && owner.attributes) {
    newPayment.set('garage', owner.attributes.username);
  } else {
    newPayment.set('garage', payment.owner);
  }

  const administratorRole = await getRoleByName(ADMIN_ROLE);
  const newACL = new Parse.ACL();
  const user = await getUserByUsername(payment.owner);
  if (user) {
    newACL.setReadAccess(user.id, true);
    newACL.setWriteAccess(user.id, false);
  } else {
    const userById = await getUserById(payment.owner);
    newACL.setReadAccess(userById.id, true);
    newACL.setWriteAccess(userById.id, false);
  }
  newACL.setRoleReadAccess(administratorRole, true);
  newACL.setRoleWriteAccess(administratorRole, true);
  newPayment.setACL(newACL);

  await newPayment.save();
};

export const removePayment = async (id) => {
  const query = new Parse.Query(PAYMENTS);
  const payment = await query.get(id);
  await payment.destroy();
};

export const getPaymentsRange = async (clientId, fromYear, toYear) => {
  const startDate = new Date(fromYear, 0, 1);
  const endDate = new Date(toYear + 1, 0, 1);

  const query = new Parse.Query(PAYMENTS)
    .equalTo('owner', clientId)
    .equalTo('active', true)
    .greaterThanOrEqualTo('added', startDate)
    .lessThan('added', endDate)
    .ascending('added');

  const payments = await query.find();

  return payments.map((payment) => ({
    id: payment.id,
    date: payment.attributes.added,
    total: payment.attributes.suma
  }));
};

export const getAllPaymentsSumInYear = async (year) => {
  const startDate = new Date(year, 0, 1);
  const endDate = new Date(year + 1, 0, 1);
  const query = new Parse.Query(PAYMENTS).greaterThanOrEqualTo('added', startDate).lessThan('added', endDate);

  const payments = await fetchAllRecords(query);
  const sum = payments.reduce((accumulator, payment) => {
    return accumulator + payment.attributes.suma;
  }, 0);

  return sum;
};

export const getAllPaymentsForExport = async (year) => {
  const startDate = new Date(year, 0, 1);
  const endDate = new Date(year + 1, 0, 1);
  const query = new Parse.Query(PAYMENTS)
    .greaterThanOrEqualTo('added', startDate)
    .lessThan('added', endDate)
    .descending('added');

  const payments = await fetchAllRecords(query);
  return payments.map((payment) => {
    return {
      id: payment.id,
      addedDate: payment.attributes.added,
      amount: payment.attributes.suma,
      owner: payment.attributes.owner
    };
  });
};
