import React from 'react';
import HeaderCell from '../table/HeaderCell';

const PostTableHeader = () => {
  return (
    <thead>
      <tr>
        <HeaderCell customWrap={'w-10'}>Kategorija</HeaderCell>
        <HeaderCell customWrap={'w-10'}>Sukūrimo data</HeaderCell>
        <HeaderCell customWrap={'w-10'}>Vardas</HeaderCell>
        <HeaderCell customWrap={'w-10'}>Kontaktai</HeaderCell>
        <HeaderCell customWrap={'text-wrap text-start'}>Skelbimo turinys</HeaderCell>
        <HeaderCell customWrap={'w-10'}>Veiksmai</HeaderCell>
      </tr>
    </thead>
  );
};

export default PostTableHeader;
