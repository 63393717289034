import React from 'react';

const UtilityCostInput = ({ labelText, utilityName, value, handleChange, errorMessage }) => {
  return (
    <>
      <div className="flex flex-wrap mb-2">
        <div className="w-1/6 px-4 flex items-center justify-end">
          <label htmlFor={utilityName} className="text-gray-700 text-sm mr-2">
            {labelText}
          </label>
        </div>
        <div className="w-1/6 px-4">
          <input
            type="number"
            id={utilityName}
            name={utilityName}
            value={value ?? ''}
            onChange={handleChange}
            min={0}
            className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline"
          />
        </div>
      </div>
      {errorMessage && (
        <div className="flex flex-wrap mb-2">
          <div className="w-1/6"></div>
          <div className="w-auto px-4 flex-1">
            <label className="text-sm text-red-500">{errorMessage}</label>
          </div>
        </div>
      )}
    </>
  );
};

export default UtilityCostInput;
