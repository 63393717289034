import React from 'react';
import { useSelector } from 'react-redux';
import BodyCell from '../table/BodyCell';

const ClientPaymentHistoryTableBody = () => {
  const { history } = useSelector((state) => state.clientPaymentHistory);

  return (
    <tbody>
      {history.map((h) => {
        return (
          <tr key={h.year} className="odd:bg-white even:bg-gray-50 border-b">
            <BodyCell>{h.year}</BodyCell>
            <BodyCell>{`${h.debt}€`}</BodyCell>
            <BodyCell>{h.total !== undefined && `${h.total}€`}</BodyCell>
            <BodyCell customAlignment={'text-right'}>
              {h.taxes.map((tax) => (
                <p key={tax.id}>{tax.description}</p>
              ))}
            </BodyCell>
            <BodyCell customAlignment={'text-left'}>
              {h.taxes.map((tax) => (
                <p key={tax.id}>{`${tax.total}€`}</p>
              ))}
            </BodyCell>
            <BodyCell>
              {h.payments.map((payment) => (
                <p key={payment.id}>{payment.date}</p>
              ))}
            </BodyCell>
            <BodyCell>
              {h.payments.map((payment) => (
                <p key={payment.id}>{`${payment.total}€`}</p>
              ))}
            </BodyCell>
          </tr>
        );
      })}
    </tbody>
  );
};

export default ClientPaymentHistoryTableBody;
