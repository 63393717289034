import React from 'react';
import AdminMessage from '../../containers/dashboard/AdminMessage';

const AdminMessagePage = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <AdminMessage />
      </div>
    </div>
  );
};

export default AdminMessagePage;
