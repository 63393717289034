import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFormattedDateOnly } from '../../helpers/dateHelpers';
import { getClient } from '../../api/clientsService';

const initialState = {
  client: null
};

export const getClientById = createAsyncThunk('client/get', async (id) => {
  const client = await getClient(id);
  return { ...client, memberSince: getFormattedDateOnly(client.memberSince) };
});

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClientById.fulfilled, (state, action) => {
      state.client = action.payload;
    });
  }
});

export default clientSlice.reducer;
