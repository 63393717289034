import Parse from 'parse';
import { POSTS } from '../consts/tables';

const postColumn = {
  added: 'added',
  updated: 'updated',
  name: 'name',
  contact: 'contact',
  message: 'message',
  category: 'category',
  objectId: 'objectId'
};

export const createNewPost = async (post) => {
  const PostObject = Parse.Object.extend(POSTS);
  const newPost = new PostObject();
  const currentDateTime = new Date();

  newPost.set(postColumn.added, currentDateTime);
  newPost.set(postColumn.updated, currentDateTime);
  newPost.set(postColumn.name, post.name);
  newPost.set(postColumn.contact, post.contact);
  newPost.set(postColumn.message, post.message);
  newPost.set(postColumn.category, post.category);

  await newPost.save();
};

export const getAllPosts = async () => {
  const query = new Parse.Query(POSTS);
  query.descending(postColumn.added);
  try {
    const results = await query.find();
    const posts = results.map((post) => post.toJSON());
    return posts;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

export const deletePostById = async (objectId) => {
  const PostObject = Parse.Object.extend(POSTS);
  const query = new Parse.Query(PostObject);
  try {
    const post = await query.get(objectId);
    await post.destroy();
    return true;
  } catch (error) {
    return false;
  }
};

export const updatePost = async (postData) => {
  const post = await new Parse.Query(POSTS).get(postData.objectId);
  const currentDateTime = new Date();

  post.set(postColumn.updated, currentDateTime);
  post.set(postColumn.name, postData.name);
  post.set(postColumn.contact, postData.contact);
  post.set(postColumn.message, postData.message);
  post.set(postColumn.category, postData.category);

  await post.save();
};

export const deletePost = async (postData) => {
  const PostObject = Parse.Object.extend(POSTS);
  const query = new Parse.Query(PostObject);
  try {
    const post = await query.get(postData.objectId);
    await post.destroy();
    return true;
  } catch (error) {
    return false;
  }
};
