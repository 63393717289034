import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OTHER, getClientTaxesFor } from '../../api/taxesService';
import { round } from '../../helpers/numberHelpers';

const initialState = {
  otherTaxes: []
};

export const getOtherDebt = createAsyncThunk('otherDebt/get', async (id) => {
  return await getClientTaxesFor(id, OTHER);
});

const otherDebtSlice = createSlice({
  name: 'otherDebt',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOtherDebt.fulfilled, (state, action) => {
      state.otherTaxes = action.payload.map((tax) => {
        tax.total = round(tax.total, 2);
        return tax;
      });
    });
  }
});

export default otherDebtSlice.reducer;
