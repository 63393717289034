import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPrices } from '../../api/utilitiesPricesService';

const initialState = {
  electricityPrice: 0,
  waterPrice: 0
};

export const getUtilitiesPrices = createAsyncThunk('utilitiesPrices/get', async () => {
  return await getPrices();
});

const utilitiesPricesSlice = createSlice({
  name: 'utilitiesPrices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUtilitiesPrices.fulfilled, (state, action) => {
      state.electricityPrice = action.payload.electricityPrice;
      state.waterPrice = action.payload.waterPrice;
    });
  }
});

export default utilitiesPricesSlice.reducer;
