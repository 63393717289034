import React from 'react';
import { useSelector } from 'react-redux';

const DashboardHeader = () => {
  const { isAdmin } = useSelector((state) => state.role);

  const ADMIN_COLOR = 'bg-red-800';
  const CLIENT_COLOR = 'bg-lightBlue-600';

  return (
    <div className={`relative md:pt-32 pb-32 pt-12 ${isAdmin ? ADMIN_COLOR : CLIENT_COLOR}`}>
      {isAdmin && (
        <div className={`absolute top-8 ml-4 text-3xl font-bold text-white`}>GEB Eismas Apskaitos programa</div>
      )}
    </div>
  );
};

export default DashboardHeader;
