export const CURRENT_DATE = new Date();
export const CURRENT_YEAR = CURRENT_DATE.getFullYear();
export const CURRENT_MONTH = CURRENT_DATE.getMonth() + 1;

export const findDifferenceInDays = (targetDate) => {
  // Number of milliseconds in a day
  const millisecondsPerDay = 24 * 60 * 60 * 1000;

  const firstTime = CURRENT_DATE.getTime();
  const secondTime = targetDate.getTime();

  const differenceInMilliseconds = Math.abs(firstTime - secondTime);

  // Convert the difference to days
  return Math.ceil(differenceInMilliseconds / millisecondsPerDay);
};

const locale = 'lt-LT';
const dateTimeFormatter = new Intl.DateTimeFormat(locale, {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'
});

export const getFormattedDateTime = (date) => {
  return date && dateTimeFormatter.format(date);
};

const dateOnlyFormatter = new Intl.DateTimeFormat(locale, {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
});

export const getFormattedDateOnly = (date) => {
  return date && dateOnlyFormatter.format(date);
};
