import React from 'react';
import Modal from '../modal/Modal';
import ModalHeader from '../modal/ModalHeader';
import ModalBody from '../modal/ModalBody';
import ModalFooter from '../modal/ModalFooter';

const PaymentAcceptanceModal = ({ isOpen, taxData, closeModal, openNewPaymentModal, handleForcedPayment }) => {
  return (
    isOpen && (
      <Modal>
        <ModalHeader>{`Kaip įskaityti mokestį ${taxData.total}€ sumai?`}</ModalHeader>
        <ModalBody>
          <span>Galimos operacijos:</span>
          <ol className="list-decimal mt-2 ml-4">
            <li
              className="hover:underline text-blue-500 hover:text-blueGray-800 cursor-pointer"
              onClick={() => openNewPaymentModal(true)}
            >
              {`Įnešti ${taxData.total}€ (mokestis bus įskaitytas automatiškai)`}
            </li>
            <li
              className="hover:underline text-blue-500 hover:text-blueGray-800 cursor-pointer"
              onClick={handleForcedPayment}
            >
              Įskaityti mokėjimą dabar (netikrinus, ar yra mokėjimas)
            </li>
            <li
              className="hover:underline text-blue-500 hover:text-blueGray-800 cursor-pointer"
              onClick={() => openNewPaymentModal(false)}
            >
              Įnešti kitą sumą ir grįžus čia rankiniu būdu (pasirinkus 2-ą būdą) įskaityti mokestį
            </li>
          </ol>
        </ModalBody>
        <ModalFooter>
          <button
            className="text-red-500 background-transparent font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
            type="button"
            onClick={closeModal}
          >
            Uždaryti
          </button>
        </ModalFooter>
      </Modal>
    )
  );
};

export default PaymentAcceptanceModal;
