import React from 'react';
import ClientUpdate from '../../containers/dashboard/ClientUpdate';

const ClientDataUpdatePage = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <ClientUpdate />
      </div>
    </div>
  );
};

export default ClientDataUpdatePage;
