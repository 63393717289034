import Parse from 'parse';
import { getClient } from './clientsService';
import { fetchAllRecords } from './queryHandler';
import { ELECTRICITY, LATE_INTEREST, PAYMENT, WATER } from '../consts/types';
import { CURRENT_YEAR } from '../helpers/dateHelpers';
import { TAXES } from '../consts/tables';
import { getRoleByName } from './rolesService';
import { ADMIN_ROLE } from '../consts/roles';
import { getUserByUsername, getUserById } from './userService';

export const DEBT = 'skola';
export const WATER_B = 'vanduob';
export const OTHER = 'other';

export const getDebtSumIn = async (id, year, type) => {
  const startDate = new Date(year, 0, 1);
  const endDate = new Date(year + 1, 0, 1);

  const query = new Parse.Query(TAXES)
    .greaterThanOrEqualTo('added', startDate)
    .lessThan('added', endDate)
    .equalTo('owner', id)
    .equalTo('type', type);
  const taxes = await fetchAllRecords(query);

  const sum = taxes.reduce((accumulator, tax) => {
    return accumulator + tax.attributes.suma;
  }, 0);

  return sum;
};

export const getTotalDebtSumIn = async (id, year) => {
  const startDate = new Date(year, 0, 1);
  const endDate = new Date(year + 1, 0, 1);

  const baseQuery = new Parse.Query(TAXES)
    .greaterThanOrEqualTo('added', startDate)
    .lessThan('added', endDate)
    .equalTo('owner', id)
    .notEqualTo('suma', null);

  const typeQuery = Parse.Query.or(
    new Parse.Query(TAXES).equalTo('type', PAYMENT),
    new Parse.Query(TAXES).equalTo('type', LATE_INTEREST),
    new Parse.Query(TAXES).equalTo('type', WATER_B),
    new Parse.Query(TAXES).equalTo('type', DEBT)
  );
  const electricityQuery = new Parse.Query(TAXES).equalTo('type', ELECTRICITY).greaterThanOrEqualTo('status', 0);
  const waterQuery = new Parse.Query(TAXES).equalTo('type', WATER).greaterThanOrEqualTo('status', 0);
  const combinedTypeQuery = Parse.Query.or(typeQuery, electricityQuery, waterQuery);
  const query = Parse.Query.and(baseQuery, combinedTypeQuery);

  /*
  SQL query:

  SELECT SUM(suma) 
  FROM mokesciai 
  WHERE 
    year(added)='{$i}' AND 
    (
        type='mokestis' OR 
        type='delspinigiai' 
        OR type='vanduob' 
        OR type='skola' OR 
        (type='elektra' AND status>=0) OR 
        (type='vanduo' AND status>=0)
    )
*/
  const taxes = await query.find();

  const sum = taxes.reduce((accumulator, tax) => {
    return accumulator + tax.attributes.suma;
  }, 0);

  return sum;
};

export const getClientTaxesFor = async (id, type) => {
  const clientId = id || (await getClient()).id;

  switch (type) {
    case ELECTRICITY:
      const electricityData = await getElectricityTaxes(clientId);
      return electricityData.map((data) => {
        return {
          id: data.id,
          status: data.attributes.status,
          year: data.attributes.added.getFullYear(),
          date: data.attributes.added,
          firstReading: data.attributes.reading,
          secondReading: data.attributes.reading2,
          total: data.attributes.suma
        };
      });
    case WATER:
      const waterData = await getWaterTaxes(clientId);
      return waterData.map((data) => {
        return {
          id: data.id,
          status: data.attributes.status,
          year: data.attributes.added.getFullYear(),
          date: data.attributes.added,
          firstReading: data.attributes.reading,
          secondReading: data.attributes.reading2,
          total: data.attributes.suma
        };
      });
    case OTHER:
      const otherData = await getOtherTaxes(clientId);
      return otherData.map((data) => {
        const year = new Date(data.attributes.added).getFullYear();
        const name = data.attributes.paslauga || 'Skola';

        return {
          id: data.id,
          year,
          name,
          total: data.attributes.suma
        };
      });
    default:
      return [];
  }
};

const getElectricityTaxes = async (id) => {
  const query = new Parse.Query(TAXES).equalTo('owner', id).equalTo('type', ELECTRICITY).descending('added');

  return await fetchAllRecords(query);
};

const getWaterTaxes = async (id) => {
  const query = new Parse.Query(TAXES).equalTo('owner', id).equalTo('type', WATER).descending('added');

  return await fetchAllRecords(query);
};

const getOtherTaxes = async (id) => {
  const typeQuery = Parse.Query.or(
    new Parse.Query(TAXES).equalTo('type', PAYMENT),
    new Parse.Query(TAXES).equalTo('type', LATE_INTEREST),
    new Parse.Query(TAXES).equalTo('type', WATER_B),
    new Parse.Query(TAXES).equalTo('type', DEBT)
  );

  const baseQuery = new Parse.Query(TAXES).equalTo('owner', id);
  const query = Parse.Query.and(baseQuery, typeQuery);

  return await fetchAllRecords(query);
};

export const getUnpaidSumFor = async (id, type) => {
  const query = new Parse.Query(TAXES)
    .equalTo('owner', id)
    .equalTo('type', type)
    .greaterThan('suma', 0)
    .equalTo('status', 0);

  const taxes = await fetchAllRecords(query);

  const sum = taxes.reduce((accumulator, tax) => {
    return accumulator + tax.attributes.suma;
  }, 0);

  return sum;
};

export const getAllClientTaxesThisYear = async (id) => {
  const startDate = new Date(CURRENT_YEAR, 0, 1);
  const endDate = new Date(CURRENT_YEAR + 1, 0, 1);

  const query = new Parse.Query(TAXES)
    .greaterThanOrEqualTo('added', startDate)
    .lessThan('added', endDate)
    .equalTo('owner', id)
    .ascending('added');

  const taxes = await query.find();

  return taxes.map((tax) => ({
    id: tax.id,
    date: tax.attributes.added,
    information: tax.attributes.paslauga,
    total: tax.attributes.suma
  }));
};

export const insertTax = async (tax) => {
  const TaxObject = Parse.Object.extend(TAXES);
  const newTax = new TaxObject();
  newTax.set('paslauga', tax.information);
  newTax.set('reading', tax.firstReading);
  newTax.set('reading2', tax.secondReading);
  newTax.set('owner', tax.owner);
  newTax.set('type', tax.type);
  newTax.set('added', new Date());
  newTax.set('suma', tax.amount);
  newTax.set('status', 0);

  const administratorRole = await getRoleByName(ADMIN_ROLE);

  const newACL = new Parse.ACL();
  const user = await getUserByUsername(tax.owner);
  if (user) {
    newACL.setReadAccess(user.id, true);
    newACL.setWriteAccess(user.id, true);
  } else {
    const userById = await getUserById(tax.owner);
    newACL.setReadAccess(userById.id, true);
    newACL.setWriteAccess(userById.id, true);
  }
  newACL.setRoleReadAccess(administratorRole, true);
  newACL.setRoleWriteAccess(administratorRole, true);
  newTax.setACL(newACL);

  await newTax.save();
};

export const removeTax = async (id) => {
  const query = new Parse.Query(TAXES);
  const tax = await query.get(id);
  await tax.destroy();
};

export const getTaxesRange = async (clientId, fromYear, toYear) => {
  const startDate = new Date(fromYear, 0, 1);
  const endDate = new Date(toYear + 1, 0, 1);

  const query = new Parse.Query(TAXES)
    .equalTo('owner', clientId)
    .greaterThanOrEqualTo('added', startDate)
    .lessThan('added', endDate)
    .ascending('added');

  const taxes = await query.find();

  return taxes.map((tax) => ({
    id: tax.id,
    date: tax.attributes.added,
    description: tax.attributes.paslauga,
    total: tax.attributes.suma
  }));
};

export const removeLastUnsavedTax = async (id, type) => {
  const query = new Parse.Query(TAXES)
    .equalTo('owner', id)
    .equalTo('type', type)
    .equalTo('status', 0)
    .descending('added');
  const tax = await query.first();

  if (tax) {
    await tax.destroy();
  }
};

export const changeTaxStatusTo = async (id, status) => {
  const query = new Parse.Query(TAXES);
  const tax = await query.get(id);

  tax.set('status', status);

  await tax.save();
};

export const updateReadingData = async (id, data) => {
  const query = new Parse.Query(TAXES);
  const tax = await query.get(id);

  tax.set('reading2', data.secondReading);
  tax.set('suma', data.total);

  await tax.save();
};

export const normalizeClientTaxesAfterMeterChange = async (clientId, type) => {
  const readingQuery = Parse.Query.or(
    new Parse.Query(TAXES).equalTo('reading2', 0),
    new Parse.Query(TAXES).equalTo('reading2', null)
  );
  const baseQuery = new Parse.Query(TAXES).equalTo('owner', clientId).equalTo('type', type);
  const query = Parse.Query.and(baseQuery, readingQuery);

  const taxesToDelete = await query.find();
  await Promise.all(taxesToDelete.map(async (tax) => await tax.destroy()));
};

export const getAllTaxesForArchive = async (skip, pageSize, { year, type }) => {
  const startDate = new Date(year, 0, 1);
  const endDate = new Date(year + 1, 0, 1);
  const query = new Parse.Query(TAXES)
    .greaterThanOrEqualTo('added', startDate)
    .lessThan('added', endDate)
    .equalTo('type', type)
    .greaterThan('suma', 0)
    .descending('added');

  const totalTaxes = await query.count();
  const result = await query.skip(skip).limit(pageSize).find();
  const taxes = result.map((tax) => {
    return {
      id: tax.id,
      addedDate: tax.attributes.added,
      amount: tax.attributes.suma,
      information: tax.attributes.paslauga,
      owner: tax.attributes.owner
    };
  });

  return { taxes, totalTaxes };
};

export const getAllTaxesSumInYear = async (year, type) => {
  const startDate = new Date(year, 0, 1);
  const endDate = new Date(year + 1, 0, 1);
  const query = new Parse.Query(TAXES)
    .greaterThanOrEqualTo('added', startDate)
    .lessThan('added', endDate)
    .greaterThan('suma', 0)
    .equalTo('type', type);

  const taxes = await fetchAllRecords(query);
  const sum = taxes.reduce((accumulator, tax) => {
    return accumulator + tax.attributes.suma;
  }, 0);

  return sum;
};

export const getAllTaxesForExport = async (year, type) => {
  const startDate = new Date(year, 0, 1);
  const endDate = new Date(year + 1, 0, 1);
  const query = new Parse.Query(TAXES)
    .greaterThanOrEqualTo('added', startDate)
    .lessThan('added', endDate)
    .greaterThan('suma', 0)
    .equalTo('type', type)
    .descending('added');

  const taxes = await fetchAllRecords(query);
  return taxes.map((tax) => {
    return {
      id: tax.id,
      addedDate: tax.attributes.added,
      amount: tax.attributes.suma,
      owner: tax.attributes.owner
    };
  });
};
