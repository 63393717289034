import React, { useCallback, useState, useEffect } from 'react';
import { AdAdmin } from '../../components/landing/AdAdmin';
import { useSelector, useDispatch } from 'react-redux';
import { getPosts } from '../../redux/slices/postsSlice';
import { SALE, BUY, RENT, OTHER, ALL } from '../../consts/postCategories'; // Import the ALL category
import Header from '../../components/posts/PostTableHeader';

const AdminPostsList = () => {
  const categories = {
    SALE,
    BUY,
    RENT,
    OTHER,
    ALL // Add the ALL category
  };
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    dispatch(getPosts());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
    dispatch(getPosts());
  }, [dispatch, fetchData]);

  const categoryClasses = 'cursor-pointer hover:text-blue-900';
  const { posts } = useSelector((state) => state.posts);

  const defaultCategory = 'ALL';
  const [category, setCategory] = useState(defaultCategory);

  const filteredPosts = category === 'ALL' ? posts : posts.filter((post) => post.category === categories[category]);

  return (
    <div className="flex flex-col">
      <div className=" ml-2 flex flex-row mb-5 mt-2">
        {Object.keys(categories).map((key) => (
          <button
            key={key}
            onClick={() => {
              setCategory(key);
            }}
          >
            <span className={`mr-3 ${category !== key ? categoryClasses : 'underline'}`}>
              <strong>{categories[key]}</strong>
            </span>
          </button>
        ))}
      </div>
      <div className="mr-2 ml-2">
        <table className="items-center w-full bg-transparent border-collapse">
          <Header />
          {filteredPosts.map((post) => (
            <AdAdmin key={post.objectId} post={post} />
          ))}
        </table>
      </div>
    </div>
  );
};

export default AdminPostsList;
