import React from 'react';
import RevisionDocuments from '../../containers/dashboard/RevisionDocuments';
import ProtocolDocuments from '../../containers/dashboard/ProtocolDocuments';
import OtherMeetingsDocuments from '../../containers/dashboard/OtherMeetingsDocuments';

const MeetingsPage = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <RevisionDocuments />
        <ProtocolDocuments />
        <OtherMeetingsDocuments />
      </div>
    </div>
  );
};

export default MeetingsPage;
