import React, { useEffect, useState } from 'react';
import DebtTable from '../../components/payments/tables/DebtTable';
import { useDispatch, useSelector } from 'react-redux';
import { getDebt } from '../../redux/slices/debtSlice';
import { getElectricityDebt } from '../../redux/slices/electricityDebtSlice';
import { getWaterDebt } from '../../redux/slices/waterDebtSlice';
import { getOtherDebt } from '../../redux/slices/otherDebtSlice';
import { useNavigate } from 'react-router-dom';
import { LATE_INTEREST } from '../../consts/types';
import { insertTax } from '../../api/taxesService';
import { useCallback } from 'react';
import ConfirmationModal from '../../components/dashboard/ConfirmationModal';
import LinkButton from '../../components/dashboard/LinkButton';
import useConfirmationModal from '../../customHooks/useConfirmationModal';
import { getClient } from '../../api/clientsService';
import { updateClientDebts } from '../../api/debtsService';

const Debt = ({ id }) => {
  const { showModal, data, handleModalClick, closeModal } = useConfirmationModal();
  const { isAdmin } = useSelector((state) => state.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(() => {
    dispatch(getDebt(id));
    dispatch(getElectricityDebt(id));
    dispatch(getWaterDebt(id));
    dispatch(getOtherDebt(id));
  }, [dispatch, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAddLateInterest = async () => {
    const newTax = {
      owner: id,
      information: `Priskaičiuoti delspinigiai už ${data.year} metus`,
      type: LATE_INTEREST,
      amount: data.lateInterest
    };

    await insertTax(newTax);
    fetchData();
    closeModal();
  };

  const handleClick = async () => {
    try {
      setIsLoading(true);
      const client = await getClient(id);
      await updateClientDebts(client);
      await fetchData();
    } catch (error) {
      console.error('Error handling click:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded overflow-x-auto">
      <div className="rounded-t mb-0 px-1 py-3 border-0">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full px-0 max-w-full flex-grow flex-1">
            <h3 className="font-semibold text-base text-blueGray-700">Visa mokėjimų istorija</h3>
          </div>
        </div>
      </div>
      <DebtTable addLateInterestToDebt={handleModalClick} />
      <button
        className={`hover:shadow-md text-white font-bold uppercase text-xs mt-2 ml-2 mb-2 px-4 py-2 rounded shadow outline-none focus:outline-none 
              bg-lightBlue-500 active:bg-lightBlue-600`}
        type="button"
        onClick={() => handleClick()}
        disabled={isLoading}
      >
        {isLoading ? 'Atnaujinama...' : 'Atnaujinti'}
      </button>
      {isAdmin && (
        <LinkButton
          additionalClasses={'text-lightBlue-500 text-left px-4 py-2'}
          onClick={() => navigate(`/dashboard/clients/${id}/taxes`)}
        >
          Papildomi mokesčiai
        </LinkButton>
      )}
      {showModal && (
        <ConfirmationModal
          description="Ar įtraukti delspinigius į skolą?"
          handleConfirm={() => handleAddLateInterest()}
          handleClose={() => closeModal()}
        />
      )}
    </div>
  );
};

export default Debt;
