import React, { useEffect, useState } from 'react';
import Modal from '../modal/Modal';
import ModalHeader from '../modal/ModalHeader';
import ModalBody from '../modal/ModalBody';
import ModalFooter from '../modal/ModalFooter';

const ClientUpdateModal = ({
  isOpen,
  client,
  closeModal,
  handleClientUpdate,
  handlePasswordReset,
  generatedPassword
}) => {
  const [formData, setFormData] = useState({
    owner: '',
    address: '',
    phone: '',
    email: '',
    squares: '',
    withoutMeter: false,
    memberSince: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [copySuccess, setCopySuccess] = useState('');

  useEffect(() => {
    setErrorMessage('');
    if (client) {
      setFormData({ ...client, memberSince: client.memberSince || '' });
    }
  }, [client]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    if (name === 'squares') {
      setErrorMessage('');

      if (!value) {
        setErrorMessage('Garažo ploto laukelis yra būtinas');
      } else if (value <= 0) {
        setErrorMessage('Garažo plotas negali būti mažesnis nei 0');
      }
    }

    setFormData({
      ...formData,
      [name]: newValue
    });
  };

  const handleSubmit = () => {
    const clientData = {
      ...formData,
      squares: parseFloat(formData.squares),
      memberSince: formData.memberSince ? new Date(formData.memberSince) : null
    };
    handleClientUpdate(clientData);
  };

  const handleCopy = () => {
    if (generatedPassword) {
      navigator.clipboard.writeText(generatedPassword).then(
        () => {
          setCopySuccess('Slaptažodis nukopijuotas!');
          setTimeout(() => {
            setCopySuccess('');
          }, 2000);
        },
        (err) => {
          setCopySuccess('Nepavyko nukopijuoti!');
          console.error('Failed to copy: ', err);
        }
      );
    }
  };

  if (!client) {
    return <></>;
  }

  return (
    isOpen && (
      <Modal>
        <ModalHeader>{`Garažo Nr. ${client.garage} informacijos keitimas`}</ModalHeader>
        <ModalBody>
          <div className="container mx-auto">
            <div className="flex flex-wrap mb-2">
              <div className="w-1/3 px-4 flex items-center justify-end">
                <label htmlFor="owner" className="text-gray-700 text-sm mr-2">
                  Savininkas
                </label>
              </div>
              <div className="w-full px-4 flex-1 flex items-center justify-start">
                <input
                  type="text"
                  id="owner"
                  name="owner"
                  value={formData.owner || ''}
                  onChange={handleChange}
                  className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                />
              </div>
            </div>
            <div className="flex flex-wrap mb-2">
              <div className="w-1/3 px-4 flex items-center justify-end">
                <label htmlFor="address" className="text-gray-700 text-sm mr-2">
                  Adresas
                </label>
              </div>
              <div className="w-full px-4 flex-1 flex items-center justify-start">
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address || ''}
                  onChange={handleChange}
                  className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                />
              </div>
            </div>
            <div className="flex flex-wrap mb-2">
              <div className="w-1/3 px-4 flex items-center justify-end">
                <label htmlFor="phone" className="text-gray-700 text-sm mr-2">
                  Telefonas
                </label>
              </div>
              <div className="w-full px-4 flex-1 flex items-center justify-start">
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone || ''}
                  onChange={handleChange}
                  className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                />
              </div>
            </div>
            <div className="flex flex-wrap mb-2">
              <div className="w-1/3 px-4 flex items-center justify-end">
                <label htmlFor="email" className="text-gray-700 text-sm mr-2">
                  E. paštas
                </label>
              </div>
              <div className="w-full px-4 flex-1 flex items-center justify-start">
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={formData.email || ''}
                  onChange={handleChange}
                  className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                />
              </div>
            </div>
            <div className="flex flex-wrap mb-2">
              <div className="w-1/3 px-4 flex items-center justify-end">
                <label htmlFor="squares" className="text-gray-700 text-sm mr-2">
                  Garažo plotas
                </label>
              </div>
              <div className="w-full px-4 flex-1">
                <input
                  type="number"
                  id="squares"
                  name="squares"
                  value={formData.squares}
                  onChange={handleChange}
                  min={0}
                  className={`px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full ${
                    errorMessage && 'border-red-500'
                  }`}
                />
              </div>
            </div>
            {errorMessage && (
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3"></div>
                <div className="w-full px-4 flex-1">
                  <label className="text-sm text-red-500">{errorMessage}</label>
                </div>
              </div>
            )}
            <div className="flex flex-wrap mb-2">
              <div className="w-1/3 px-4 flex items-center justify-end">
                <label htmlFor="withoutMeter" className="text-gray-700 text-sm mr-2">
                  Be skaitliuko
                </label>
              </div>
              <div className="w-full px-4 flex-1 flex items-center justify-start" style={{ padding: '7px 1rem' }}>
                <input
                  type="checkbox"
                  id="withoutMeter"
                  name="withoutMeter"
                  checked={formData.withoutMeter}
                  onChange={handleChange}
                  className="rounded shadow outline-none focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
            <div className="flex flex-wrap mb-2">
              <div className="w-1/3 px-4 flex items-center justify-end">
                <label htmlFor="memberSince" className="text-gray-700 text-sm mr-2">
                  Narys nuo
                </label>
              </div>
              <div className="w-full px-4 flex-1 flex items-center justify-start">
                <input
                  type="date"
                  id="memberSince"
                  name="memberSince"
                  value={formData.memberSince || ''}
                  onChange={handleChange}
                  className="rounded shadow outline-none focus:outline-none focus:shadow-outline"
                  style={{ padding: '2px 12px' }}
                />
              </div>
            </div>
            <div className="flex justify-center mt-10">
              <button
                className="text-gray-700 bg-transparent border border-solid border-gray-700 hover:bg-gray-100 active:bg-gray-200 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none"
                type="button"
                onClick={handlePasswordReset}
                disabled={generatedPassword} // TODO: Enable button when email sending is implemented
              >
                Generuoti naują slaptažodį
              </button>
            </div>
            <div className="flex justify-center mt-2">
              {generatedPassword && (
                <div className="flex-col flex gap-2 text-center items-center">
                  <label className="text-gray-700 text-lg mr-2">
                    Sugeneruotas slaptažodis: <span className="font-bold text-green-700">{generatedPassword}</span>
                  </label>
                  <button
                    className="w-1/2 text-gray-700 bg-transparent border border-solid border-gray-700 hover:bg-gray-100 active:bg-gray-200 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none"
                    onClick={handleCopy}
                  >
                    Kopijuoti
                  </button>
                  {copySuccess && <span className="text-green-500 text-sm ml-2">{copySuccess}</span>}
                </div>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="text-red-500 background-transparent font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
            type="button"
            onClick={closeModal}
          >
            Uždaryti
          </button>
          <button
            className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ${
              errorMessage && 'cursor-not-allowed'
            }`}
            type="button"
            disabled={errorMessage}
            onClick={handleSubmit}
          >
            Saugoti
          </button>
        </ModalFooter>
      </Modal>
    )
  );
};

export default ClientUpdateModal;
