import React, { useCallback, useEffect, useState } from 'react';
import PageTab from '../../components/dashboard/PageTab';
import PageTabTitle from '../../components/dashboard/PageTabTitle';
import PageTabBody from '../../components/dashboard/PageTabBody';
import { ELECTRICITY } from '../../consts/types';
import { useDispatch, useSelector } from 'react-redux';
import { getElectricityDebt } from '../../redux/slices/electricityDebtSlice';
import UtilitiesTable from '../../components/payments/tables/UtilitiesTable';
import { getWaterDebt } from '../../redux/slices/waterDebtSlice';
import DeleteLastRecord from '../../components/clientUtilitiesRecords/DeleteLastRecord';
import {
  changeTaxStatusTo,
  insertTax,
  normalizeClientTaxesAfterMeterChange,
  removeLastUnsavedTax,
  updateReadingData
} from '../../api/taxesService';
import PaymentAcceptanceModal from '../../components/clientUtilitiesRecords/PaymentAcceptanceModal';
import AddPaymentModal from '../../components/paymentHistory/AddPaymentModal';
import NewUtilityRecord from '../../components/clientUtilitiesRecords/NewUtilityRecord';
import { getUtilitiesPrices } from '../../redux/slices/utilitiesPricesSlice';

const ClientUtilitiesRecords = ({ id, type }) => {
  const [isPaymentAcceptanceModalOpen, setIsPaymentAcceptanceModalOpen] = useState(false);
  const [selectedTax, setSelectedTax] = useState(null);
  const [isNewPaymentModalOpen, setIsNewPaymentModalOpen] = useState(false);
  const [isPaymentAutomatic, setIsPaymentAutomatic] = useState(false);
  const { electricityTaxes } = useSelector((state) => state.electricityDebt);
  const { waterTaxes } = useSelector((state) => state.waterDebt);
  const { electricityPrice, waterPrice } = useSelector((state) => state.utilitiesPrices);
  const dispatch = useDispatch();

  const title = type === ELECTRICITY ? 'Elektros apskaita' : 'Vandens apskaita';
  const data = type === ELECTRICITY ? electricityTaxes : waterTaxes;

  const fetchData = useCallback(() => {
    if (type === ELECTRICITY) {
      dispatch(getElectricityDebt(id));
    } else {
      dispatch(getWaterDebt(id));
    }
  }, [dispatch, type, id]);

  useEffect(() => {
    dispatch(getUtilitiesPrices());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const openPaymentAcceptanceModal = (id) => {
    if (type === ELECTRICITY) {
      setSelectedTax(electricityTaxes.find((x) => x.id === id));
    } else {
      setSelectedTax(waterTaxes.find((x) => x.id === id));
    }

    setIsPaymentAcceptanceModalOpen(true);
  };

  const closePaymentAcceptanceModal = () => {
    setIsPaymentAcceptanceModalOpen(false);
    setSelectedTax(null);
  };

  const openNewPaymentModal = (isAutomatic) => {
    setIsNewPaymentModalOpen(true);
    setIsPaymentAutomatic(isAutomatic);

    setIsPaymentAcceptanceModalOpen(false);
  };

  const handleAutomaticPayment = async () => {
    await changeTaxStatusTo(selectedTax.id, 1);
    fetchData();

    setSelectedTax(null);
  };

  const handleForcedPayment = async () => {
    await changeTaxStatusTo(selectedTax.id, 2);
    fetchData();

    closePaymentAcceptanceModal();
  };

  const deletePayment = async () => {
    await removeLastUnsavedTax(id, type);
    fetchData();
  };

  const handleNewUtilityRecord = async (formData) => {
    const pricePerUnit = type === ELECTRICITY ? electricityPrice : waterPrice;

    if (!data.length || formData.isChanged) {
      await normalizeClientTaxesAfterMeterChange(id, type);

      const newTax = {
        firstReading: formData.reading,
        owner: id,
        type
      };

      await insertTax(newTax);
    } else if (data[0].secondReading) {
      const newTax = {
        firstReading: data[0].secondReading,
        secondReading: formData.reading,
        owner: id,
        type,
        amount: (formData.reading - data[0].secondReading) * pricePerUnit
      };

      await insertTax(newTax);
    } else {
      const readingData = {
        secondReading: formData.reading,
        total: (formData.reading - data[0].firstReading) * pricePerUnit
      };

      await updateReadingData(data[0].id, readingData);
    }

    fetchData();
  };

  const getLatestReading = () => {
    if (data.length) {
      return data[0].secondReading ? data[0].secondReading : data[0].firstReading;
    }

    return null;
  };

  return (
    <PageTab>
      <PageTabTitle>{title}</PageTabTitle>
      <PageTabBody>
        <NewUtilityRecord latestReading={getLatestReading()} onSubmit={handleNewUtilityRecord} />
        <UtilitiesTable data={data} acceptPayment={openPaymentAcceptanceModal} />
        <DeleteLastRecord deleteLastPayment={deletePayment} />
        <PaymentAcceptanceModal
          isOpen={isPaymentAcceptanceModalOpen}
          clientId={id}
          taxData={selectedTax}
          closeModal={closePaymentAcceptanceModal}
          openNewPaymentModal={openNewPaymentModal}
          handleForcedPayment={handleForcedPayment}
        />
        <AddPaymentModal
          isOpen={isNewPaymentModalOpen}
          clientId={id}
          closeModal={() => setIsNewPaymentModalOpen(false)}
          additionalHandleOnSubmit={isPaymentAutomatic && handleAutomaticPayment}
          defaultState={
            isPaymentAutomatic && selectedTax
              ? {
                  amount: selectedTax.total,
                  information: `Mokestis už garažo nr. ${id} ${type === ELECTRICITY ? 'elektrą' : 'vandenį'} nuo ${
                    selectedTax.firstReading
                  } iki ${selectedTax.secondReading}`,
                  isBank: false
                }
              : null
          }
        />
      </PageTabBody>
    </PageTab>
  );
};

export default ClientUtilitiesRecords;
