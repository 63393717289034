import React from 'react';
import HeaderCell from '../../table/HeaderCell';

const DebtTableHeader = () => {
  return (
    <thead>
      <tr>
        <HeaderCell rowSpan={3}>Metai</HeaderCell>
        <HeaderCell rowSpan={3}>Skola</HeaderCell>
        <HeaderCell colSpan={8}>Sąskaita</HeaderCell>
        <HeaderCell rowSpan={3} customWrap={'whitespace-normal'}>
          Dalinis padengimas
        </HeaderCell>
      </tr>
      <tr>
        <HeaderCell rowSpan={2} customWrap={'whitespace-normal'}>
          I-as pusmetis
        </HeaderCell>
        <HeaderCell rowSpan={2} customWrap={'whitespace-normal'}>
          II-as pusmetis
        </HeaderCell>
        <HeaderCell colSpan={2}>Elektra</HeaderCell>
        <HeaderCell colSpan={2}>Vanduo</HeaderCell>
        <HeaderCell rowSpan={2}>Kita</HeaderCell>
        <HeaderCell rowSpan={2}>Iš viso</HeaderCell>
      </tr>
      <tr>
        <HeaderCell>Atsiskaityta</HeaderCell>
        <HeaderCell>Neatsiskaityta</HeaderCell>
        <HeaderCell>Atsiskaityta</HeaderCell>
        <HeaderCell>Neatsiskaityta</HeaderCell>
      </tr>
    </thead>
  );
};

export default DebtTableHeader;
