import React from 'react';
import LandingNavbar from '../../components/landing/LandingNavbar';
import UpperImage from '../../components/landing/UpperImage';
import Footer from '../../components/landing/Footer';
import AdsList from '../../containers/landing/AdsList';

const Ads = () => {
  return (
    <>
      <LandingNavbar />
      <UpperImage />

      <section className="relative py-16 bg-blueGray-200">
        <div className="container mx-auto px-4">
          <div className="relative flex flex-col bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
            <div className="px-6 py-6">
              <div className="flex flex-col flex-wrap">
                <div className="w-full w-auto px-4 mb-5">
                  <div className="flex flex-wrap justify-center border-b">
                    <p className="mb-4 text-lg text-2xl font-bold">SKELBIMAI</p>
                  </div>
                </div>
                <AdsList />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Ads;
