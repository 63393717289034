import React from 'react';
import PaymentHistory from '../../containers/dashboard/PaymentHistory';

const PaymentHistoryPage = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <PaymentHistory />
      </div>
    </div>
  );
};

export default PaymentHistoryPage;
