import React, { useEffect } from 'react';
import PageTab from '../../components/dashboard/PageTab';
import PageTabTitle from '../../components/dashboard/PageTabTitle';
import PageTabBody from '../../components/dashboard/PageTabBody';
import { useDispatch } from 'react-redux';
import { getUtilitiesPrices } from '../../redux/slices/utilitiesPricesSlice';
import UtilityCosts from '../../components/settings/UtilityCosts';
import { updateCostFor } from '../../api/utilitiesPricesService';

const UtilityCostsSettings = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUtilitiesPrices());
  }, [dispatch]);

  const updateUtilityCost = async (utilityName, newPrice) => {
    await updateCostFor(utilityName, newPrice);
    dispatch(getUtilitiesPrices());
  };

  return (
    <PageTab>
      <PageTabTitle>Kainos</PageTabTitle>
      <PageTabBody>
        <UtilityCosts onSubmit={updateUtilityCost} />
      </PageTabBody>
    </PageTab>
  );
};

export default UtilityCostsSettings;
