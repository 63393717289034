import React, { useCallback, useEffect } from 'react';
import Pagination from '../../components/dashboard/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClientsWithUnconfirmedInfo,
  setCurrentPage,
  setPageSize
} from '../../redux/slices/clientsWithUnconfirmedInfoSlice';
import ClientsUnconfirmedInfoTable from '../../components/clientsUnconfirmedInfo/ClientsUnconfirmedInfoTable';
import { confirmInformation, rejectInformation } from '../../api/clientsService';

const ClientsWithUnconfirmedInfo = () => {
  const { isLoading, currentPage, totalPages, pageSize } = useSelector((state) => state.clientsWithUnconfirmedInfo);
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    dispatch(getClientsWithUnconfirmedInfo());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const confirmNewInformation = async (clientId, column) => {
    await confirmInformation(clientId, column);
    dispatch(getClientsWithUnconfirmedInfo());
  };

  const rejectNewInformation = async (clientId, column) => {
    await rejectInformation(clientId, column);
    dispatch(getClientsWithUnconfirmedInfo());
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage({ page }));
    fetchData();
  };

  const handlePageSizeChange = (pageSize) => {
    dispatch(setPageSize({ pageSize }));
    fetchData();
  };

  return (
    <div className="relative min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded overflow-x-auto">
      <div className="rounded-t mb-0 px-4 py-3 border-0">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 className="font-semibold text-base text-blueGray-700">Informacijos patvirtinimas</h3>
          </div>
        </div>
      </div>
      <ClientsUnconfirmedInfoTable onConfirm={confirmNewInformation} onReject={rejectNewInformation} />
      <Pagination
        data={{ isLoading, currentPage, totalPages, pageSize }}
        onPageChange={handlePageChange}
        onPageSizechange={handlePageSizeChange}
      />
    </div>
  );
};

export default ClientsWithUnconfirmedInfo;
