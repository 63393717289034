import Parse from 'parse';
import { UTILITIES_PRICES } from '../consts/tables';
import { ELECTRICITY_PRICE, WATER_PRICE } from '../consts/utilityCostSettings';
import { getRoleByName } from './rolesService';
import { ADMIN_ROLE } from '../consts/roles';

export const getPrices = async () => {
  const prices = await new Parse.Query(UTILITIES_PRICES).find();

  const electricityPrice =
    prices.find((price) => price.attributes.optionas === ELECTRICITY_PRICE)?.attributes.value ?? 0;
  const waterPrice = prices.find((price) => price.attributes.optionas === WATER_PRICE)?.attributes.value ?? 0;

  return { electricityPrice, waterPrice };
};

export const updateCostFor = async (utilityName, newPrice) => {
  const utilityCost = await new Parse.Query(UTILITIES_PRICES).equalTo('optionas', utilityName).first();
  utilityCost.set('value', newPrice);

  const newACL = new Parse.ACL();
  const administratorRole = await getRoleByName(ADMIN_ROLE);
  newACL.setRoleReadAccess(administratorRole, true);
  newACL.setRoleWriteAccess(administratorRole, true);
  utilityCost.setACL(newACL);

  await utilityCost.save();
};
