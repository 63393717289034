import React from 'react';
import { useDispatch } from 'react-redux';
import { openMessage } from '../../redux/slices/messagesSlice';
import { setMessageAsRead } from '../../api/messagesService';

const MessageItem = ({ message }) => {
  const { id, subject, sent, read, userId } = message;
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(openMessage({ messageId: id }));

    if (!read) {
      setMessageAsRead(id, userId);
    }
  };

  return (
    <div className="hover:cursor-pointer" onClick={handleClick}>
      <span style={{ marginRight: '1rem', color: '#660000' }}>{sent}</span>
      <span style={read ? {} : { color: '#0088cc' }}>{subject ? subject : 'Pranešimas be temos'}</span>
    </div>
  );
};

export default MessageItem;
