import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/taxes/Header';
import Taxes from '../../containers/dashboard/Taxes';

const AdditionalTaxPage = () => {
  const { clientId } = useParams();

  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <Header id={clientId} />
        <Taxes id={clientId} />
      </div>
    </div>
  );
};

export default AdditionalTaxPage;
