import Parse from 'parse';
import { generatePassword } from '../helpers/passwordHelpers';
import { addUserToRole, getRoleByName } from './rolesService';
import { ADMIN_ROLE, CLIENT_ROLE } from '../consts/roles';

export const login = async (username, password, recaptchaToken) => {
  try {
    const captchaResponse = await fetch(process.env.REACT_APP_PARSE_SERVER_URL + '/verify-recaptcha', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ recaptchaToken })
    });
    const captchaData = await captchaResponse.json();

    if (!captchaData.success) {
      throw new Error('Please complete the reCAPTCHA verification.');
    }

    return await Parse.User.logIn(username, password);
  } catch (error) {
    throw new Error('An error occurred during login.');
  }
};

export const logout = async () => {
  return await Parse.User.logOut();
};

export const getCurrentUser = async () => {
  return Parse.User.current()?.toJSON();
};

export const getUserByUsername = async (username) => {
  const query = new Parse.Query(Parse.User).equalTo('username', username);
  try {
    return await query.first();
  } catch (error) {
    console.error('Error finding user by username:', error);
    return null;
  }
};

export const getUserById = async (id) => {
  const query = new Parse.Query(Parse.User).equalTo('data', id);
  try {
    return await query.first();
  } catch (error) {
    console.error('Error finding user by username:', error);
    return null;
  }
};

export const isAuthenticated = () => {
  const currentUser = Parse.User.current();
  return currentUser !== null;
};

export const getUserRoles = async () => {
  // Get the currently logged-in user
  const currentUser = Parse.User.current();

  // If user logged in, return his roles
  if (currentUser) {
    try {
      return (await new Parse.Query(Parse.Role).equalTo('users', currentUser).find()).map(
        (role) => role.attributes.name
      );
    } catch (error) {
      console.error('Error fetching user roles:', error);
    }
  }
};

export const changePassword = async (newPassword, id) => {
  const user = id ? await new Parse.Query(Parse.User).equalTo('data', id).first() : Parse.User.current();
  user.setPassword(newPassword);
  await user.save();
};

export const removeUser = async (id) => {
  const query = new Parse.Query(Parse.User).equalTo('data', id);
  const user = await query.first();
  await user.destroy();
};

export const insertUser = async (client) => {
  const administratorRole = await getRoleByName(ADMIN_ROLE);

  const user = new Parse.User();

  user.set('username', client.attributes.garage);
  user.set('data', client);
  user.set('password', generatePassword(12));

  const newACL = new Parse.ACL();
  newACL.setRoleReadAccess(administratorRole, true);
  newACL.setRoleWriteAccess(administratorRole, true);
  user.setACL(newACL);

  await user.save();
  await addUserToRole(user, CLIENT_ROLE);

  return user.id;
};
