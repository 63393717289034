import React from 'react';
import ClientPaymentHistoryTableHeader from './ClientPaymentHistoryTableHeader';
import ClientPaymentHistoryTableBody from './ClientPaymentHistoryTableBody';

const ClientPaymentHistoryTable = () => {
  return (
    <table className="items-center w-full bg-transparent border-collapse">
      <ClientPaymentHistoryTableHeader />
      <ClientPaymentHistoryTableBody />
    </table>
  );
};

export default ClientPaymentHistoryTable;
