import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ELECTRICITY_PRICE, WATER_PRICE } from '../../consts/utilityCostSettings';
import UtilityCostInput from './UtilityCostInput';

const UtilityCosts = ({ onSubmit }) => {
  const { electricityPrice, waterPrice } = useSelector((state) => state.utilitiesPrices);
  const [costs, setCosts] = useState({ [ELECTRICITY_PRICE]: null, [WATER_PRICE]: null });
  const [errors, setErrors] = useState({ [ELECTRICITY_PRICE]: '', [WATER_PRICE]: '' });

  useEffect(() => {
    setCosts({ [ELECTRICITY_PRICE]: electricityPrice, [WATER_PRICE]: waterPrice });
  }, [electricityPrice, waterPrice]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = parseFloat(value);

    setErrors({ ...errors, [name]: '' });
    if (newValue < 0) {
      setErrors({ ...errors, [name]: 'Kaina negali būti neigiamas skaičius' });
    }

    setCosts({ ...costs, [name]: newValue });
  };

  const handleSubmit = () => {
    if (electricityPrice !== costs[ELECTRICITY_PRICE]) {
      onSubmit(ELECTRICITY_PRICE, costs[ELECTRICITY_PRICE]);
    }

    if (waterPrice !== costs[WATER_PRICE]) {
      onSubmit(WATER_PRICE, costs[WATER_PRICE]);
    }
  };

  return (
    <div className="container mx-auto">
      <UtilityCostInput
        labelText={'Elektros kaina'}
        utilityName={ELECTRICITY_PRICE}
        value={costs[ELECTRICITY_PRICE]}
        handleChange={handleChange}
        errorMessage={errors[ELECTRICITY_PRICE]}
      />
      <UtilityCostInput
        labelText={'Vandens kaina'}
        utilityName={WATER_PRICE}
        value={costs[WATER_PRICE]}
        handleChange={handleChange}
        errorMessage={errors[WATER_PRICE]}
      />
      <div className="flex flex-wrap mb-2 mt-4">
        <div className="w-1/3 px-4 flex items-center justify-center">
          <button
            className={`hover:shadow-md text-white font-bold uppercase text-xs px-4 py-2 rounded shadow outline-none focus:outline-none mr-2 ${
              errors[ELECTRICITY_PRICE] || errors[WATER_PRICE]
                ? 'bg-lightBlue-300 cursor-not-allowed'
                : 'bg-lightBlue-500 active:bg-lightBlue-600'
            }`}
            type="button"
            onClick={handleSubmit}
            disabled={errors[ELECTRICITY_PRICE] || errors[WATER_PRICE]}
          >
            Saugoti
          </button>
        </div>
      </div>
    </div>
  );
};

export default UtilityCosts;
