import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { generateOrderCopy } from './orderPdfHelpers';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const openPdf = async (order) => {
  const documentDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [15, 15],
    content: [
      {
        table: {
          widths: ['*'],
          body: [
            [generateOrderCopy(order)],
            [{ text: '', border: [false, false, false, false] }],
            [generateOrderCopy(order)]
          ]
        }
      }
    ]
  };

  pdfMake.createPdf(documentDefinition).open();
};
