import React, { useState } from 'react';
import Modal from '../modal/Modal';
import ModalHeader from '../modal/ModalHeader';
import ModalBody from '../modal/ModalBody';
import ModalFooter from '../modal/ModalFooter';
import { getTrimmedData } from '../../helpers/formHelpers';
import { SALE, BUY, RENT, OTHER } from '../../consts/postCategories';

const PostCreate = ({ addNewPost }) => {
  const [isOpen, setIsOpen] = useState(false);

  const initializeModal = () => {
    setIsOpen(true);
  };

  const handleSubmit = async () => {
    const formData = getTrimmedData('new-post');
    const clientData = {
      ...formData
    };

    await addNewPost(clientData);
    setIsOpen(false);
  };

  return (
    <>
      <button
        className="bg-blue-400 text-white hover:bg-blue-500 active:bg-blue-600 font-bold uppercase text-sm px-2 py-2 rounded outline-none focus:outline-none m-1 mb-2"
        type="button"
        onClick={initializeModal}
      >
        Pridėti naują skelbimą
      </button>

      {isOpen && (
        <Modal>
          <ModalHeader>Naujas skelbimas</ModalHeader>
          <ModalBody>
            <form className="container mx-auto" id="new-post">
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="category" className="text-gray-700 text-sm mr-2">
                    Kategorija
                  </label>
                </div>
                <div className="w-full px-4 flex-1 flex items-center justify-start">
                  <select
                    type="text"
                    id="category"
                    name="category"
                    className={`px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full`}
                  >
                    <option value={SALE}>{SALE}</option>
                    <option value={BUY}>{BUY}</option>
                    <option value={RENT}>{RENT}</option>
                    <option value={OTHER}>{OTHER}</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="name" className="text-gray-700 text-sm mr-2">
                    Vardas
                  </label>
                </div>
                <div className="w-full px-4 flex-1 flex items-center justify-start">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className={`px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full`}
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="contact" className="text-gray-700 text-sm mr-2">
                    Kontaktai
                  </label>
                </div>
                <div className="w-full px-4 flex-1 flex items-center justify-start">
                  <input
                    type="text"
                    id="contact"
                    name="contact"
                    className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="message" className="text-gray-700 text-sm mr-2">
                    Skelbimo turinys
                  </label>
                </div>
                <div className="w-full px-4 flex-1 flex items-center justify-start">
                  <textarea
                    type="text"
                    id="message"
                    name="message"
                    className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                    style={{ height: '200px' }}
                  />
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              className="text-red-500 background-transparent font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
              type="button"
              onClick={() => setIsOpen(false)}
            >
              Uždaryti
            </button>
            <button
              className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1
              `}
              type="button"
              onClick={handleSubmit}
            >
              Skelbti
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default PostCreate;
