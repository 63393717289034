import React, { useCallback, useEffect } from 'react';
import PageTab from '../../components/dashboard/PageTab';
import PageTabTitle from '../../components/dashboard/PageTabTitle';
import PageTabBody from '../../components/dashboard/PageTabBody';
import { useDispatch, useSelector } from 'react-redux';
import { getBaseTaxes } from '../../redux/slices/baseTaxesSlice';
import AnnualTaxes from '../../components/settings/AnnualTaxes';
import { CURRENT_YEAR } from '../../helpers/dateHelpers';
import { upsertBaseTax } from '../../api/baseTaxesService';
import { round } from '../../helpers/numberHelpers';

const BaseTaxesSettings = () => {
  const { baseTaxes } = useSelector((state) => state.baseTaxes);
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    dispatch(getBaseTaxes());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateBaseTax = async (newPrice) => {
    // Always update the latest record
    const currentYearBaseTax = baseTaxes.find((x) => x.year === CURRENT_YEAR);
    const roundedValue = round(newPrice, 2);
    await upsertBaseTax(currentYearBaseTax?.id, roundedValue);

    fetchData();
  };

  return (
    <PageTab>
      <PageTabTitle>Baziniai mokesčiai</PageTabTitle>
      <PageTabBody>
        <AnnualTaxes data={baseTaxes} onSubmit={updateBaseTax} />
      </PageTabBody>
    </PageTab>
  );
};

export default BaseTaxesSettings;
