import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  garageNumber: '',
  owner: '',
  garageDebt: false,
  electricityDebt: false,
  waterDebt: false,
  member: false,
  debtFrom: 0
};

const clientsFilterSlice = createSlice({
  name: 'clientsFilter',
  initialState,
  reducers: {
    reset: () => initialState,
    changeFilter: (state, { payload }) => {
      const { name, value, type, checked } = payload;
      let newValue = value;

      if (name === 'debtFrom') {
        newValue = value && Math.abs(newValue);
      }

      state[name] = type === 'checkbox' ? checked : newValue;
    }
  }
});

export const { reset, changeFilter } = clientsFilterSlice.actions;
export default clientsFilterSlice.reducer;
