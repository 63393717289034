import React, { useState } from 'react';
import Modal from '../modal/Modal';
import ModalHeader from '../modal/ModalHeader';
import ModalBody from '../modal/ModalBody';
import ModalFooter from '../modal/ModalFooter';
import { useDispatch } from 'react-redux';
import { getTaxes } from '../../redux/slices/taxesSlice';
import { insertTax } from '../../api/taxesService';

const AddTaxModal = ({ clientId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    amount: 0,
    information: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setErrorMessage('');
    if (name === 'amount' && value <= 0) {
      setErrorMessage('Suma turi būti didesnė nei 0');
    }

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async () => {
    const newTax = {
      amount: parseFloat(formData.amount),
      information: formData.information,
      owner: clientId,
      type: formData.information.toLowerCase().includes('skola') ? 'skola' : 'mokestis'
    };

    if (newTax.amount <= 0) {
      setErrorMessage('Suma turi būti didesnė nei 0');
      return;
    }

    await insertTax(newTax);
    dispatch(getTaxes(clientId));
    setIsOpen(false);
    setFormData({
      amount: 0,
      information: ''
    });
  };

  return (
    <>
      <button
        className="bg-blue-400 text-white hover:bg-blue-500 active:bg-blue-600 font-bold uppercase text-sm px-2 py-2 rounded outline-none focus:outline-none m-1"
        type="button"
        onClick={() => setIsOpen(true)}
      >
        Pridėti naują mokestį
      </button>
      {isOpen && (
        <Modal>
          <ModalHeader>Naujas mokestis</ModalHeader>
          <ModalBody>
            <div className="container mx-auto">
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="amount" className="text-gray-700 text-sm mr-2">
                    Suma
                  </label>
                </div>
                <div className="w-full px-4 flex-1">
                  <input
                    type="number"
                    id="amount"
                    name="amount"
                    value={formData.amount}
                    onChange={(e) => handleChange(e)}
                    min={0}
                    className={`px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full ${
                      errorMessage && 'border-red-500'
                    }`}
                  />
                </div>
              </div>
              {errorMessage && (
                <div className="flex flex-wrap mb-2">
                  <div className="w-1/3"></div>
                  <div className="w-full px-4 flex-1">
                    <label className="text-sm text-red-500">{errorMessage}</label>
                  </div>
                </div>
              )}
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="information" className="text-gray-700 text-sm mr-2">
                    Mokesčio aprašymas
                  </label>
                </div>
                <div className="w-full px-4 flex-1 flex items-center justify-start">
                  <input
                    type="text"
                    id="information"
                    name="information"
                    value={formData.information}
                    onChange={(e) => handleChange(e)}
                    className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="text-red-500 background-transparent font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
              type="button"
              onClick={() => setIsOpen(false)}
            >
              Uždaryti
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button"
              onClick={handleSubmit}
            >
              Saugoti
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default AddTaxModal;
