import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllMessages, getUnreadCount } from '../../api/messagesService';
import { getFormattedDateTime } from '../../helpers/dateHelpers';

const initialState = {
  messages: [],
  currentPage: 1,
  pageSize: 10,
  totalPages: 0,
  isLoading: false,
  unreadMessagesCount: 0,
  openedMessage: null
};

export const getMessages = createAsyncThunk('messages/getMessages', async (_, thunkAPI) => {
  const currentState = thunkAPI.getState();
  const page = currentState.messages.currentPage;
  const pageSize = currentState.messages.pageSize;

  const skip = (page - 1) * pageSize;
  const result = await getAllMessages(skip, pageSize);
  const { messages, totalMessages } = result;

  const totalPages = Math.ceil(totalMessages / pageSize);

  // set unread message count because new messages might have been delivered
  const unreadMessagesCount = await getUnreadCount();

  return { messages, totalPages, unreadMessagesCount };
});

export const getUnreadMessagesCount = createAsyncThunk('messages/getUnreadMessagesCount', async () => {
  return await getUnreadCount();
});

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setPageSize: (state, { payload }) => {
      state.pageSize = payload.pageSize;
      state.currentPage = 1;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload.page;
    },
    openMessage: (state, { payload }) => {
      const openedMessageId = payload.messageId;
      const openedMessage = state.messages.find((message) => message.id === openedMessageId);

      if (!openedMessage.read) {
        state.unreadMessagesCount--;
      }

      openedMessage.read = true;
      state.openedMessage = openedMessage;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMessages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.totalPages = action.payload.totalPages;
        state.messages = action.payload.messages.map((message) => {
          message.sent = getFormattedDateTime(message.sent);
          return message;
        });

        state.openedMessage = null;
        state.unreadMessagesCount = action.payload.unreadMessagesCount;
      })
      .addCase(getMessages.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getUnreadMessagesCount.fulfilled, (state, action) => {
        state.unreadMessagesCount = action.payload;
      });
  }
});

export const { setPageSize, setCurrentPage, openMessage } = messagesSlice.actions;
export default messagesSlice.reducer;
