import Parse from 'parse';
import { getCurrentUser } from './userService';
import { MESSAGES } from '../consts/tables';
import { getRoleByName } from './rolesService';
import { ADMIN_ROLE } from '../consts/roles';

export const getAllMessages = async (skip, pageSize) => {
  const query = new Parse.Query(MESSAGES);

  const totalMessages = await query.count();
  const result = await query.descending('sent').skip(skip).limit(pageSize).find();
  const messages = result.map((message) => {
    return {
      id: message.id,
      subject: message.attributes.subject,
      body: message.attributes.body,
      read: message.attributes.read,
      sent: message.attributes.sent,
      userId: message.attributes.user
    };
  });

  return { messages, totalMessages };
};

export const deleteMessage = async (id) => {
  const messageObject = await new Parse.Query(MESSAGES).get(id);
  await messageObject.destroy();
};

export const setMessageAsRead = async (id, userId) => {
  const currentUser = await getCurrentUser();

  // set message as read only when its' recipient opened it
  if (currentUser.data.objectId === userId) {
    const messageObject = await new Parse.Query(MESSAGES).get(id);
    messageObject.set('read', true);
    await messageObject.save();
  }
};

export const getUnreadCount = async () => {
  const currentUser = await getCurrentUser();
  return await new Parse.Query(MESSAGES).equalTo('user', currentUser.data.objectId).equalTo('read', false).count();
};

export const sendMessage = async (props) => {
  const MessageObject = Parse.Object.extend(MESSAGES);
  const currentDateTime = new Date();
  try {
    for (let user of props.selectedUsers) {
      if (!user.userId) {
        console.log('Invalid userId for user:', user);
        continue;
      }
      const newMessage = new MessageObject();

      newMessage.set('sent', currentDateTime);
      newMessage.set('subject', props.subject);
      newMessage.set('body', props.message);
      newMessage.set('user', String(user.id));
      newMessage.set('read', false);

      const administratorRole = await getRoleByName(ADMIN_ROLE);
      const newACL = new Parse.ACL();

      const userIdString = String(user.userId);
      newACL.setReadAccess(userIdString, true);
      newACL.setWriteAccess(userIdString, true);
      newACL.setRoleReadAccess(administratorRole, true);
      newACL.setRoleWriteAccess(administratorRole, true);
      newMessage.setACL(newACL);

      await newMessage.save();
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
