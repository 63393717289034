import Parse from 'parse';
import { BASE_TAXES } from '../consts/tables';
import { CURRENT_YEAR } from '../helpers/dateHelpers';
import { getRoleByName } from './rolesService';
import { ADMIN_ROLE } from '../consts/roles';

export const getAllBaseTaxes = async () => {
  const baseTaxes = await new Parse.Query(BASE_TAXES).find();

  return baseTaxes.map((baseTax) => ({
    id: baseTax.id,
    year: baseTax.attributes.metai,
    tax: parseFloat(baseTax.attributes.kaina)
  }));
};

export const insertBaseTax = async (newTax) => {
  const BaseTaxObject = Parse.Object.extend(BASE_TAXES);
  const newBaseTax = new BaseTaxObject();

  newBaseTax.set('metai', CURRENT_YEAR);
  newBaseTax.set('kaina', newTax);

  const newACL = new Parse.ACL();
  const administratorRole = await getRoleByName(ADMIN_ROLE);
  newACL.setRoleReadAccess(administratorRole, true);
  newACL.setRoleWriteAccess(administratorRole, true);
  newBaseTax.setACL(newACL);

  await newBaseTax.save();
};

export const updateBaseTax = async (id, newTax) => {
  const baseTax = await new Parse.Query(BASE_TAXES).get(id);
  baseTax.set('kaina', newTax);

  const newACL = new Parse.ACL();
  const administratorRole = await getRoleByName(ADMIN_ROLE);
  newACL.setRoleReadAccess(administratorRole, true);
  newACL.setRoleWriteAccess(administratorRole, true);
  baseTax.setACL(newACL);

  await baseTax.save();
};

export const upsertBaseTax = async (id, newTax) => {
  if (id) {
    await updateBaseTax(id, newTax);
  } else {
    await insertBaseTax(newTax);
  }
};
