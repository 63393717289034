import React, { useCallback, useEffect } from 'react';
import PageTab from '../../components/dashboard/PageTab';
import PageTabTitle from '../../components/dashboard/PageTabTitle';
import PageTabBody from '../../components/dashboard/PageTabBody';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getTotal, setCurrentPage, setPageSize } from '../../redux/slices/archiveSlice';
import Pagination from '../../components/dashboard/Pagination';
import TotalSum from '../../components/archive/TotalSum';
import ArchiveTable from '../../components/archive/ArchiveTable';
import ArchiveSelection from '../../components/archive/ArchiveSelection';
import { ELECTRICITY, PAYMENT } from '../../consts/types';
import { getAllPaymentsForExport } from '../../api/paymentsService';
import { getAllTaxesForExport } from '../../api/taxesService';
import { CURRENT_DATE, getFormattedDateTime } from '../../helpers/dateHelpers';
import { exportData } from '../../helpers/exportHelpers';
import ArchiveExport from '../../components/archive/ArchiveExport';

const Archive = () => {
  const { selectedYear, selectedDataType, isLoading, data, currentPage, totalPages, pageSize } = useSelector(
    (state) => state.archive
  );
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    if (selectedYear && selectedDataType) {
      dispatch(getData());
    }
  }, [selectedYear, selectedDataType, dispatch]);

  useEffect(() => {
    fetchData();
    dispatch(getTotal());
  }, [fetchData, dispatch]);

  const handleExport = async () => {
    let data = [];
    switch (selectedDataType) {
      case PAYMENT:
        data = await getAllPaymentsForExport(selectedYear);
        break;
      case ELECTRICITY:
        data = await getAllTaxesForExport(selectedYear, selectedDataType);
        break;
      default:
        break;
    }

    const dataForExport = [];

    const headerRow = ['ID', 'Garažo Nr.', 'Įmokos įvedimo į sistemą laikas', 'Suma, €'];
    dataForExport.push(headerRow);

    data.forEach((d) => {
      dataForExport.push([d.id, d.owner, getFormattedDateTime(d.addedDate), d.amount]);
    });

    exportData(`archyvas-${CURRENT_DATE.toISOString().substring(0, 10)}.xlsx`, dataForExport);
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage({ page }));
    fetchData();
  };

  const handlePageSizeChange = (pageSize) => {
    dispatch(setPageSize({ pageSize }));
    fetchData();
  };

  return (
    <PageTab>
      <PageTabTitle>Archyvas</PageTabTitle>
      <PageTabBody>
        <ArchiveSelection />
        <ArchiveExport handleExport={handleExport} />
        <TotalSum />
        {data?.length > 0 && (
          <>
            <ArchiveTable />
            <Pagination
              data={{ isLoading, currentPage, totalPages, pageSize }}
              onPageChange={handlePageChange}
              onPageSizechange={handlePageSizeChange}
            />
          </>
        )}
      </PageTabBody>
    </PageTab>
  );
};

export default Archive;
