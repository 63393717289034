import React, { useCallback, useEffect, useState } from 'react';
import ClientsTable from '../../components/clients/ClientsTable';
import Pagination from '../../components/dashboard/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import {
  anyClientsWithUnconfirmedInfo,
  getClients,
  setCurrentPage,
  setPageSize
} from '../../redux/slices/clientsSlice';
import ClientFiltering from '../../components/clients/ClientFiltering';
import ClientUpdateModal from '../../components/clientUpdate/ClientUpdateModal';
import { getClientById } from '../../redux/slices/clientSlice';
import { doesClientExist, insertClient, removeClient, updateClient } from '../../api/clientsService';
import { changePassword } from '../../api/userService';
import { generatePassword } from '../../helpers/passwordHelpers';
import useConfirmationModal from '../../customHooks/useConfirmationModal';
import ConfirmationModal from '../../components/dashboard/ConfirmationModal';
import UnconfirmedClientsDataLink from '../../components/clients/UnconfirmedClientsDataLink';
import NewClient from '../../components/clients/NewClient';

const Clients = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [generatedPassword, setGeneratedPassword] = useState('');
  const {
    showModal: showDeleteConfirmationModal,
    data: clientToDelete,
    handleModalClick: handleConfirmationModalClick,
    closeModal: closeConfirmationModal
  } = useConfirmationModal();
  const { isLoading, currentPage, totalPages, pageSize } = useSelector((state) => state.clients);
  const { client } = useSelector((state) => state.client);
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    dispatch(getClients());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
    dispatch(anyClientsWithUnconfirmedInfo());
  }, [fetchData, dispatch]);

  const openClientUpdateModal = (clientId) => {
    setIsOpen(true);
    dispatch(getClientById(clientId));
  };

  const handleClientUpdate = async (clientData) => {
    await updateClient(clientData);
    setGeneratedPassword('');
    fetchData();
    setIsOpen(false);
  };

  const handlePasswordReset = async () => {
    const newPassword = generatePassword();
    setGeneratedPassword(newPassword);
    await changePassword(newPassword, client.id);

    // TODO: Send new password to client's email address
  };

  const handleModalVisability = () => {
    setIsOpen(false);
    setGeneratedPassword('');
  };

  const handleClientDelete = async () => {
    await removeClient(clientToDelete.id);
    fetchData();
    closeConfirmationModal();
  };

  const isClientUnique = async (id) => {
    const doesExist = await doesClientExist(id);
    return !doesExist;
  };

  const handleNewClientSubmit = async (newClient) => {
    await insertClient(newClient);
    fetchData();
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage({ page }));
    fetchData();
  };

  const handlePageSizeChange = (pageSize) => {
    dispatch(setPageSize({ pageSize }));
    fetchData();
  };

  return (
    <div className="relative min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded overflow-x-auto">
      <div className="rounded-t mb-0 px-1 py-3 border-0">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full px-0 max-w-full flex-grow flex-1">
            <h3 className="font-semibold text-base text-blueGray-700">Klientai</h3>
          </div>
        </div>
      </div>
      <UnconfirmedClientsDataLink />
      <ClientFiltering />
      <NewClient isClientUnique={isClientUnique} addNewClient={handleNewClientSubmit} />
      <ClientsTable onEditClick={openClientUpdateModal} onDeleteClick={handleConfirmationModalClick} />
      <Pagination
        data={{ isLoading, currentPage, totalPages, pageSize }}
        onPageChange={handlePageChange}
        onPageSizechange={handlePageSizeChange}
      />
      <ClientUpdateModal
        isOpen={isOpen}
        client={client}
        closeModal={handleModalVisability}
        generatedPassword={generatedPassword}
        handleClientUpdate={handleClientUpdate}
        handlePasswordReset={handlePasswordReset}
      />
      {showDeleteConfirmationModal && (
        <ConfirmationModal
          description={`Ar tikrai norite ištrinti klientą '${clientToDelete.garage}'?`}
          handleConfirm={handleClientDelete}
          handleClose={closeConfirmationModal}
        />
      )}
    </div>
  );
};

export default Clients;
