import React from 'react';
import Debt from '../../containers/dashboard/Debt';
import Electricity from '../../containers/dashboard/Electricity';
import Water from '../../containers/dashboard/Water';

const PaymentsPage = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <Debt />
        <Electricity />
        <Water />
      </div>
    </div>
  );
};

export default PaymentsPage;
