import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { round } from '../../helpers/numberHelpers';
import { getAllClientOrders } from '../../api/ordersService';
import { getFormattedDateOnly } from '../../helpers/dateHelpers';

const initialState = {
  orders: []
};

export const getOrders = createAsyncThunk('orders/getAll', async (id) => {
  return await getAllClientOrders(id);
});

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrders.fulfilled, (state, action) => {
      state.orders = action.payload.map((order) => {
        order.total = round(order.total, 2);
        order.date = getFormattedDateOnly(order.date);
        return order;
      });
    });
  }
});

export default ordersSlice.reducer;
