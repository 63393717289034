import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllBaseTaxes } from '../../api/baseTaxesService';

const initialState = {
  baseTaxes: []
};

export const getBaseTaxes = createAsyncThunk('baseTaxes/getAll', async () => {
  return await getAllBaseTaxes();
});

const baseTaxesSlice = createSlice({
  name: 'baseTaxes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBaseTaxes.fulfilled, (state, action) => {
      state.baseTaxes = action.payload;
    });
  }
});

export default baseTaxesSlice.reducer;
