import React from 'react';
import HeaderCell from '../table/HeaderCell';

const ClientPaymentHistoryTableHeader = () => {
  return (
    <thead>
      <tr>
        <HeaderCell rowSpan={2}>Metai</HeaderCell>
        <HeaderCell rowSpan={2}>Skola</HeaderCell>
        <HeaderCell rowSpan={2}>Sąskaita</HeaderCell>
        <HeaderCell colSpan={2}>Papildomi mokesčiai</HeaderCell>
        <HeaderCell colSpan={2}>Atlikti mokėjimai</HeaderCell>
      </tr>
      <tr>
        <HeaderCell>Aprašymas</HeaderCell>
        <HeaderCell>Suma</HeaderCell>
        <HeaderCell>Data</HeaderCell>
        <HeaderCell>Suma</HeaderCell>
      </tr>
    </thead>
  );
};

export default ClientPaymentHistoryTableHeader;
