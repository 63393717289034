import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UtilitiesTable from '../../components/payments/tables/UtilitiesTable';
import { getWaterDebt } from '../../redux/slices/waterDebtSlice';

const Water = ({ id }) => {
  const { waterTaxes } = useSelector((state) => state.waterDebt);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWaterDebt(id));
  }, [dispatch, id]);

  if (waterTaxes.length) {
    return (
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded overflow-x-auto">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">Vanduo</h3>
            </div>
          </div>
        </div>
        <UtilitiesTable data={waterTaxes} />
      </div>
    );
  } else {
    return <></>;
  }
};

export default Water;
