import React, { useCallback, useMemo, useState } from 'react';
import Modal from '../modal/Modal';
import ModalHeader from '../modal/ModalHeader';
import ModalBody from '../modal/ModalBody';
import ModalFooter from '../modal/ModalFooter';
import { getTrimmedData } from '../../helpers/formHelpers';
import { keepDigitsOnly } from '../../helpers/stringHelpers';

const NewClient = ({ isClientUnique, addNewClient }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [garageError, setGarageError] = useState('');
  const [squaresError, setSquaresError] = useState('');

  const initializeModal = () => {
    setIsOpen(true);
    setGarageError('Garažo numerio laukelis yra būtinas');
    setSquaresError('Garažo ploto laukelis yra būtinas');
  };

  const handleGarageChange = useCallback(() => {
    let timeoutId;

    return (e) => {
      const { value } = e.target;
      const newValue = value.trim();

      clearTimeout(timeoutId);

      timeoutId = setTimeout(async () => {
        let idError = '';
        if (!newValue) {
          idError = 'Garažo numerio laukelis yra būtinas';
        }

        const isUnique = await isClientUnique(newValue);
        if (!isUnique) {
          idError = 'Toks garažo numeris jau yra naudojamas';
        }

        setGarageError(idError);
      }, 500);
    };
  }, [isClientUnique]);

  const debounceGarageChange = useMemo(() => handleGarageChange(), [handleGarageChange]);

  const handleSquaresChange = async (e) => {
    const { value } = e.target;

    let squaresError = '';

    if (!value) {
      squaresError = 'Garažo ploto laukelis yra būtinas';
    } else if (value <= 0) {
      squaresError = 'Garažo plotas negali būti mažesnis nei 0';
    }

    setSquaresError(squaresError);
  };

  const handleSubmit = async () => {
    const formData = getTrimmedData('new-client');
    const digitsInId = keepDigitsOnly(formData.garage);
    const clientData = {
      ...formData,
      squares: parseFloat(formData.squares),
      sort: digitsInId ? parseInt(digitsInId) : 0,
      withoutMeter: formData.withoutMeter === 'on' ? true : false
    };

    await addNewClient(clientData);
    setIsOpen(false);
  };

  return (
    <>
      <button
        className="bg-blue-400 text-white hover:bg-blue-500 active:bg-blue-600 font-bold uppercase text-sm px-2 py-2 rounded outline-none focus:outline-none m-1"
        type="button"
        onClick={initializeModal}
      >
        Įvesti naują klientą
      </button>

      {isOpen && (
        <Modal>
          <ModalHeader>Naujas klientas</ModalHeader>
          <ModalBody>
            <form className="container mx-auto" id="new-client">
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="garage" className="text-gray-700 text-sm mr-2">
                    Garažo Nr.
                  </label>
                </div>
                <div className="w-full px-4 flex-1 flex items-center justify-start">
                  <input
                    type="text"
                    id="garage"
                    name="garage"
                    onChange={debounceGarageChange}
                    className={`px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full ${
                      garageError && 'border-red-500'
                    }`}
                  />
                </div>
              </div>
              {garageError && (
                <div className="flex flex-wrap mb-2">
                  <div className="w-1/3"></div>
                  <div className="w-full px-4 flex-1">
                    <label className="text-sm text-red-500">{garageError}</label>
                  </div>
                </div>
              )}
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="owner" className="text-gray-700 text-sm mr-2">
                    Savininkas
                  </label>
                </div>
                <div className="w-full px-4 flex-1 flex items-center justify-start">
                  <input
                    type="text"
                    id="owner"
                    name="owner"
                    className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="address" className="text-gray-700 text-sm mr-2">
                    Adresas
                  </label>
                </div>
                <div className="w-full px-4 flex-1 flex items-center justify-start">
                  <input
                    type="text"
                    id="address"
                    name="address"
                    className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="phone" className="text-gray-700 text-sm mr-2">
                    Telefonas
                  </label>
                </div>
                <div className="w-full px-4 flex-1 flex items-center justify-start">
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="email" className="text-gray-700 text-sm mr-2">
                    E. paštas
                  </label>
                </div>
                <div className="w-full px-4 flex-1 flex items-center justify-start">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="squares" className="text-gray-700 text-sm mr-2">
                    Garažo plotas
                  </label>
                </div>
                <div className="w-full px-4 flex-1">
                  <input
                    type="number"
                    id="squares"
                    name="squares"
                    onChange={handleSquaresChange}
                    min={0}
                    className={`px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full ${
                      squaresError && 'border-red-500'
                    }`}
                  />
                </div>
              </div>
              {squaresError && (
                <div className="flex flex-wrap mb-2">
                  <div className="w-1/3"></div>
                  <div className="w-full px-4 flex-1">
                    <label className="text-sm text-red-500">{squaresError}</label>
                  </div>
                </div>
              )}
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="withoutMeter" className="text-gray-700 text-sm mr-2">
                    Be skaitliuko
                  </label>
                </div>
                <div className="w-full px-4 flex-1 flex items-center justify-start" style={{ padding: '7px 1rem' }}>
                  <input
                    type="checkbox"
                    id="withoutMeter"
                    name="withoutMeter"
                    className="rounded shadow outline-none focus:outline-none focus:shadow-outline"
                  />
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              className="text-red-500 background-transparent font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
              type="button"
              onClick={() => setIsOpen(false)}
            >
              Uždaryti
            </button>
            <button
              className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ${
                (garageError || squaresError) && 'cursor-not-allowed'
              }`}
              type="button"
              disabled={garageError || squaresError}
              onClick={handleSubmit}
            >
              Saugoti
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default NewClient;
