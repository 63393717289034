import React from 'react';
import DepartmentDocuments from '../../containers/dashboard/DepartmentDocuments';
import ActivitiesDocuments from '../../containers/dashboard/ActivitiesDocuments';

const ActivityDocumentsPage = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <DepartmentDocuments />
        <ActivitiesDocuments />
      </div>
    </div>
  );
};

export default ActivityDocumentsPage;
