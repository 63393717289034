import React, { useEffect, useState } from 'react';
import DocumentLink from '../../components/documents/DocumentLink';
import PageTab from '../../components/dashboard/PageTab';
import PageTabTitle from '../../components/dashboard/PageTabTitle';
import PageTabBody from '../../components/dashboard/PageTabBody';

const DepartmentDocuments = () => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    try {
      const context = require.context('/public/activitiesDocuments/departments', false, /\.pdf$/);
      const docs = context.keys();

      setDocuments(docs);
    } catch (error) {
      console.error("Directory '/public/activitiesDocuments/departments' does not exist.");
    }
  }, []);

  return (
    <PageTab>
      <PageTabTitle>Padalinių sąrašas</PageTabTitle>
      <PageTabBody>
        {' '}
        {documents.map((filename, index) => (
          <DocumentLink key={index} directory={'/activitiesDocuments/departments'} filename={filename} />
        ))}
      </PageTabBody>
    </PageTab>
  );
};

export default DepartmentDocuments;
