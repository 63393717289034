import React, { useEffect, useState } from 'react';

const AnnualTaxInput = ({ year, tax, disabled, onChange }) => {
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(tax);
  }, [tax]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);

    onChange(newValue);
  };

  return (
    <div className="flex flex-wrap mb-2">
      <div className="w-1/6 px-4 flex items-center justify-end">
        <label htmlFor={`tax-${year}`} className="text-gray-700 text-sm mr-2">
          {year}
        </label>
      </div>
      <div className="w-1/6 px-4">
        <input
          type="number"
          id={`tax-${year}`}
          value={value ?? ''}
          min={0}
          disabled={disabled}
          onChange={handleChange}
          className={`px-2 py-1 text-blueGray-600 relative  rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline ${
            disabled ? 'bg-blueGray-100' : 'bg-white'
          }`}
        />
      </div>
    </div>
  );
};

export default AnnualTaxInput;
