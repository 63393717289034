import React from 'react';
import PaymentHistoryTableHeader from './PaymentHistoryTableHeader';
import PaymentHistoryTableBody from './PaymentHistoryTableBody';

const PaymentHistoryTable = ({ handleCashOrderClick, handleDeleteClick }) => {
  return (
    <table className="items-center w-full bg-transparent border-collapse">
      <PaymentHistoryTableHeader />
      <PaymentHistoryTableBody handleCashOrderClick={handleCashOrderClick} handleDeleteClick={handleDeleteClick} />
    </table>
  );
};

export default PaymentHistoryTable;
