import React from 'react';
import UtilitiesTableHeader from './UtilitiesTableHeader';
import UtilitiesTableBody from './UtilitiesTableBody';

const UtilitiesTable = ({ data, acceptPayment }) => {
  return (
    <table className="items-center w-full bg-transparent border-collapse">
      <UtilitiesTableHeader />
      <UtilitiesTableBody data={data} acceptPayment={acceptPayment} />
    </table>
  );
};

export default UtilitiesTable;
