import React from 'react';
import Modal from '../modal/Modal';
import ModalHeader from '../modal/ModalHeader';
import ModalBody from '../modal/ModalBody';
import ModalFooter from '../modal/ModalFooter';

const ConfirmationModal = ({ description, handleConfirm, handleClose, isLoading }) => {
  return (
    <Modal>
      <ModalHeader>Patvirtinkite veiksmą</ModalHeader>
      <ModalBody>{description}</ModalBody>
      <ModalFooter>
        <button
          className="text-red-500 background-transparent font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
          type="button"
          onClick={handleClose}
        >
          Uždaryti
        </button>
        <button
          className={`${
            isLoading ? 'bg-red-500' : 'bg-emerald-500'
          } text-white active:bg-emerald-600 font-bold uppercase text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
          type="button"
          onClick={handleConfirm}
          disabled={isLoading}
        >
          Patvirtinti
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
