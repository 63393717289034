import React from 'react';

const HeaderCell = ({ children, customWrap, customWidth, rowSpan, colSpan }) => {
  return (
    <th
      className={`px-1 bg-blueGray-300 text-blueGray-500 align-middle border-t border-b border-solid border-blueGray-400 py-2 text-xs uppercase font-semibold text-center ${
        customWidth || 'w-auto'
      } ${customWrap || 'whitespace-nowrap'}`}
      rowSpan={rowSpan || 1}
      colSpan={colSpan || 1}
    >
      {children}
    </th>
  );
};

export default HeaderCell;
