import React, { useEffect, useState } from 'react';
import Modal from '../modal/Modal';
import ModalHeader from '../modal/ModalHeader';
import ModalBody from '../modal/ModalBody';
import ModalFooter from '../modal/ModalFooter';
import { insertPayment } from '../../api/paymentsService';

const AddPaymentModal = ({ isOpen, clientId, closeModal, additionalHandleOnSubmit, defaultState }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const initialState = defaultState || {
      amount: 0,
      information: '',
      isBank: false
    };
    setFormData(initialState);
  }, [defaultState]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setErrorMessage('');
    if (name === 'amount' && value <= 0) {
      setErrorMessage('Suma turi būti didesnė nei 0');
    }

    setFormData({
      ...formData,
      [name]: newValue
    });
  };

  const handleSubmit = async () => {
    const newPayment = {
      amount: parseFloat(formData.amount),
      information: formData.information,
      isBank: formData.isBank,
      owner: clientId
    };

    if (newPayment.amount <= 0) {
      setErrorMessage('Suma turi būti didesnė nei 0');
      return;
    }

    setIsLoading(true);

    await insertPayment(newPayment);
    additionalHandleOnSubmit && additionalHandleOnSubmit();

    setFormData({
      amount: 0,
      information: '',
      isBank: false
    });
    closeModal();

    setIsLoading(false);
  };

  return (
    isOpen && (
      <Modal>
        <ModalHeader>Naujas mokėjimas</ModalHeader>
        <ModalBody>
          <div className="container mx-auto">
            <div className="flex flex-wrap mb-2">
              <div className="w-1/3 px-4 flex items-center justify-end">
                <label htmlFor="amount" className="text-gray-700 text-sm mr-2">
                  Suma
                </label>
              </div>
              <div className="w-full px-4 flex-1">
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  value={formData.amount}
                  onChange={(e) => handleChange(e)}
                  min={0}
                  className={`px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full ${
                    errorMessage && 'border-red-500'
                  }`}
                />
              </div>
            </div>
            {errorMessage && (
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3"></div>
                <div className="w-full px-4 flex-1">
                  <label className="text-sm text-red-500">{errorMessage}</label>
                </div>
              </div>
            )}
            <div className="flex flex-wrap mb-2">
              <div className="w-1/3 px-4 flex items-center justify-end">
                <label htmlFor="information" className="text-gray-700 text-sm mr-2">
                  Pastaba
                </label>
              </div>
              <div className="w-full px-4 flex-1 flex items-center justify-start">
                <input
                  type="text"
                  id="information"
                  name="information"
                  value={formData.information}
                  onChange={(e) => handleChange(e)}
                  className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                />
              </div>
            </div>
            <div className="flex flex-wrap mb-2">
              <div className="w-1/3 px-4 flex items-center justify-end">
                <label htmlFor="isBank" className="text-gray-700 text-sm mr-2">
                  Bankinis
                </label>
              </div>
              <div className="w-full px-4 flex-1 flex items-center justify-start">
                <input
                  type="checkbox"
                  id="isBank"
                  name="isBank"
                  checked={formData.isBank}
                  onChange={(e) => handleChange(e)}
                  className="rounded shadow outline-none focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="text-red-500 background-transparent font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
            type="button"
            onClick={closeModal}
          >
            Uždaryti
          </button>
          <button
            className={`${
              isLoading ? 'bg-red-500' : 'bg-emerald-500'
            } text-white active:bg-emerald-600 font-bold uppercase text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
            type="button"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Saugoti
          </button>
        </ModalFooter>
      </Modal>
    )
  );
};

export default AddPaymentModal;
