import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/clientPaymentHistory/Header';
import ClientPaymentHistory from '../../containers/dashboard/ClientPaymentHistory';

const ClientPaymentHistoryPage = () => {
  const { clientId } = useParams();

  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <Header id={clientId} />
        <ClientPaymentHistory id={clientId} />
      </div>
    </div>
  );
};

export default ClientPaymentHistoryPage;
