import React from 'react';
import ClientsUnconfirmedInfoTableHeader from './ClientsUnconfirmedInfoTableHeader';
import ClientsUnconfirmedInfoTableBody from './ClientsUnconfirmedInfoTableBody';

const ClientsUnconfirmedInfoTable = ({ onConfirm, onReject }) => {
  return (
    <table className="items-center w-full bg-transparent border-collapse">
      <ClientsUnconfirmedInfoTableHeader />
      <ClientsUnconfirmedInfoTableBody onConfirm={onConfirm} onReject={onReject} />
    </table>
  );
};

export default ClientsUnconfirmedInfoTable;
