import React from 'react';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

const Modal = ({ children }) => {
  const childrenArray = React.Children.toArray(children);
  const headerContent = childrenArray.find((child) => child.type === ModalHeader);
  const bodyContent = childrenArray.find((child) => child.type === ModalBody);
  const footerContent = childrenArray.find((child) => child.type === ModalFooter);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto max-w-xl w-full">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {headerContent}
            {bodyContent}
            {footerContent}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default Modal;
