import React, { createRef, useEffect, useState } from 'react';
import { createPopper } from '@popperjs/core';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../api/userService';
import { useDispatch, useSelector } from 'react-redux';
import { clearRole } from '../../redux/slices/roleSlice';
import { getUnreadMessagesCount } from '../../redux/slices/messagesSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const MAX_UNREAD_MESSAGES_COUNT = 99;

const UserDropdown = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const btnDropdownRef = createRef();
  const popoverDropdownRef = createRef();

  const { isAdmin } = useSelector((state) => state.role);
  const { unreadMessagesCount } = useSelector((state) => state.messages);

  useEffect(() => {
    dispatch(getUnreadMessagesCount());
  }, [dispatch]);

  useEffect(() => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: 'bottom-start'
    });
  }, [btnDropdownRef, popoverDropdownRef]);

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  return (
    <>
      <a
        className="text-blueGray-500 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : setDropdownPopoverShow(true);
        }}
      >
        <div className="items-center flex relative">
          <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            <FontAwesomeIcon style={{ color: 'black' }} icon={faUser} />
          </span>
          {!isAdmin && unreadMessagesCount ? (
            <span className="w-5 h-5 text-sm text-white bg-red-500 inline-flex justify-center rounded-full absolute top-0 right-0">
              {unreadMessagesCount <= MAX_UNREAD_MESSAGES_COUNT ? unreadMessagesCount : MAX_UNREAD_MESSAGES_COUNT}
            </span>
          ) : (
            <></>
          )}
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? 'block ' : 'hidden ') +
          'bg-white text-base z-50 float-left list-none text-left rounded shadow-lg min-w-48'
        }
      >
        {isAdmin ? (
          <>
            <button
              className="text-sm text-left py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100 hover:text-blueGray-800 cursor-pointer"
              onClick={() => {
                navigate('/dashboard');
                closeDropdownPopover();
              }}
            >
              Klientai
            </button>
            <button
              className="text-sm text-left py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100 hover:text-blueGray-800 cursor-pointer"
              onClick={() => {
                navigate('/dashboard/archive');
                closeDropdownPopover();
              }}
            >
              Archyvas
            </button>
            <button
              className="text-sm text-left py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100 hover:text-blueGray-800 cursor-pointer"
              onClick={() => {
                navigate('/dashboard/message');
                closeDropdownPopover();
              }}
            >
              Pranešimas
            </button>
            <button
              className="text-sm text-left py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100 hover:text-blueGray-800 cursor-pointer"
              onClick={() => {
                navigate('/dashboard/posts');
                closeDropdownPopover();
              }}
            >
              Skelbimai
            </button>
            <button
              className="text-sm text-left py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100 hover:text-blueGray-800 cursor-pointer"
              onClick={() => {
                navigate('/dashboard/settings');
                closeDropdownPopover();
              }}
            >
              Nustatymai
            </button>
          </>
        ) : (
          <>
            <button
              className="text-sm text-left py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100 hover:text-blueGray-800 cursor-pointer"
              onClick={() => {
                navigate('/dashboard/messages');
                closeDropdownPopover();
              }}
            >
              {`Pranešimai (${unreadMessagesCount})`}
            </button>
            <button
              className="text-sm text-left py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100 hover:text-blueGray-800  cursor-pointer"
              onClick={() => {
                navigate('/dashboard/payments');
                closeDropdownPopover();
              }}
            >
              Mokėjimų istorija
            </button>
            <button
              className="text-sm text-left py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100 hover:text-blueGray-800  cursor-pointer"
              onClick={() => {
                navigate('/dashboard/declarations');
                closeDropdownPopover();
              }}
            >
              Parodymų deklaravimas
            </button>
            <button
              className="text-sm text-left py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100 hover:text-blueGray-800  cursor-pointer"
              onClick={() => {
                navigate('/dashboard/profile');
                closeDropdownPopover();
              }}
            >
              Profilis
            </button>
            <button
              className="text-sm text-left py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100 hover:text-blueGray-800  cursor-pointer"
              onClick={() => {
                navigate('/dashboard/legal');
                closeDropdownPopover();
              }}
            >
              Teisinė informacija
            </button>
            <button
              className="text-sm text-left py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100 hover:text-blueGray-800  cursor-pointer"
              onClick={() => {
                navigate('/dashboard/activities');
                closeDropdownPopover();
              }}
            >
              Veiklos dokumentai
            </button>
            <button
              className="text-sm text-left py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100 hover:text-blueGray-800  cursor-pointer"
              onClick={() => {
                navigate('/dashboard/meetings');
                closeDropdownPopover();
              }}
            >
              Susirinkimai
            </button>
          </>
        )}
        <div className="h-0 border border-solid border-blueGray-100" />
        <button
          className="text-sm text-left py-3 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-blueGray-100 hover:text-blueGray-800 cursor-pointer"
          onClick={(e) => {
            logout().then(() => {
              setTimeout(() => {
                dispatch(clearRole());
              }, 500);
              navigate('/');
            });
          }}
        >
          Atsijungti
        </button>
      </div>
    </>
  );
};

export default UserDropdown;
