import Parse from 'parse';
import { fetchAllRecords } from './queryHandler';
import { ORDERS, PAYMENTS } from '../consts/tables';
import { getRoleByName } from './rolesService';
import { ADMIN_ROLE } from '../consts/roles';

export const getAllClientOrders = async (id) => {
  const query = new Parse.Query(ORDERS).equalTo('owner', id);
  const orders = await fetchAllRecords(query);

  return orders.map((order) => ({
    id: order.id,
    paymentId: order.attributes.mokejimoId.id,
    total: order.attributes.suma,
    orderNumber: order.attributes.orderNumber,
    date: order.attributes.data,
    owner: order.attributes.owner
  }));
};

export const insertOrder = async (order) => {
  const OrderObject = Parse.Object.extend(ORDERS);
  const newOrder = new OrderObject();

  const orderQuery = new Parse.Query(ORDERS).descending('orderNumber').limit(1);
  const maxOrder = await orderQuery.first();
  const maxOrderNumber = maxOrder ? maxOrder.get('orderNumber') || 0 : 0;

  newOrder.set('data', new Date());
  newOrder.set('suma', order.amount);
  newOrder.set('owner', order.owner);
  newOrder.set('orderNumber', maxOrderNumber);

  const paymentQuery = new Parse.Query(PAYMENTS);
  const payment = await paymentQuery.get(order.paymentId);
  payment.set('active', true);
  newOrder.set('mokejimoId', payment);
  await payment.save();

  const administratorRole = await getRoleByName(ADMIN_ROLE);
  const newACL = new Parse.ACL();
  newACL.setRoleReadAccess(administratorRole, true);
  newACL.setRoleWriteAccess(administratorRole, true);
  newOrder.setACL(newACL);

  newOrder.increment('orderNumber');

  await newOrder.save();

  const query = new Parse.Query(ORDERS).equalTo('objectId', newOrder.id);
  const createdOrder = await query.first();

  return {
    id: createdOrder.id,
    paymentId: createdOrder.get('mokejimoId').id,
    total: createdOrder.get('suma'),
    orderNumber: createdOrder.get('orderNumber'),
    date: createdOrder.get('data'),
    owner: createdOrder.get('owner')
  };
};

export const getOrderByPaymentId = async (paymentId) => {
  const paymentPointer = new Parse.Object('PAYMENTS');
  paymentPointer.id = paymentId;

  const query = new Parse.Query(ORDERS).equalTo('mokejimoId', paymentPointer);
  const order = await query.first();

  if (!order) {
    return null;
  }

  return {
    id: order.id,
    paymentId: order.attributes.mokejimoId.id,
    total: order.attributes.suma,
    orderNumber: order.attributes.orderNumber,
    date: order.attributes.data,
    owner: order.attributes.owner
  };
};
