import React, { useCallback, useEffect } from 'react';
import MessageItem from '../../components/messages/MessageItem';
import MessageContent from '../../components/messages/MessageContent';
import { useDispatch, useSelector } from 'react-redux';
import { getMessages, setCurrentPage, setPageSize } from '../../redux/slices/messagesSlice';
import { deleteMessage } from '../../api/messagesService';
import Pagination from '../../components/dashboard/Pagination';

const Messages = () => {
  const { messages, openedMessage, isLoading, currentPage, totalPages, pageSize } = useSelector(
    (state) => state.messages
  );
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    dispatch(getMessages());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteMessage = () => {
    deleteMessage(openedMessage.id);
    fetchData();
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage({ page }));
    fetchData();
  };

  const handlePageSizeChange = (pageSize) => {
    dispatch(setPageSize({ pageSize }));
    fetchData();
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 className="font-semibold text-base text-blueGray-700">Pranešimai</h3>
              </div>
            </div>
          </div>
          {messages ? (
            <div className="block w-full overflow-x-auto">
              <div className="grid grid-cols-[1fr,3fr] gap-10">
                <div className="px-4 border-r">
                  <Pagination
                    data={{ isLoading, currentPage, totalPages, pageSize }}
                    onPageChange={handlePageChange}
                    onPageSizechange={handlePageSizeChange}
                  />
                  {messages.map((message) => {
                    return <MessageItem key={message.id} message={message} />;
                  })}
                </div>
                <div>
                  {openedMessage && (
                    <>
                      <MessageContent message={openedMessage} />
                      <button
                        className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        style={{ marginTop: '1rem' }}
                        onClick={handleDeleteMessage}
                      >
                        Trinti pranešimą
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <p className="px-4">Pranešimų nėra</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Messages;
