import React from 'react';

const RejectButton = ({ onClick }) => {
  return (
    <button
      className="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-xs px-2 py-1 rounded outline-none focus:outline-none"
      type="button"
      onClick={onClick}
    >
      Atmesti
    </button>
  );
};

export default RejectButton;
