import React from 'react';

const PageTab = ({ children }) => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageTab;
