import React, { useEffect, useCallback } from 'react';
import PageTab from '../../components/dashboard/PageTab';
import PageTabTitle from '../../components/dashboard/PageTabTitle';
import PageTabBody from '../../components/dashboard/PageTabBody';
import { useDispatch } from 'react-redux';
import PostsCreate from '../../components/posts/PostsCreate';
import AdminPostsList from '../../components/posts/AdminPostsList';
import { createNewPost } from '../../api/postsService';
import { getPosts } from '../../redux/slices/postsSlice';

const Posts = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  });

  const fetchData = useCallback(() => {
    dispatch(getPosts());
  }, [dispatch]);

  const handleNewPostSubmit = async (newPost) => {
    await createNewPost(newPost);
    fetchData();
  };

  return (
    <PageTab>
      <PageTabTitle>Skelbimai</PageTabTitle>
      <PageTabBody>
        <div className="relative min-w-0 break-words bg-white w-full mb-6 rounded overflow-x-auto">
          <PostsCreate addNewPost={handleNewPostSubmit} />
          <AdminPostsList />
        </div>
      </PageTabBody>
    </PageTab>
  );
};

export default Posts;
