import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getClient } from '../../api/clientsService';

const Header = ({ id }) => {
  const { clients } = useSelector((state) => state.clients);
  const [client, setClient] = useState();

  useEffect(() => {
    const fetchClient = async () => {
      const fetchedClient = await getClient(id);
      setClient(fetchedClient);
    };

    const existingClient = clients.find((client) => client.id === id);
    if (existingClient) {
      setClient(existingClient);
    } else {
      fetchClient();
    }
  }, [clients, id]);

  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-0">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          <div className="rounded-t mb-0 px-1 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-1 max-w-full flex-grow flex-1">
                <h3 className="font-semibold text-base text-blueGray-700">
                  {`${client?.garage} - ${client?.owner.trim()}`}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
