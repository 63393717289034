import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getElectricityDebt } from '../../redux/slices/electricityDebtSlice';
import { getWaterDebt } from '../../redux/slices/waterDebtSlice';
import { ELECTRICITY, WATER } from '../../consts/types';
import UtilitiesTable from '../../components/payments/tables/UtilitiesTable';
import TypeSelection from '../../components/declarations/TypeSelection';
import DeclarationInput from '../../components/declarations/DeclarationInput';
import { getUtilitiesPrices } from '../../redux/slices/utilitiesPricesSlice';
import { insertTax, updateReadingData } from '../../api/taxesService';
import { getClient } from '../../api/clientsService';

const Declarations = () => {
  const { electricityTaxes } = useSelector((state) => state.electricityDebt);
  const { electricityPrice, waterPrice } = useSelector((state) => state.utilitiesPrices);
  const { waterTaxes } = useSelector((state) => state.waterDebt);
  const [type, setType] = useState('');
  const [isError, setIsError] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getElectricityDebt());
    dispatch(getWaterDebt());
    dispatch(getUtilitiesPrices());
  }, [dispatch]);

  const handleTypeChange = (type) => {
    setType(type);
    setIsError(false);
    setResultMessage('');
  };

  const showSelectedTable = () => {
    switch (type) {
      case ELECTRICITY:
        return <UtilitiesTable data={electricityTaxes} />;
      case WATER:
        return <UtilitiesTable data={waterTaxes} />;
      default:
        return <></>;
    }
  };

  const handleSubmit = async (value) => {
    setIsError(false);
    setResultMessage('');
    setIsLoading(true);

    try {
      const client = await getClient();

      const latestData = type === ELECTRICITY ? electricityTaxes[0] : waterTaxes[0];
      const pricePerUnit = type === ELECTRICITY ? electricityPrice : waterPrice;

      if (!latestData) {
        if (value < 0) {
          setIsError(true);
          setResultMessage('Parodymas turi būti ne mažesnis nei 0');
          return;
        }

        const newTax = {
          firstReading: value,
          owner: client.id,
          type
        };

        await insertTax(newTax);
      } else if (latestData.secondReading) {
        if (value <= latestData.secondReading) {
          setIsError(true);
          setResultMessage('Nauajs parodymas negali būti mažesnis nei ankstesnis');
          return;
        }

        const newTax = {
          firstReading: latestData.secondReading,
          secondReading: value,
          owner: client.id,
          type,
          amount: (value - latestData.secondReading) * pricePerUnit
        };

        await insertTax(newTax);
      } else {
        if (value <= latestData.firstReading) {
          setIsError(true);
          setResultMessage('Nauajs parodymas negali būti mažesnis nei ankstesnis');
          return;
        }

        const readingData = {
          secondReading: value,
          total: (value - latestData.firstReading) * pricePerUnit
        };

        await updateReadingData(latestData.id, readingData);
      }

      setResultMessage('Skaitiklio parodymas įrašytas sėkmingai');

      if (type === ELECTRICITY) {
        dispatch(getElectricityDebt());
      } else {
        dispatch(getWaterDebt());
      }
    } catch (error) {
      setIsError(true);
      setResultMessage('Serverio klaida');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 className="font-semibold text-base text-blueGray-700">Parodymų deklaravimas</h3>
              </div>
            </div>
          </div>
          <TypeSelection type={type} onTypeChange={handleTypeChange} isLoading={isLoading} />

          {showSelectedTable()}

          {type && (
            <DeclarationInput
              onSubmit={handleSubmit}
              isError={isError}
              resultMessage={resultMessage}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Declarations;
