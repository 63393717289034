import Parse from 'parse';
import { LATE_PAYMENTS } from '../consts/tables';
import { CURRENT_YEAR } from '../helpers/dateHelpers';
import { getRoleByName } from './rolesService';
import { ADMIN_ROLE } from '../consts/roles';

export const getAllLatePayments = async () => {
  const latePayments = await new Parse.Query(LATE_PAYMENTS).find();

  return latePayments.map((latePayment) => ({
    id: latePayment.id,
    year: latePayment.attributes.metai,
    tax: parseFloat(latePayment.attributes.kaina)
  }));
};

export const insertLatePayment = async (newTax) => {
  const LatePaymentObject = Parse.Object.extend(LATE_PAYMENTS);
  const newLatePayment = new LatePaymentObject();

  newLatePayment.set('metai', CURRENT_YEAR);
  newLatePayment.set('kaina', newTax);

  const newACL = new Parse.ACL();
  const administratorRole = await getRoleByName(ADMIN_ROLE);
  newACL.setRoleReadAccess(administratorRole, true);
  newACL.setRoleWriteAccess(administratorRole, true);
  newLatePayment.setACL(newACL);

  await newLatePayment.save();
};

export const updateLatePayment = async (id, newTax) => {
  const latePayment = await new Parse.Query(LATE_PAYMENTS).get(id);
  latePayment.set('kaina', newTax);

  const newACL = new Parse.ACL();
  const administratorRole = await getRoleByName(ADMIN_ROLE);
  newACL.setRoleReadAccess(administratorRole, true);
  newACL.setRoleWriteAccess(administratorRole, true);
  latePayment.setACL(newACL);

  await latePayment.save();
};

export const upsertLatePayment = async (id, newTax) => {
  if (id) {
    await updateLatePayment(id, newTax);
  } else {
    await insertLatePayment(newTax);
  }
};
