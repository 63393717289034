import Parse from 'parse';

export const getRoleByName = async (name) => {
  return await new Parse.Query(Parse.Role).equalTo('name', name).first();
};

export const addUserToRole = async (user, name) => {
  const role = await new Parse.Query(Parse.Role).equalTo('name', name).first();
  role.getUsers().add(user);

  await role.save();
};
