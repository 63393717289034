import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

const DocumentLink = ({ directory, filename }) => {
  const normalizeFileName = (filePath) => {
    const fileExtensionStartIndex = filePath.lastIndexOf('.');
    return filePath.substring(2, fileExtensionStartIndex);
  };

  return (
    <a
      href={`${directory}/${filename}`}
      rel="noopener noreferrer"
      target="_blank"
      className="text-lightBlue-500 background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mb-1"
    >
      <FontAwesomeIcon icon={faFile} />
      <span className="ml-2">{normalizeFileName(filename)}</span>
    </a>
  );
};

export default DocumentLink;
