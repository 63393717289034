import React from "react";
import Parse from "parse";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import { Provider } from "react-redux";

import "./sass/styles.scss";
import "react-18-image-lightbox/style.css";

// Index
import Dashboard from "./pages/dashboard";

// Landing pages
import Landing from "./pages/landing";
import About from "./pages/landing/About";
import Gallery from "./pages/landing/Gallery";
import Contacts from "./pages/landing/Contacts";
import Ads from "./pages/landing/Ads";

// Configure Parse with environment variables
Parse.serverURL = process.env.REACT_APP_PARSE_SERVER_URL + "/parse";
Parse.applicationId = process.env.REACT_APP_PARSE_APPLICATION_ID;
Parse.javaScriptKey = process.env.REACT_APP_PARSE_JAVASCRIPT_KEY;
Parse.masterKey = process.env.REACT_APP_PARSE_MASTER_KEY;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Routes>
          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/contacts" exact element={<Contacts />} />
          <Route path="/gallery" exact element={<Gallery />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/ads-list" exact element={<Ads />} />
          <Route path="/" exact element={<Landing />} />
          <Route path="/*" exact element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
