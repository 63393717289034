import React from 'react';
import Auth from './Auth';
import { isAuthenticated } from '../../api/userService';

const Landing = () => {
  return (
    <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
      <div className="absolute top-0 w-full h-full bg-center bg-cover" style={{ backgroundImage: `url(/landing.jpg)` }}>
        <span id="overlay" className="w-full h-full absolute" style={{ background: '#ecd988', opacity: '0.75' }}></span>
      </div>

      <div className="container relative mx-auto">
        <div className="flex flex-wrap justify-center">
          <div className="lg:w-4/12 px-4 mt-10" style={{ marginBottom: '50px' }}>
            <img src="/logo.svg" style={{ height: '150px', marginTop: '75px' }} alt="LOGO" />
          </div>
          {!isAuthenticated() && (
            <div style={{ minWidth: '400px' }} className="mt-10">
              <Auth />
            </div>
          )}
        </div>
      </div>
      <div
        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
        style={{ transform: 'translateZ(0)', bottom: '-1px' }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon className="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
  );
};

export default Landing;
