import React from 'react';
import Archive from '../../containers/dashboard/Archive';
import ArchiveDocuments from '../../containers/dashboard/ArchiveDocuments';

const ArchivePage = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <Archive />
        <ArchiveDocuments />
      </div>
    </div>
  );
};

export default ArchivePage;
