import React from 'react';

const PageTabTitle = ({ children }) => {
  return (
    <div className="rounded-t mb-0 px-1 py-3 border-0">
      <div className="flex flex-wrap items-center">
        <div className="relative w-full px-0 max-w-full flex-grow flex-1">
          <h3 className="font-semibold text-base text-blueGray-700">{children}</h3>
        </div>
      </div>
    </div>
  );
};

export default PageTabTitle;
