import React, { useState, useEffect } from 'react';
import { Ad } from '../../components/landing/Ad';
import { useSelector, useDispatch } from 'react-redux';
import { getPosts } from '../../redux/slices/postsSlice';
import { SALE, BUY, RENT, OTHER } from '../../consts/postCategories';

const AdsList = () => {
  const categories = {
    SALE,
    BUY,
    RENT,
    OTHER
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPosts());
  }, [dispatch]);

  const categoryClasses = ' cursor-pointer hover:text-blue-900';
  const { posts } = useSelector((state) => state.posts);

  const defaultCategory = 'SALE';
  const [category, setCategory] = useState(defaultCategory);

  const filteredPosts = posts.filter((post) => post.category === categories[category]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row mb-5">
        {Object.keys(categories).map((key) => (
          <button
            key={key}
            onClick={() => {
              setCategory(key);
            }}
          >
            <span className={`mr-3 ${category !== key ? categoryClasses : 'underline'}`}>
              <strong>{categories[key]}</strong>
            </span>
          </button>
        ))}
      </div>
      <div>
        {filteredPosts.map((post) => (
          <Ad key={post.id} ad={post} />
        ))}
      </div>
    </div>
  );
};

export default AdsList;
