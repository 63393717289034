import React from 'react';
import Clients from '../../containers/dashboard/Clients';
import ClientsExports from '../../containers/dashboard/ClientsExports';

const ClientsPage = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-8">
        <Clients />
        <ClientsExports />
      </div>
    </div>
  );
};

export default ClientsPage;
