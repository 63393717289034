import React, { useCallback, useEffect } from 'react';
import PageTab from '../../components/dashboard/PageTab';
import PageTabTitle from '../../components/dashboard/PageTabTitle';
import PageTabBody from '../../components/dashboard/PageTabBody';
import { useDispatch, useSelector } from 'react-redux';
import { getLatePayments } from '../../redux/slices/latePaymentsSlice';
import AnnualTaxes from '../../components/settings/AnnualTaxes';
import { CURRENT_YEAR } from '../../helpers/dateHelpers';
import { upsertLatePayment } from '../../api/latePaymentsService';
import { round } from '../../helpers/numberHelpers';

const LatePaymentsSettings = () => {
  const { latePayments } = useSelector((state) => state.latePayments);
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    dispatch(getLatePayments());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateLatePayment = async (newPrice) => {
    // Always update the latest record
    const currentYearLatePayment = latePayments.find((x) => x.year === CURRENT_YEAR);
    const roundedValue = round(newPrice, 4);
    await upsertLatePayment(currentYearLatePayment?.id, roundedValue);

    fetchData();
  };

  return (
    <PageTab>
      <PageTabTitle>Delspinigiai</PageTabTitle>
      <PageTabBody>
        <AnnualTaxes data={latePayments} onSubmit={updateLatePayment} />
      </PageTabBody>
    </PageTab>
  );
};

export default LatePaymentsSettings;
