import React from 'react';

const ConfirmButton = ({ onClick }) => {
  return (
    <button
      className="text-green-500 bg-transparent border border-solid border-green-500 hover:bg-green-500 hover:text-white active:bg-green-600 font-bold uppercase text-xs px-2 py-1 rounded outline-none focus:outline-none mr-1"
      type="button"
      onClick={onClick}
    >
      Patvirtinti
    </button>
  );
};

export default ConfirmButton;
