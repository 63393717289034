import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getRole } from '../../redux/slices/roleSlice';
import { isAuthenticated } from '../../api/userService';
import Sidebar from '../../components/dashboard/Sidebar';
import DashboardNavbar from '../../components/dashboard/DashboardNavbar';
import Footer from '../../components/dashboard/Footer';
import Messages from '../../containers/dashboard/Messages';
import ClientsPage from './ClientsPage';
import PaymentsPage from './PaymentsPage';
import ClientDataUpdatePage from './ClientDataUpdatePage';
import DeclarationPage from './DeclarationPage';
import LegalDocumentsPage from './LegalDocumentsPage';
import MeetingsPage from './MeetingsPage';
import ActivityDocumentsPage from './ActivityDocumentsPage';
import DashboardHeader from '../../components/dashboard/DashboardHeader';
import PaymentHistoryPage from './PaymentHistoryPage';
import DebtAndPaymentsPage from './DebtAndPaymentsPage';
import AdditionalTaxPage from './AdditionalTaxPage';
import ClientPaymentHistoryPage from './ClientPaymentHistoryPage';
import { ELECTRICITY, WATER } from '../../consts/types';
import ClientUtilitiesRecordsPage from './ClientUtilitiesRecordsPage';
import ClientsWithUnconfirmedInfoPage from './ClientsWithUnconfirmedInfoPage';
import ArchivePage from './ArchivePage';
import SettingsPage from './SettingsPage';
import PostsPage from './PostsPage';
import AdminMessagePage from './AdminMessagePage';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoggedIn] = useState(isAuthenticated());
  const { isAdmin } = useSelector((state) => state.role);

  useEffect(() => {
    if (!isLoggedIn) navigate('/');
    if (isAdmin === undefined) dispatch(getRole());
  }, [dispatch, isAdmin, isLoggedIn, navigate]);

  const getAuthorizedRoutes = () => {
    if (isLoggedIn === true && isAdmin === undefined) return <></>;

    if (isLoggedIn === true && isAdmin === true) {
      return (
        <>
          <Route path="/" exact element={<ClientsPage />} />
          <Route path="/clients/unconfirmed" exact element={<ClientsWithUnconfirmedInfoPage />} />
          <Route path="/clients/:clientId/debt-and-payments" exact element={<DebtAndPaymentsPage />} />
          <Route path="/clients/:clientId/taxes" exact element={<AdditionalTaxPage />} />
          <Route path="/clients/:clientId/payment-history" exact element={<ClientPaymentHistoryPage />} />
          <Route
            path="/clients/:clientId/electricity-records"
            exact
            element={<ClientUtilitiesRecordsPage type={ELECTRICITY} />}
          />
          <Route path="/clients/:clientId/water-records" exact element={<ClientUtilitiesRecordsPage type={WATER} />} />
          <Route path="/archive" exact element={<ArchivePage />} />
          <Route path="/message" exact element={<AdminMessagePage />} />
          <Route path="/posts" exact element={<PostsPage />} />
          <Route path="/settings" exact element={<SettingsPage />} />
        </>
      );
    }

    if (isLoggedIn === true && isAdmin === false) {
      return (
        <>
          <Route path="/" exact element={<PaymentsPage />} />
          <Route path="/messages" exact element={<Messages />} />
          <Route path="/payments" exact element={<PaymentHistoryPage />} />
          <Route path="/declarations" exact element={<DeclarationPage />} />
          <Route path="/profile" exact element={<ClientDataUpdatePage />} />
          <Route path="/legal" exact element={<LegalDocumentsPage />} />
          <Route path="/activities" exact element={<ActivityDocumentsPage />} />
          <Route path="/meetings" exact element={<MeetingsPage />} />
        </>
      );
    }

    return <Navigate to="/" />;
  };

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <DashboardNavbar />
        <DashboardHeader />
        <div className="mx-auto w-full custom-margin">
          <Routes>
            {getAuthorizedRoutes()}
            <Route path="/*" exact element={<Navigate to="/dashboard" />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
