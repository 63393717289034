import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinkButton from '../../components/dashboard/LinkButton';
import { useNavigate } from 'react-router-dom';
import ClientPaymentHistoryTable from '../../components/clientPaymentHistory/ClientPaymentHistoryTable';
import Pagination from '../../components/dashboard/Pagination';
import { getPaymentHistory, setCurrentPage, setPageSize } from '../../redux/slices/clientPaymentHistorySlice';

const ClientPaymentHistory = ({ id }) => {
  const { isLoading, currentPage, totalPages, pageSize } = useSelector((state) => state.clientPaymentHistory);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchData = useCallback(() => {
    dispatch(getPaymentHistory(id));
  }, [dispatch, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (page) => {
    dispatch(setCurrentPage({ page }));
    fetchData();
  };

  const handlePageSizeChange = (pageSize) => {
    dispatch(setPageSize({ pageSize }));
    fetchData();
  };

  return (
    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded overflow-x-auto">
      <div className="rounded-t mb-0 px-4 py-3 border-0">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 className="font-semibold text-base text-blueGray-700">Visa mokėjimų istorija</h3>
          </div>
        </div>
      </div>
      <LinkButton
        additionalClasses={'text-lightBlue-500 text-left px-4 py-2'}
        onClick={() => navigate(`/dashboard/clients/${id}/debt-and-payments`)}
      >
        Mokėjimai
      </LinkButton>
      <ClientPaymentHistoryTable />
      <Pagination
        data={{ isLoading, currentPage, totalPages, pageSize }}
        onPageChange={handlePageChange}
        onPageSizechange={handlePageSizeChange}
      />
    </div>
  );
};

export default ClientPaymentHistory;
