import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoltLightning, faFaucetDrip } from '@fortawesome/free-solid-svg-icons';
import { ELECTRICITY, WATER } from '../../consts/types';

const TypeSelection = ({ type, onTypeChange, isLoading }) => {
  return (
    <div className="container mx-auto">
      <div className="flex flex-wrap mb-2">
        <div className="w-1/2 px-4 flex items-center justify-end">
          <button
            className={`background-transparent font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mb-1 ${
              type === ELECTRICITY ? 'text-lightBlue-500' : 'text-lightBlue-300'
            }`}
            style={type === ELECTRICITY ? { textDecoration: 'underline' } : {}}
            type="button"
            disabled={type === ELECTRICITY || isLoading}
            onClick={() => onTypeChange(ELECTRICITY)}
          >
            <span className="mr-2">Elektra</span>
            <FontAwesomeIcon icon={faBoltLightning} />
          </button>
        </div>
        <div className="w-1/2 px-4">
          <button
            className={`background-transparent font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mb-1 ${
              type === WATER ? 'text-lightBlue-500' : 'text-lightBlue-300'
            }`}
            style={type === WATER ? { textDecoration: 'underline' } : {}}
            type="button"
            disabled={type === WATER || isLoading}
            onClick={() => onTypeChange(WATER)}
          >
            <FontAwesomeIcon icon={faFaucetDrip} />
            <span className="ml-2">Vanduo</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TypeSelection;
