import React from 'react';
import LandingNavbar from '../../components/landing/LandingNavbar';
import UpperImage from '../../components/landing/UpperImage';
import Footer from '../../components/landing/Footer';

const About = () => {
  return (
    <>
      <LandingNavbar />
      <UpperImage />

      <section className="relative py-16 bg-blueGray-200">
        <div className="container mx-auto px-4">
          <div className="flex flex-row bg-white mb-6 shadow-xl rounded-lg -mt-64">
            <div className="px-6" style={{ minHeight: '600px' }}>
              <div className="flex flex-wrap justify-center mt-10">
                <div className="w-full w-auto px-4 mb-5">
                  <div className="flex flex-wrap justify-center border-b">
                    <p className="mb-4 text-lg text-2xl font-bold">APIE MUS</p>
                  </div>
                </div>
                <img src="/garazai.jpg" className="shadow-xl" alt="GARAŽAI" />
                <div className="mt-10 border-blueGray-200 text-center">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      <p className="mb-4 text-lg text-blueGray-700">
                        Garažų Eksploatavimo Bendrija <span className="font-bold">„EISMAS“</span> įkurta 1974 metais.
                      </p>
                      <p className="mb-4 text-lg text-blueGray-700">
                        Šiuo metu bendrija išsidėstė 2 ha žemės plote ir vienija virš 500 garažų.
                      </p>
                      <p className="mb-4 text-lg text-blueGray-700">
                        Bendrijos ir garažų saugumą užtikrina bendrijos teritoriją stebinčios vaizdo kameros ir
                        garažuose įrengtos šiuolaikiškos saugos sistemos.
                      </p>
                      <p className="mb-4 text-lg text-blueGray-700 font-bold">
                        Garažuose yra trifazė elektra ir vandentiekis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default About;
