import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllClientsWithUnconfirmedInformation } from '../../api/clientsService';

const initialState = {
  clientsWithUnconfirmedInfo: [],
  currentPage: 1,
  pageSize: 10,
  totalPages: 0,
  isLoading: false,
  error: undefined
};

export const getClientsWithUnconfirmedInfo = createAsyncThunk(
  'clientsWithUnconfirmedInfo/getAll',
  async (_, thunkAPI) => {
    const currentState = thunkAPI.getState();
    const page = currentState.clientsWithUnconfirmedInfo.currentPage;
    const pageSize = currentState.clientsWithUnconfirmedInfo.pageSize;

    const skip = (page - 1) * pageSize;
    const result = await getAllClientsWithUnconfirmedInformation(skip, pageSize);
    const { clients, totalClients } = result;

    const totalPages = Math.ceil(totalClients / pageSize);

    return { clients, totalPages };
  }
);

const clientsWithUnconfirmedInfoSlice = createSlice({
  name: 'clientsWithUnconfirmedInfo',
  initialState,
  reducers: {
    setPageSize: (state, { payload }) => {
      state.pageSize = payload.pageSize;
      state.currentPage = 1;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload.page;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientsWithUnconfirmedInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClientsWithUnconfirmedInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.totalPages = action.payload.totalPages;
        state.clientsWithUnconfirmedInfo = action.payload.clients;
      })
      .addCase(getClientsWithUnconfirmedInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const { setPageSize, setCurrentPage } = clientsWithUnconfirmedInfoSlice.actions;
export default clientsWithUnconfirmedInfoSlice.reducer;
