import React from 'react';

export const Ad = ({ ad }) => {
  const { name, contact, added, message } = ad;

  return (
    <div
      className="p-6 border border-gray-400 rounded-lg shadow dark:bg-gray-400 dark:border-gray-700 mb-2"
      style={{ background: '#FEEEAA' }}
    >
      <p>
        <span className="font-bold">Vardas:</span>
        <span className="mx-2">{name}</span>
      </p>
      <p>
        <span className="font-bold">Kontaktai:</span>
        <span className="mx-2">{contact}</span>
      </p>
      <p>
        <span className="font-bold">Skelbimo data:</span>
        <span className="mx-2 mb-2">{new Date(added.iso).toISOString().split('T')[0]}</span>
      </p>
      <p className="mt-3 font-normal text-black" style={{ borderTop: '1px solid gray', paddingTop: '5px' }}>
        {message}
      </p>
    </div>
  );
};
