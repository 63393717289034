import React from 'react';
import LoadingSpinner from './LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const DISABLED_BUTTON = 'border-lightBlue-200 text-white bg-lightBlue-200 cursor-auto';
const ENABLED_BUTTON = 'border-lightBlue-500 bg-white text-lightBlue-500';

const Pagination = ({ data, onPageChange, onPageSizechange }) => {
  if (!data) {
    return;
  }

  return (
    <div className="m-2 py-2">
      <nav className="block">
        <ul className="flex pl-0 rounded list-none flex-wrap">
          <li>
            <button
              className={`first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid ${
                data.isLoading || data.currentPage <= 1 ? DISABLED_BUTTON : ENABLED_BUTTON
              }`}
              onClick={() => onPageChange(data.currentPage - 1)}
              disabled={data.isLoading || data.currentPage <= 1}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          </li>
          <li>
            <span className="first:ml-0 text-s font-semibold flex w-16 h-8 mx-1 p-0 items-center justify-center leading-tight relative text-lightBlue-500">
              {data.isLoading ? <LoadingSpinner /> : `${data.currentPage} / ${data.totalPages}`}
            </span>
          </li>
          <li>
            <button
              className={`first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid ${
                data.isLoading || data.currentPage === data.totalPages ? DISABLED_BUTTON : ENABLED_BUTTON
              }`}
              onClick={() => onPageChange(data.currentPage + 1)}
              disabled={data.isLoading || data.currentPage === data.totalPages}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </li>
          <li>
            <select
              className={`text-s font-semibold w-16 h-8 m-0 p-0 pl-2 ml-2 rounded border border-solid ${
                data.isLoading ? 'text-lightBlue-200 border-lightBlue-200' : 'text-lightBlue-500 border-lightBlue-500'
              }`}
              value={data.pageSize}
              onChange={(e) => onPageSizechange(parseInt(e.target.value))}
              disabled={data.isLoading}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
