import React from 'react';
import { useSelector } from 'react-redux';
import YearsDropdown from './YearsDropdown';
import ArchiveTypeDropdown from './ArchiveTypeDropdown';

const ArchiveSelection = () => {
  const { selectedYear } = useSelector((state) => state.archive);

  return (
    <div style={{ display: 'flex' }}>
      <YearsDropdown />
      {selectedYear && <ArchiveTypeDropdown />}
    </div>
  );
};

export default ArchiveSelection;
