import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ELECTRICITY, PAYMENT } from '../../consts/types';
import { getAllPaymentsForArchive, getAllPaymentsSumInYear } from '../../api/paymentsService';
import { getFormattedDateTime } from '../../helpers/dateHelpers';
import { getAllTaxesForArchive, getAllTaxesSumInYear } from '../../api/taxesService';
import { round } from '../../helpers/numberHelpers';

const initialState = {
  selectedYear: null,
  selectedDataType: null,
  data: null,
  currentPage: 1,
  pageSize: 10,
  totalPages: 0,
  isLoading: false,
  total: null
};

export const getData = createAsyncThunk('archive/getData', async (_, thunkAPI) => {
  const currentState = thunkAPI.getState();
  const { selectedYear, selectedDataType, currentPage: page, pageSize } = currentState.archive;
  const skip = (page - 1) * pageSize;

  switch (selectedDataType) {
    case PAYMENT:
      const paymentsResult = await getAllPaymentsForArchive(skip, pageSize, selectedYear);
      const { payments, totalPayments } = paymentsResult;
      const totalPaymentsPages = Math.ceil(totalPayments / pageSize);
      return { data: payments, totalPages: totalPaymentsPages };
    case ELECTRICITY:
      const result = await getAllTaxesForArchive(skip, pageSize, { year: selectedYear, type: ELECTRICITY });
      const { taxes: electricityTaxes, totalTaxes: totalElectricityTaxes } = result;
      const totalElectricityTaxesPages = Math.ceil(totalElectricityTaxes / pageSize);
      return { data: electricityTaxes, totalPages: totalElectricityTaxesPages };
    default:
      return null;
  }
});

export const getTotal = createAsyncThunk('archive/getTotal', async (_, thunkAPI) => {
  const currentState = thunkAPI.getState();
  const { selectedYear, selectedDataType } = currentState.archive;

  let total;
  switch (selectedDataType) {
    case PAYMENT:
      total = await getAllPaymentsSumInYear(selectedYear);
      break;
    case ELECTRICITY:
      total = await getAllTaxesSumInYear(selectedYear, ELECTRICITY);
      break;
    default:
      return null;
  }

  return round(total, 2);
});

const archiveSlice = createSlice({
  name: 'archive',
  initialState,
  reducers: {
    selectYear: (state, { payload }) => {
      state.selectedYear = payload.year;
    },
    selectDataType: (state, { payload }) => {
      state.selectedDataType = payload.type;
    },
    setPageSize: (state, { payload }) => {
      state.pageSize = payload.pageSize;
      state.currentPage = 1;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload.page;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload.totalPages;
        state.data = payload.data.map((element) => {
          element.addedDate = getFormattedDateTime(element.addedDate);
          element.amount = round(element.amount, 2);
          return element;
        });
      })
      .addCase(getData.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getTotal.fulfilled, (state, { payload }) => {
        state.total = payload;
      });
  }
});

export const { selectYear, selectDataType, setPageSize, setCurrentPage } = archiveSlice.actions;
export default archiveSlice.reducer;
