import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllPosts } from '../../api/postsService';

const initialState = {
  posts: []
};

export const getPosts = createAsyncThunk('posts/get', async () => {
  return await getAllPosts();
});

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPosts.fulfilled, (state, action) => {
      state.posts = action.payload;
    });
  }
});

export default postsSlice.reducer;
