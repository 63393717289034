import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../dashboard/LoadingSpinner';
import LinkButton from '../dashboard/LinkButton';

const ArchiveExport = ({ handleExport }) => {
  const [isExporting, setIsExporting] = useState(false);
  const { selectedYear, selectedDataType } = useSelector((state) => state.archive);

  const handleClick = async () => {
    setIsExporting(true);
    await handleExport();
    setIsExporting(false);
  };

  return (
    selectedYear &&
    selectedDataType && (
      <div style={{ display: 'flex' }}>
        <LinkButton
          additionalClasses={`text-left m-2 ${
            isExporting ? 'text-lightBlue-300 cursor-not-allowed' : 'text-lightBlue-500'
          }`}
          onClick={handleClick}
          disabled={isExporting}
        >
          {`Eksportuoti pasirinktą ${selectedYear} metų archyvą`}
        </LinkButton>
        <div className="w-full px-4 flex-1">{isExporting && <LoadingSpinner />}</div>
      </div>
    )
  );
};

export default ArchiveExport;
