import React, { useCallback, useState, useEffect } from 'react';
import { updatePost, deletePost } from '../../api/postsService';
import { useDispatch } from 'react-redux';
import { getPosts } from '../../redux/slices/postsSlice';
import BodyCell from '../table/BodyCell';
import LinkButton from '../dashboard/LinkButton';
import PostUpdateModal from '../../components/posts/PostUpdate';
import ConfirmationModal from '../../components/dashboard/ConfirmationModal';

export const AdAdmin = ({ post }) => {
  const { name, contact, added, message, objectId, category } = post;
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [showDeleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const fetchData = useCallback(() => {
    dispatch(getPosts());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [dispatch, fetchData]);

  const handelUpdate = async () => {
    setIsOpen(true);
  };

  const handlePostUpdate = async (post) => {
    setIsOpen(false);
    await updatePost(post);
    fetchData();
  };

  const handlePostDelete = async (post) => {
    setIsOpen(false);
    await deletePost(post);
    fetchData();
  };

  const closeConfirmationModal = () => {
    setDeleteConfirmationModal(false);
  };

  const showConfirmationModal = () => {
    setDeleteConfirmationModal(true);
  };

  return (
    <tbody>
      <tr key={objectId} className="odd:bg-white even:bg-gray-50 border-b ">
        <BodyCell customWrap={'whitespace-normal'}>{category}</BodyCell>
        <BodyCell customWrap={'whitespace-normal'}>{new Date(added.iso).toISOString().split('T')[0]}</BodyCell>
        <BodyCell customWrap={'whitespace-normal'}>{name}</BodyCell>
        <BodyCell customWrap={'whitespace-normal'}>{contact}</BodyCell>
        <BodyCell customWrap={'whitespace-normal text-start '}>{message}</BodyCell>
        <BodyCell>
          <div>
            <LinkButton
              additionalClasses={'text-lightBlue-500 hover:underline hover:text-black'}
              onClick={handelUpdate}
            >
              Redaguoti
            </LinkButton>
          </div>
          <div>
            <LinkButton
              additionalClasses={'text-lightBlue-500 hover:underline hover:text-black'}
              onClick={() => showConfirmationModal()}
            >
              Trinti
            </LinkButton>
          </div>
        </BodyCell>
      </tr>
      <PostUpdateModal
        isOpen={isOpen}
        post={post}
        closeModal={() => setIsOpen(false)}
        handlePostUpdate={handlePostUpdate}
      />
      {showDeleteConfirmationModal && (
        <ConfirmationModal
          description={
            <div>
              <p>Ar tikrai norite ištrinti skelbimą?</p>
              <hr />
              <p>
                <strong>ID: </strong>
                {objectId}
              </p>
              <p>
                <strong>Vardas: </strong>
                {name}
              </p>
              <p>
                <strong>Kontaktai: </strong>
                {contact}
              </p>
              <p>
                <strong>Sukūrimo data: </strong>
                {new Date(added.iso).toISOString().split('T')[0]}
              </p>
              <hr />
              <p>
                <strong>Skelbimo turinys: </strong>
                <textarea style={{ width: '100%' }} readOnly value={message}></textarea>
              </p>
            </div>
          }
          handleConfirm={() => handlePostDelete(post)}
          handleClose={closeConfirmationModal}
        />
      )}
    </tbody>
  );
};
