import React, { useState, useEffect } from 'react';
import { getClient } from '../../api/clientsService';

const Header = ({ id }) => {
  const [client, setClient] = useState(null);

  useEffect(() => {
    const fetchClient = async () => {
      const clientData = await getClient(id);
      setClient(clientData);
    };

    fetchClient();
  }, [id]);

  if (!client) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          <div className="rounded-t mb-0 px-1 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-0 max-w-full flex-grow flex-1">
                <h3 className="font-semibold text-base text-blueGray-700">
                  {`${client.garage} - ${client.owner.trim()}`}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
