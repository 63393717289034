import React, { useState, useEffect } from 'react';
import { getAllClientsForMessage } from '../../api/clientsService';

const MessageForm = ({ addMessage, isLoading }) => {
  const [allOwnersChecked, setAllOwnersChecked] = useState(true);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        let usersData = await getAllClientsForMessage();
        setUsers(usersData);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
      setLoading(false);
    };

    fetchUsers();
  }, []);

  const handleCheckboxChange = () => {
    if (allOwnersChecked) {
      setSelectedUsers([]);
    }
    setAllOwnersChecked(!allOwnersChecked);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleUserCheckboxChange = (user) => {
    setSelectedUsers((prevSelectedUsers) => {
      const isSelected = prevSelectedUsers.some((u) => u.id === user.id);
      if (isSelected) {
        return prevSelectedUsers.filter((u) => u.id !== user.id);
      } else {
        return [...prevSelectedUsers, { id: user.id, userId: user.userId }];
      }
    });
  };

  const handleSubmit = async () => {
    const formData = {
      subject: subject,
      message: message,
      allOwners: allOwnersChecked,
      selectedUsers: allOwnersChecked ? users.map((user) => ({ id: user.id, userId: user.userId })) : selectedUsers
    };
    await addMessage(formData);

    setSubject('');
    setMessage('');
    setSelectedUsers([]);
  };

  const filteredUsers = users.filter(
    (user) =>
      user.owner.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.id.toLowerCase() === searchTerm.toLowerCase() ||
      user.garage.toString() === searchTerm
  );

  return (
    <form className="container mx-auto" id="new-message">
      <div className="flex flex-wrap mb-2">
        <div className="w-1/6 px-4 flex items-center justify-end">
          <label htmlFor="tema" className="text-gray-700 text-sm mr-2">
            Tema
          </label>
        </div>
        <div className="w-full px-4 flex-1 flex items-center justify-end">
          <input
            type="text"
            id="tema"
            name="tema"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
          />
        </div>
      </div>
      <div className="flex flex-wrap mb-2">
        <div className="w-1/6 px-4 flex items-center justify-end">
          <label htmlFor="message" className="text-gray-700 text-sm mr-2">
            Message
          </label>
        </div>
        <div className="w-full px-4 flex-1 flex items-center justify-end">
          <textarea
            id="message"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
            style={{ height: '200px' }}
          />
        </div>
      </div>
      <div className="flex flex-wrap mb-2">
        <div className="w-1/6 px-4 flex items-center justify-end">
          <label htmlFor="allOwners" className="text-gray-700 text-sm mr-2">
            Gavėjas
          </label>
        </div>
        <div className="w-full px-4 flex-1 flex items-center justify-start">
          <div className="checkbox">
            <label className="flex items-center">
              <p className="text-gray-700 text-sm mr-2">Visi savininkai</p>
              <input
                id="allOwners"
                name="allOwners"
                className="ml-2"
                type="checkbox"
                value="true"
                checked={allOwnersChecked}
                onChange={handleCheckboxChange}
              />
            </label>
          </div>
        </div>
      </div>
      {!allOwnersChecked && (
        <div className="flex flex-wrap mb-2">
          <div className="w-1/6 px-4 flex justify-end">
            <label htmlFor="searchUsers" className="text-gray-700 text-sm mr-2">
              Ieškoti gavėjo
            </label>
          </div>
          <div className="w-1/2 px-4 flex-1 flex justify-start h-8">
            <input
              type="text"
              id="searchUsers"
              name="searchUsers"
              value={searchTerm}
              onChange={handleSearchChange}
              className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
              placeholder="Gavėjo vardas, pavardė arba garažo numeris"
            />
          </div>
          <div style={{ maxHeight: '200px', overflowY: 'auto' }} className="w-full px-4 flex-1 flex justify-start mt-2">
            {loading ? (
              <p>Gavėjai kraunami...</p>
            ) : (
              <div>
                {filteredUsers.map((user) => (
                  <div key={user.id}>
                    <input
                      type="checkbox"
                      id={user.id}
                      checked={selectedUsers.some((u) => u.id === user.id)}
                      onChange={() => handleUserCheckboxChange(user)}
                    />
                    <label htmlFor={user.id}>
                      {' '}
                      {user.owner}, Garažas: {user.garage}
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      {selectedUsers.length > 0 && !allOwnersChecked && (
        <div className="flex flex-wrap mb-2">
          <div className="w-1/6 px-4 flex items-center justify-end">
            <label className="text-gray-700 text-sm mr-2">Pasirinkti gavėjai:</label>
          </div>
          <div className="w-full px-4 flex-1 flex items-center justify-start mt-2">
            <ul>
              {selectedUsers.map(({ id, userId }) => (
                <li className="text-gray-700 text-sm mr-2" key={id}>
                  {users.find((user) => user.id === id)?.owner}, Garažas:
                  {users.find((user) => user.id === id)?.garage}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <div className="w-full px-4 flex-1 flex items-center justify-end">
        <button
          className={`${
            isLoading ? 'bg-gray-500 cursor-not-allowed' : 'bg-emerald-500 hover:bg-emerald-600'
          } text-white font-bold uppercase text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
          type="button"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? 'Siunčiama...' : 'Siųsti'}
        </button>
      </div>
    </form>
  );
};

export default MessageForm;
