import React, { useState } from 'react';
import PageTab from '../../components/dashboard/PageTab';
import PageTabTitle from '../../components/dashboard/PageTabTitle';
import PageTabBody from '../../components/dashboard/PageTabBody';
import MessageForm from '../../components/messages/MessageForm';
import { sendMessage } from '../../api/messagesService';

const AdminMessage = () => {
  const [messageStatus, setMessageStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleNewMessage = async (newMessage) => {
    setIsLoading(true);
    const sent = await sendMessage(newMessage);
    setMessageStatus(sent ? 'Pranešimas buvo sėkmingai išsiųstas' : 'Nepavyko išsųsti pranešimo');
    setIsLoading(false);
  };

  return (
    <PageTab>
      <PageTabTitle>Pranešimas</PageTabTitle>
      <PageTabBody>
        <div className="relative min-w-0 break-words bg-white w-full mb-6 rounded overflow-x-auto">
          <MessageForm addMessage={handleNewMessage} isLoading={isLoading} />
          {messageStatus && (
            <p
              className={`text-lg mr-2 text-center ${
                messageStatus.includes('sėkmingai') ? 'text-green-500' : 'text-red-500'
              }`}
            >
              {messageStatus}
            </p>
          )}
        </div>
      </PageTabBody>
    </PageTab>
  );
};

export default AdminMessage;
