import React from 'react';
import LandingNavbar from '../../components/landing/LandingNavbar';
import UpperImage from '../../components/landing/UpperImage';
import Footer from '../../components/landing/Footer';
import Map from '../../components/landing/Map';

const Contacts = () => {
  return (
    <>
      <LandingNavbar />
      <UpperImage />

      <section className="relative py-16 bg-blueGray-200">
        <div className="container mx-auto px-4">
          <div className="flex flex-col bg-white mb-6 shadow-xl rounded-lg -mt-64 px-6 py-6">
            <div className="w-full w-auto px-4 mb-5">
              <div className="flex flex-wrap justify-center border-b">
                <p className="mb-4 text-lg text-2xl font-bold">KONTAKTAI</p>
              </div>
            </div>
            <div className="border-blueGray-200 text-center">
              <div className="flex flex-wrap justify-center">
                <div className="w-full lg:w-9/12 px-4">
                  <p className="text-lg leading-relaxed text-blueGray-700">Garažų eksploatavimo bendrija </p>
                  <p className="font-bold text-lg leading-relaxed text-blueGray-700">"EISMAS"</p>
                </div>
              </div>
            </div>
            <div className="mt-5 border-blueGray-200 text-center">
              <div className="flex flex-wrap justify-center">
                <div className="w-full lg:w-9/12 px-4" style={{ height: '500px' }}>
                  <Map />
                </div>
              </div>
            </div>
            <div className="mt-10 border-blueGray-200 text-center">
              <div className="flex flex-wrap justify-center">
                <div className="w-full lg:w-9/12 px-4">
                  <p className="font-bold text-lg leading-relaxed text-blueGray-700">Adresas:</p>
                  <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
                    Pramonės pr. 19 Kauno m., Kauno m. sav., LT-51328
                  </p>

                  <p className="font-bold text-lg leading-relaxed text-blueGray-700">Įmonės rekvizitai:</p>
                  <p className="mb-4 text-lg leading-relaxed text-blueGray-700">Įmonės kodas: 235356620</p>

                  <p className="font-bold text-lg leading-relaxed text-blueGray-700">Priėmimo valandos:</p>
                  <p className="mb-4 text-2xl leading-relaxed text-blueGray-700">
                    Kiekvieną ketvirtadienį nuo 18 - 20 val.
                  </p>

                  <p className="font-bold text-lg leading-relaxed text-blueGray-700">Sąskaitos:</p>
                  <p className="text-lg leading-relaxed text-blueGray-700">LT70 7300 0101 3472 0350 AB "Swedbank"</p>
                  <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
                    LT91 7044 0600 0789 2126 AB "SEB" bankas
                  </p>

                  <p className="font-bold text-lg leading-relaxed text-blueGray-700">Kontaktai:</p>
                  <p className="text-lg leading-relaxed text-blueGray-700" style={{ fontStyle: 'italic' }}>
                    Pirmininkas:
                  </p>
                  <p className="mb-4 text-lg leading-relaxed text-blueGray-700">Tel.: 8 679 61434</p>
                  <p className="text-lg leading-relaxed text-blueGray-700" style={{ fontStyle: 'italic' }}>
                    Elektrikas:
                  </p>
                  <p className="mb-4 text-lg leading-relaxed text-blueGray-700">Tel.: 8 601 29879</p>
                  <p className="text-lg leading-relaxed text-blueGray-700" style={{ fontStyle: 'italic' }}>
                    Santechnikas:
                  </p>
                  <p className="mb-4 text-lg leading-relaxed text-blueGray-700">Tel.: 8 620 41841</p>

                  <p className="font-bold text-lg leading-relaxed text-blueGray-700">Bendrai informacijai:</p>
                  <p className="mb-4 text-lg leading-relaxed text-blueGray-700">El. paštas: gebeismas@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Contacts;
