import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserRoles } from '../../api/userService';
import { isAdmin } from '../../helpers/roleHelpers';

const initialState = {
  isAdmin: undefined,
  roles: undefined
};

// Async thunk action for syncing role from backend
export const getRole = createAsyncThunk('role/get', async () => {
  return await getUserRoles();
});

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    clearRole: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getRole.fulfilled, (state, action) => {
      // Handle successful current user role retrieval, action.payload should contain user role information
      state.isAdmin = isAdmin(action.payload);
      state.roles = action.payload;
    });
  }
});

export const { clearRole } = roleSlice.actions;
export default roleSlice.reducer;
