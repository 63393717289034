import React from 'react';

const EmailSignature = ({ signature, handleChange, handleSubmit }) => {
  return (
    <div className="container mx-auto">
      <textarea className="resize rounded-md p-2 w-1/3" value={signature} onChange={handleChange}></textarea>
      <div className="flex flex-wrap mb-2 mt-4">
        <div className="w-1/3 px-4 flex items-center justify-center">
          <button
            className="hover:shadow-md text-white font-bold uppercase text-xs px-4 py-2 rounded shadow outline-none focus:outline-none mr-2 bg-lightBlue-500 active:bg-lightBlue-600"
            type="button"
            onClick={handleSubmit}
          >
            Saugoti
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailSignature;
