import React from 'react';

const BodyCell = ({
  children,
  customWrap,
  customAlignment,
  customBackground,
  customFont,
  customWeight,
  customStyle,
  colSpan
}) => {
  return (
    <td
      className={`px-3 align-middle text-xs py-2 ${customAlignment || 'text-center'} ${
        customWrap || 'whitespace-nowrap'
      } ${customBackground || ''} ${customFont || ''} ${customWeight || ''}`}
      style={customStyle || {}}
      colSpan={colSpan || 1}
    >
      {children}
    </td>
  );
};

export default BodyCell;
