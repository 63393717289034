import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getTaxes } from '../../redux/slices/taxesSlice';
import TaxesTable from '../../components/taxes/TaxesTable';
import ConfirmationModal from '../../components/dashboard/ConfirmationModal';
import { removeTax } from '../../api/taxesService';
import AddTaxModal from '../../components/taxes/AddTaxModal';
import { useNavigate } from 'react-router-dom';
import LinkButton from '../../components/dashboard/LinkButton';
import useConfirmationModal from '../../customHooks/useConfirmationModal';

const Taxes = ({ id }) => {
  const { showModal, data, handleModalClick, closeModal } = useConfirmationModal();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getTaxes(id));
  }, [dispatch, id]);

  const handlePaymentDelete = async () => {
    await removeTax(data);
    dispatch(getTaxes(id));
    closeModal();
  };

  return (
    <div className="relative min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded overflow-x-auto">
      <div className="rounded-t mb-0 px-4 py-3 border-0">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 className="font-semibold text-base text-blueGray-700">Mokesčiai</h3>
          </div>
        </div>
      </div>
      <AddTaxModal clientId={id} />
      <TaxesTable handleDeleteClick={handleModalClick} />
      {showModal && (
        <ConfirmationModal
          description={'Ar tikrai norite ištrinti duomenis apie mokestį?'}
          handleConfirm={() => handlePaymentDelete()}
          handleClose={() => closeModal()}
        />
      )}
      <LinkButton
        additionalClasses={'text-lightBlue-500 text-left px-4 py-2'}
        onClick={() => navigate(`/dashboard/clients/${id}/debt-and-payments`)}
      >
        Kliento mokėjimai
      </LinkButton>
    </div>
  );
};

export default Taxes;
