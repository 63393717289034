import React from 'react';
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

const AnyReactComponent = () => (
  <div style={{ position: 'relative', color: 'black', transform: 'translate(-50%, -50%)' }}>
    <FontAwesomeIcon size={'3x'} color={'red'} icon={faLocationDot} />
    <i className="fas fa-map-marker-alt mr-2 text-lg text-red-500"></i>
    <div
      style={{
        position: 'absolute',
        top: '100%',
        left: '12px',
        transform: 'translate(-50%, 0)',
        backgroundColor: 'white',
        padding: '2px 5px',
        borderRadius: '3px',
        boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
      }}
    >
      GEB "Eismas"
    </div>
  </div>
);

const Map = () => {
  const defaultProps = {
    center: {
      lat: 54.9082,
      lng: 23.98004
    },
    zoom: 17
  };

  const getMapOptions = (maps) => {
    return {
      streetViewControl: false,
      scaleControl: true,
      fullscreenControl: false,
      styles: [
        {
          featureType: 'poi.business',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        }
      ],
      gestureHandling: 'greedy',
      disableDoubleClickZoom: true,
      minZoom: 11,
      maxZoom: 18,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.HYBRID,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.TOP_LEFT,
        mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE, maps.MapTypeId.HYBRID]
      },
      zoomControl: true,
      clickableIcons: false
    };
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={(map) => getMapOptions(map)}
      >
        <AnyReactComponent lat={defaultProps.center.lat} lng={defaultProps.center.lng} />
      </GoogleMapReact>
    </div>
  );
};

export default Map;
