import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/clientUtilitiesRecords/Header';
import ClientUtilitiesRecords from '../../containers/dashboard/ClientUtilitiesRecords';

const ClientUtilitiesRecordsPage = ({ type }) => {
  const { clientId } = useParams();

  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <Header id={clientId} />
        <ClientUtilitiesRecords id={clientId} type={type} />
      </div>
    </div>
  );
};

export default ClientUtilitiesRecordsPage;
