import React, { useState } from 'react';
import Modal from '../modal/Modal';
import ModalHeader from '../modal/ModalHeader';
import ModalBody from '../modal/ModalBody';
import ModalFooter from '../modal/ModalFooter';
import { useDispatch, useSelector } from 'react-redux';
import { getClients, setCurrentPage } from '../../redux/slices/clientsSlice';
import { changeFilter, reset } from '../../redux/slices/clientsFilterSlice';

const ClientFiltering = () => {
  const { garageNumber, owner, garageDebt, electricityDebt, waterDebt, member, debtFrom } = useSelector(
    (state) => state.clientsFilter
  );
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    dispatch(changeFilter({ name, value, type, checked }));
  };

  const handleSubmit = () => {
    dispatch(setCurrentPage({ page: 1 }));
    dispatch(getClients());
  };

  const resetFilters = () => {
    dispatch(reset());
    dispatch(getClients());
  };

  return (
    <>
      <button
        className="bg-blue-400 text-white hover:bg-blue-500 active:bg-blue-600 font-bold uppercase text-sm px-2 py-2 rounded outline-none focus:outline-none m-1"
        type="button"
        onClick={() => setIsOpen(true)}
      >
        Rodyti filtravimo parinktis
      </button>

      {isOpen && (
        <Modal>
          <ModalHeader>Filtravimo parinktys</ModalHeader>
          <ModalBody>
            <div className="container mx-auto">
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="garageNumber" className="text-gray-700 text-sm mr-2">
                    Garažo Nr.
                  </label>
                </div>
                <div className="w-full px-4 flex-1">
                  <input
                    type="text"
                    id="garageNumber"
                    name="garageNumber"
                    value={garageNumber}
                    onChange={handleInputChange}
                    className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="owner" className="text-gray-700 text-sm mr-2">
                    Savininkas
                  </label>
                </div>
                <div className="w-full px-4 flex-1">
                  <input
                    type="text"
                    id="owner"
                    name="owner"
                    value={owner}
                    onChange={handleInputChange}
                    className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="garageDebt" className="text-gray-700 text-sm mr-2">
                    Skola už garažą
                  </label>
                </div>
                <div className="w-full px-4 flex-1 flex items-center justify-start">
                  <input
                    type="checkbox"
                    id="garageDebt"
                    name="garageDebt"
                    checked={garageDebt}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="electricityDebt" className="text-gray-700 text-sm mr-2">
                    Skola už elektrą
                  </label>
                </div>
                <div className="w-full px-4 flex-1 flex items-center justify-start">
                  <input
                    type="checkbox"
                    id="electricityDebt"
                    name="electricityDebt"
                    checked={electricityDebt}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="waterDebt" className="text-gray-700 text-sm mr-2">
                    Skola už vandenį
                  </label>
                </div>
                <div className="w-full px-4 flex-1 flex items-center justify-start">
                  <input
                    type="checkbox"
                    id="waterDebt"
                    name="waterDebt"
                    checked={waterDebt}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="member" className="text-gray-700 text-sm mr-2">
                    Tik nariai
                  </label>
                </div>
                <div className="w-full px-4 flex-1 flex items-center justify-start">
                  <input type="checkbox" id="member" name="member" checked={member} onChange={handleInputChange} />
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="debtFrom" className="text-gray-700 text-sm mr-2">
                    Nuo sumos
                  </label>
                </div>
                <div className="w-full px-4 flex-1 flex items-center justify-start">
                  <input
                    type="number"
                    id="debtFrom"
                    name="debtFrom"
                    min={0}
                    value={debtFrom}
                    onChange={handleInputChange}
                    className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="text-red-500 background-transparent font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
              type="button"
              onClick={() => setIsOpen(false)}
            >
              Uždaryti
            </button>
            <button
              className="text-lightBlue-500 background-transparent font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
              type="button"
              onClick={resetFilters}
            >
              Atstatyti
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button"
              onClick={handleSubmit}
            >
              Filtruoti
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default ClientFiltering;
