import React from 'react';

const LinkButton = ({ children, additionalClasses, onClick, disabled, customStyle }) => {
  return (
    <button
      className={`background-transparent font-bold uppercase text-xs outline-none focus:outline-none ${additionalClasses}`}
      style={customStyle || {}}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default LinkButton;
