import React from 'react';
import HeaderCell from '../table/HeaderCell';

const ClientsUnconfirmedInfoTableHeader = () => {
  return (
    <thead>
      <tr>
        <HeaderCell rowSpan={2} customWrap={'whitespace-normal'}>
          Garažo Nr.
        </HeaderCell>
        <HeaderCell colSpan={2}>Savininkas</HeaderCell>
        <HeaderCell colSpan={2}>Adresas</HeaderCell>
        <HeaderCell colSpan={2}>Telefonas</HeaderCell>
        <HeaderCell colSpan={2}>E. paštas</HeaderCell>
      </tr>
      <tr>
        <HeaderCell>Dabartinė reikšmė</HeaderCell>
        <HeaderCell>Nauja reikšmė</HeaderCell>
        <HeaderCell>Dabartinė reikšmė</HeaderCell>
        <HeaderCell>Nauja reikšmė</HeaderCell>
        <HeaderCell>Dabartinė reikšmė</HeaderCell>
        <HeaderCell>Nauja reikšmė</HeaderCell>
        <HeaderCell>Dabartinė reikšmė</HeaderCell>
        <HeaderCell>Nauja reikšmė</HeaderCell>
      </tr>
    </thead>
  );
};

export default ClientsUnconfirmedInfoTableHeader;
