import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllLatePayments } from '../../api/latePaymentsService';

const initialState = {
  latePayments: []
};

export const getLatePayments = createAsyncThunk('latePayments/getAll', async () => {
  return await getAllLatePayments();
});

const latePaymentsSlice = createSlice({
  name: 'latePayments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLatePayments.fulfilled, (state, action) => {
      state.latePayments = action.payload;
    });
  }
});

export default latePaymentsSlice.reducer;
