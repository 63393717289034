import React from 'react';
import LinkButton from '../dashboard/LinkButton';
import LoadingSpinner from '../dashboard/LoadingSpinner';

const ClientsExportLink = ({ isDisabled, isLoading, title, handleExport }) => {
  return (
    <div className="flex flex-wrap mb-2">
      <div className="w-5/12 px-4 flex">
        <LinkButton
          additionalClasses={`text-left ${isDisabled ? 'text-lightBlue-300 cursor-not-allowed' : 'text-lightBlue-500'}`}
          onClick={handleExport}
          disabled={isDisabled}
        >
          {title}
        </LinkButton>
      </div>
      <div className="w-full px-4 flex-1">{isLoading && <LoadingSpinner />}</div>
    </div>
  );
};

export default ClientsExportLink;
