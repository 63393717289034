import React from 'react';
import { useSelector } from 'react-redux';
import { GREEN_FONT, RED_FONT } from '../../../consts/fontColors';
import BodyCell from '../../table/BodyCell';

const DebtTableBody = ({ addLateInterestToDebt }) => {
  const { debtPerYear, debtTotal, overpayment } = useSelector((state) => state.debt);
  const { electricityTaxes } = useSelector((state) => state.electricityDebt);
  const { waterTaxes } = useSelector((state) => state.waterDebt);
  const { otherTaxes } = useSelector((state) => state.otherDebt);
  const { isAdmin } = useSelector((state) => state.role);

  const sortedDebtPerYear = [...debtPerYear].sort((a, b) => b.year - a.year);

  return (
    <tbody>
      {sortedDebtPerYear.map((debt) => {
        return (
          <tr key={debt.year} className="odd:bg-white even:bg-gray-50 border-b">
            <BodyCell>
              <strong>{debt.year}</strong>
            </BodyCell>
            <BodyCell customFont={debt.total ? RED_FONT : GREEN_FONT}>
              <strong>{`${debt.total}€`}</strong>
            </BodyCell>
            <BodyCell>
              <strong>{`${debt.firstHalf}€`}</strong>
            </BodyCell>
            <BodyCell>
              <strong>{`${debt.secondHalf}€`}</strong>
            </BodyCell>
            <BodyCell customAlignment={'text-left'} customWrap={'whitespace-normal'}>
              {electricityTaxes
                .filter((tax) => tax.status && tax.year === debt.year)
                .map((tax) => (
                  <p key={tax.id}>
                    {`Iki ${tax.secondReading}: `}
                    <strong>{`${tax.total}€`}</strong>
                  </p>
                ))}
            </BodyCell>
            <BodyCell customAlignment={'text-left'} customWrap={'whitespace-normal'}>
              {electricityTaxes
                .filter((tax) => !tax.status && tax.year === debt.year && tax.secondReading)
                .map((tax) => (
                  <p key={tax.id}>
                    {`Iki ${tax.secondReading}: `}
                    <strong>{`${tax.total}€`}</strong>
                  </p>
                ))}
            </BodyCell>
            <BodyCell customAlignment={'text-left'} customWrap={'whitespace-normal'}>
              {waterTaxes
                .filter((tax) => tax.status && tax.year === debt.year)
                .map((tax) => (
                  <p key={tax.id}>
                    {`Iki ${tax.secondReading}: `}
                    <strong>{`${tax.total}€`}</strong>
                  </p>
                ))}
            </BodyCell>
            <BodyCell customAlignment={'text-left'} customWrap={'whitespace-normal'}>
              {waterTaxes
                .filter((tax) => !tax.status && tax.year === debt.year && tax.secondReading)
                .map((tax) => (
                  <p key={tax.id}>
                    {`Iki ${tax.secondReading}: `}
                    <strong>{`${tax.total}€`}</strong>
                  </p>
                ))}
            </BodyCell>
            <BodyCell customAlignment={'text-left'}>
              {otherTaxes
                .filter((tax) => tax.year === debt.year)
                .map((tax) => (
                  <p key={tax.id}>{`${tax.name}: ${tax.total}€`}</p>
                ))}
              {isAdmin && debt.total > 0 && debt.lateInterest > 0 && (
                <button
                  className={`background-transparent font-bold uppercase text-xs outline-none focus:outline-none text-left mt-2 ${RED_FONT}`}
                  type="button"
                  onClick={() => addLateInterestToDebt({ year: debt.year, lateInterest: debt.lateInterest })}
                >
                  {`Paskaičiuoti delspinigiai ${debt.lateInterest}€ (įtraukti į skolą)`}
                </button>
              )}
            </BodyCell>
            <BodyCell customFont={debt.total ? RED_FONT : GREEN_FONT}>
              <strong>{debt.totalNoChange && `${debt.totalNoChange}€`}</strong>
            </BodyCell>
            <BodyCell>
              <strong>{debt.usedPaymentAmount > 0 && `${debt.usedPaymentAmount}€`}</strong>
            </BodyCell>
          </tr>
        );
      })}
      <tr className="bg-gray-100">
        <BodyCell customAlignment={'text-right'} customWeight={'font-bold'}>
          Viso:
        </BodyCell>
        <BodyCell colSpan={10} customAlignment={'text-left'} customFont={debtTotal ? RED_FONT : GREEN_FONT}>
          <strong>{`${debtTotal}€`}</strong>
        </BodyCell>
      </tr>
      {overpayment ? (
        <tr className="bg-gray-100">
          <BodyCell customAlignment={'text-right'} customWeight={'font-bold'}>
            Permoka
          </BodyCell>
          <BodyCell colSpan={10} customAlignment={'text-left'}>{`${overpayment}€`}</BodyCell>
        </tr>
      ) : (
        <></>
      )}
    </tbody>
  );
};

export default DebtTableBody;
