import React from 'react';

const ModalFooter = ({ children }) => {
  return (
    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
      {children}
    </div>
  );
};

export default ModalFooter;
