import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../api/userService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";

const Auth = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const INVALID_LOGIN = "Invalid username/password.";
  const INSUFFICIENT_AUTH = "username/email is required.";
  const PASSWORD_REQUIRED = "password is required.";
  const RECAPTCHA_REQUIRED = "Please complete the reCAPTCHA verification.";

  const getErrorMessage = (message) => {
    switch (message) {
      case INSUFFICIENT_AUTH:
        return "Nepakankami prisijungimo duomenys";
      case INVALID_LOGIN:
        return "Neteisingas prisijungimas";
      case PASSWORD_REQUIRED:
        return "Neįvestas slaptažodis";
      case RECAPTCHA_REQUIRED:
        return "Prašau patvirtinkite kad jūs ne robotas";
      default:
        return "Klaida prisijungiant";
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!recaptchaToken) {
        throw new Error(RECAPTCHA_REQUIRED);
      }

      await login(username, password, recaptchaToken);

      setLoading(false);
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
      setError(getErrorMessage(error.message));
      setLoading(false);
    }
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <form name="form" onSubmit={handleLogin}>
      <div className="container px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full px-4">
            <div
              className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg border-0"
              style={{ background: "#ededed" }}
            >
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    PRISIJUNGIMAS
                  </h6>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
                <p
                  className="w-full text-center text-red-500 mt-2"
                  style={{ display: error === null ? "none" : "inline-block" }}
                >
                  {error}
                </p>
              </div>
              <div className="flex-auto px-4 py-10 pt-0">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    GARAŽO NUMERIS
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Nr."
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    SLAPTAŽODIS
                  </label>
                  <input
                    type="password"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Slaptažodis"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <ReCAPTCHA
                  sitekey="6LdXVDIpAAAAAPv7N5p46Ih_UgtlHxBKfo_TLd3w"
                  onChange={handleRecaptchaChange}
                />

                <div className="text-center mt-6">
                  <button
                    className="bg-yellow-500 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="submit"
                  >
                    PRISIJUNGTI
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spinPulse={true}
                      style={{
                        marginLeft: "25px",
                        display: loading ? "inline-block" : "none",
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Auth;
