import React from 'react';
import { useSelector } from 'react-redux';
import { ELECTRICITY, WATER } from '../../consts/types';
import { ELECTRICITY_COLOR, WATER_COLOR } from '../../consts/fontColors';
import BodyCell from '../table/BodyCell';

const PaymentHistoryTableBody = ({ handleCashOrderClick, handleDeleteClick }) => {
  const { payments } = useSelector((state) => state.payments);
  const { orders } = useSelector((state) => state.orders);
  const { isAdmin } = useSelector((state) => state.role);

  return (
    <tbody>
      {payments.map((payment) => {
        let color = { color: 'black' };
        switch (payment.type) {
          case ELECTRICITY:
            color.color = ELECTRICITY_COLOR;
            break;
          case WATER:
            color.color = WATER_COLOR;
            break;
          default:
            break;
        }

        const order = orders.find((order) => order.paymentId === payment.id);

        return (
          <tr key={payment.id} className="odd:bg-white even:bg-gray-50 border-b">
            <BodyCell>{payment.addedDate}</BodyCell>
            {isAdmin && (
              <BodyCell>
                {payment.updatedDate === payment.addedDate ? 'Įmokos suma nebuvo redaguota' : payment.updatedDate}
              </BodyCell>
            )}
            <BodyCell>
              <strong>{`${payment.amount}€`}</strong>
            </BodyCell>
            <BodyCell customAlignment={'text-left'} customStyle={color}>
              {payment.information}
            </BodyCell>
            {isAdmin ? (
              <>
                <BodyCell customWrap={'whitespace-normal'} customBackground={order ? 'bg-green-600' : 'bg-red-600'}>
                  {payment.isBank ? (
                    <img className="mx-auto w-7" alt="Mokėjimas banku" src="/bank-icon.png" />
                  ) : (
                    <img
                      className="mx-auto w-7 hover:cursor-pointer"
                      alt="Mokėjimas grynais"
                      src="/cash-register.png"
                      onClick={() => handleCashOrderClick(payment, order)}
                    />
                  )}
                </BodyCell>
                <BodyCell>
                  {order ? (
                    <p>{`Orderio Nr.: ${order.orderNumber}`}</p>
                  ) : (
                    <img
                      className="mx-auto w-7 hover:cursor-pointer"
                      alt="Naikinti"
                      src="/delete-image.png"
                      onClick={() => handleDeleteClick(payment.id)}
                    />
                  )}
                </BodyCell>
              </>
            ) : (
              <BodyCell>{payment.isBank ? 'Bankinis' : 'Grynais'}</BodyCell>
            )}
          </tr>
        );
      })}
    </tbody>
  );
};

export default PaymentHistoryTableBody;
