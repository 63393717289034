import React from 'react';
import CommonLegalDocuments from '../../containers/dashboard/CommonLegalDocuments';
import OtherLegalDocuments from '../../containers/dashboard/OtherLegalDocuments';

const LegalDocumentsPage = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <CommonLegalDocuments />
        <OtherLegalDocuments />
      </div>
    </div>
  );
};

export default LegalDocumentsPage;
