import React from 'react';
import { useSelector } from 'react-redux';
import HeaderCell from '../table/HeaderCell';

const PaymentHistoryTableHeader = () => {
  const { isAdmin } = useSelector((state) => state.role);

  return (
    <thead>
      <tr>
        <HeaderCell>Įmokos įvedimo laikas</HeaderCell>
        {isAdmin && <HeaderCell>Įmokos keitimo laikas</HeaderCell>}
        <HeaderCell>Suma</HeaderCell>
        <HeaderCell>Pastaba</HeaderCell>
        {isAdmin ? (
          <>
            <HeaderCell customWrap={'whitespace-normal'} customWidth={'w-12'}>
              Kasos pajamų orderis
            </HeaderCell>
            <HeaderCell customWidth={'w-12'}>Veiksmai</HeaderCell>
          </>
        ) : (
          <HeaderCell>Mokėjimas</HeaderCell>
        )}
      </tr>
    </thead>
  );
};

export default PaymentHistoryTableHeader;
