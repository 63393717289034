import { getAllBaseTaxes } from './baseTaxesService';
import { getClient } from './clientsService';
import { getPaidSum } from './paymentsService';
import { DEBT, getDebtSumIn, getTotalDebtSumIn } from './taxesService';
import { CURRENT_MONTH, CURRENT_YEAR, findDifferenceInDays } from '../helpers/dateHelpers';
import { LATE_INTEREST } from '../consts/types';
import Parse from 'parse';

export const calculateDebt = async (id) => {
  const client = await getClient(id);
  const startYear = client.id === 'M1' ? 2014 : 2007;

  const debtPerYear = await initializeDebt(startYear, client);
  const totalPaid = await getPaidSum(client.id);
  const { atStartOfYear, overpayment } = await calculatePaidDebt(client.id, startYear, debtPerYear, totalPaid);

  const filteredDebtPerYear = debtPerYear.filter(
    (debt) => !(debt.total === 0 && debt.year !== CURRENT_YEAR && debt.year < 2012)
  );

  return { atStartOfYear, overpayment, debtPerYear: filteredDebtPerYear };
};

export const updateClientDebts = async (client) => {
  Parse.Cloud.run('updateClientDebts', client).then(
    (response) => {},
    (error) => {
      console.error(error);
    }
  );
};

const initializeDebt = async (startYear, client) => {
  const basePrices = await getAllBaseTaxes();
  const debtPerYear = [];

  for (let year = startYear; year <= CURRENT_YEAR; year++) {
    const basePrice = parseFloat(basePrices.find((basePrice) => basePrice.year === year)?.tax);

    let base = calculateBasePaymentPrice(year, client, basePrice);
    base = isNaN(base) ? 0 : base;
    const totalGarage = await getDebtSumIn(client.id, year, DEBT);
    const totalDebtSum = await getTotalDebtSumIn(client.id, year);

    const total = base + parseFloat(totalDebtSum);

    const debt = { year, total, totalGarage, base };

    if (year !== CURRENT_YEAR) {
      debt.totalNoChange = total;
    }

    debtPerYear.push(debt);
  }

  return debtPerYear;
};

const calculateBasePaymentPrice = (year, client, basePrice) => {
  const id = client.id;
  const squares = client.squares;
  var price;

  if (
    squares <= 20 &&
    year >= 2014 &&
    id !== 'M1' &&
    id !== 'M2' &&
    id !== 'M4' &&
    id !== 'M5' &&
    id !== 'M7' &&
    id !== 'M8' &&
    id !== 'M9' &&
    id !== 'M10'
  ) {
    price = basePrice;
  } else {
    price = basePrice - basePrice * 0.1;
  }

  if (squares > 20 && squares <= 50) {
    price = basePrice;
  }

  if (squares > 50 && squares <= 120) {
    price = basePrice + basePrice * 0.1;
  }

  if (squares > 120 && squares <= 200) {
    price = basePrice + basePrice * 0.2;
  }

  if (squares > 200) {
    price = basePrice + basePrice * 0.3;
  }

  if (year === CURRENT_YEAR && CURRENT_MONTH <= 4) {
    price = price / 2;
  }

  return price;
};

const calculatePaidDebt = async (clientId, startYear, debtPerYear, totalPaid) => {
  let atStartOfYear = 0;

  for (let year = startYear; year <= CURRENT_YEAR; year++) {
    const debt = debtPerYear.find((d) => d.year === year);

    debt.total -= totalPaid;

    if (debt.total <= 0) {
      totalPaid = Math.abs(-debt.total);
      debt.total = 0;
    } else {
      debt.usedPaymentAmount = totalPaid;
      totalPaid = 0;
    }

    const difference = isNaN(debt.totalGarage - debt.usedPaymentAmount) ? 0 : debt.totalGarage - debt.usedPaymentAmount;
    debt.usedPaymentLeftover = Math.min(0, difference);

    if (debt.usedPaymentAmount < debt.lateInterest) {
      debt.totalWithoutLateInterest = debt.totalNoChange - debt.lateInterest;
    } else {
      debt.totalWithoutLateInterest = debt.total;
    }

    if (debt.total <= 0) {
      debt.totalWithoutLateInterest = 0;
    }

    if (year !== CURRENT_YEAR) {
      atStartOfYear += debt.total;
    }

    const halfYears = splitDebt(debt);

    debt.firstHalf = halfYears['q1'];
    debt.secondHalf = halfYears['q2'];

    if (year === 2015) {
      debt.firstLateInterestHalf = calculateLateInterest(halfYears['f1'], new Date(year, 4, 1)); // year-05-01 => month is 0-based
      debt.secondLateInterestHalf = calculateLateInterest(halfYears['f2'], new Date(year, 9, 2)); // year-10-02

      debt.lateInterest = debt.firstLateInterestHalf + debt.secondLateInterestHalf;

      const lateInterestSum = await getDebtSumIn(clientId, year + 1, LATE_INTEREST);
      debt.lateInterest = Math.max(0, debt.lateInterest - lateInterestSum);
    }
  }

  return { atStartOfYear, overpayment: totalPaid };
};

const splitDebt = (debt) => {
  const splits = {};

  if (debt.year === CURRENT_YEAR && CURRENT_MONTH <= 4) {
    const difference = debt.base + debt.usedPaymentLeftover;
    splits['f1'] = Math.max(0, difference);
    splits['f2'] = 0;

    splits['q1'] = debt.base;
    splits['q2'] = 0;
  } else {
    splits['f1'] = debt.base / 2 + debt.usedPaymentLeftover;
    splits['f2'] = debt.base / 2;

    if (splits['f1'] < 0) {
      splits['f2'] += splits['f1'];
      splits['f1'] = 0;
    }

    splits['q1'] = debt.base / 2;
    splits['q2'] = debt.base / 2;
  }

  splits['f1'] = Math.max(0, splits['f1']);
  splits['f2'] = Math.max(0, splits['f2']);

  return splits;
};

const calculateLateInterest = (debtAmount, date) => {
  const dayDifference = findDifferenceInDays(date);
  const normalizedDayDifference = Math.min(180, dayDifference);

  return 0.002 * debtAmount * normalizedDayDifference;
};
