import React, { useState } from 'react';
import { exportData, normalizePhoneNumbers } from '../../helpers/exportHelpers';
import { getAllClientsForExport } from '../../api/clientsService';
import { CURRENT_DATE } from '../../helpers/dateHelpers';
import { calculateDebt } from '../../api/debtsService';
import ClientsExportLink from '../../components/clients/ClientsExportLink';

const PHONE_NUMBER_LENGTH = 9;
const MINIMUM_DEBT_TO_EXPORT = 5;

const ClientsExports = () => {
  const [exporting, setExporting] = useState({
    isExporting: false,
    isExportingAllClientsPhone: false,
    isExportingClientsInDebtPhone: false,
    isExportingAllMembersPhone: false,
    isExportingMembersInDebtPhone: false,
    isExportingAllClientsData: false,
    isExportingAllMembersData: false
  });

  const setExportingState = (isExporting, exportType) => {
    setExporting({
      ...exporting,
      isExporting,
      [exportType]: isExporting
    });
  };

  const handleAllClientsPhoneExport = async () => {
    setExportingState(true, 'isExportingAllClientsPhone');

    const allClients = await getAllClientsForExport();

    const phoneNumbers = allClients
      .map((client) => {
        const phoneNumber = normalizePhoneNumbers(client.phone);
        return [phoneNumber];
      })
      .filter((phoneNumber) => phoneNumber[0].length === PHONE_NUMBER_LENGTH);

    exportData(`klientai-${CURRENT_DATE.toISOString().substring(0, 10)}-visi.xlsx`, phoneNumbers);

    setExportingState(false, 'isExportingAllClientsPhone');
  };

  const handleClientsInDebtPhoneExport = async () => {
    setExportingState(true, 'isExportingClientsInDebtPhone');

    const allClients = await getAllClientsForExport();

    const filteredByPhoneClients = allClients
      .map((client) => ({
        ...client,
        phone: normalizePhoneNumbers(client.phone)
      }))
      .filter((client) => client.phone.length === PHONE_NUMBER_LENGTH);

    const clientsWithDebt = await Promise.all(
      filteredByPhoneClients.map(async (client) => {
        const debt = await calculateDebt(client.id);
        const totalDebt = debt.debtPerYear.reduce((accumulator, dpy) => {
          return accumulator + dpy.total;
        }, 0);

        return { ...client, totalDebt };
      })
    );

    const phoneNumbers = clientsWithDebt
      .filter((client) => client.totalDebt > MINIMUM_DEBT_TO_EXPORT)
      .map((client) => {
        const phoneNumber = normalizePhoneNumbers(client.phone);
        return [phoneNumber];
      });

    exportData(`klientai-${CURRENT_DATE.toISOString().substring(0, 10)}-skolos.xlsx`, phoneNumbers);

    setExportingState(false, 'isExportingClientsInDebtPhone');
  };

  const handleAllMembersPhoneExport = async () => {
    setExportingState(true, 'isExportingAllMembersPhone');

    const allClients = await getAllClientsForExport();

    const phoneNumbers = allClients
      .filter((client) => client.member)
      .map((client) => {
        const phoneNumber = normalizePhoneNumbers(client.phone);
        return [phoneNumber];
      })
      .filter((phoneNumber) => phoneNumber[0].length === PHONE_NUMBER_LENGTH);

    exportData(`nariai-${CURRENT_DATE.toISOString().substring(0, 10)}-visi.xlsx`, phoneNumbers);

    setExportingState(false, 'isExportingAllMembersPhone');
  };

  const handleMembersInDebtPhoneExport = async () => {
    setExportingState(true, 'isExportingMembersInDebtPhone');

    const allClients = await getAllClientsForExport();

    const filteredByPhoneMembers = allClients
      .map((client) => ({
        ...client,
        phone: normalizePhoneNumbers(client.phone)
      }))
      .filter((client) => client.member && client.phone.length === PHONE_NUMBER_LENGTH);

    const clientsWithDebt = await Promise.all(
      filteredByPhoneMembers.map(async (client) => {
        const debt = await calculateDebt(client.id);
        const totalDebt = debt.debtPerYear.reduce((accumulator, dpy) => {
          return accumulator + dpy.total;
        }, 0);

        return { ...client, totalDebt };
      })
    );

    const phoneNumbers = clientsWithDebt
      .filter((client) => client.totalDebt > MINIMUM_DEBT_TO_EXPORT)
      .map((client) => {
        const phoneNumber = normalizePhoneNumbers(client.phone);
        return [phoneNumber];
      });

    exportData(`nariai-${CURRENT_DATE.toISOString().substring(0, 10)}-skolos.xlsx`, phoneNumbers);

    setExportingState(false, 'isExportingMembersInDebtPhone');
  };

  const handleAllClientsDataExport = async () => {
    setExportingState(true, 'isExportingAllClientsData');

    const allClients = await getAllClientsForExport();

    const data = allClients.map((client) => {
      return [client.garage, client.owner];
    });

    exportData(`klientai-${CURRENT_DATE.toISOString().substring(0, 10)}-visi.xlsx`, data);

    setExportingState(false, 'isExportingAllClientsData');
  };

  const handleAllMembersDataExport = async () => {
    setExportingState(true, 'isExportingAllMembersData');

    const allClients = await getAllClientsForExport();

    const data = allClients
      .filter((client) => client.member)
      .map((client) => {
        return [client.id, client.owner];
      });

    exportData(`nariai-${CURRENT_DATE.toISOString().substring(0, 10)}-visi.xlsx`, data);

    setExportingState(false, 'isExportingAllMembersData');
  };

  return (
    <div className="relative min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded overflow-x-auto">
      <div className="rounded-t mb-0 px-4 py-3 border-0">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 className="font-semibold text-base text-blueGray-700">Eksportavimas</h3>
          </div>
        </div>
      </div>
      <div className="container">
        <ClientsExportLink
          isDisabled={exporting.isExporting}
          isLoading={exporting.isExportingAllClientsPhone}
          title={'Eksportuoti klientų telefono numerių sąrašą į XLSX (visi klientai)'}
          handleExport={handleAllClientsPhoneExport}
        />
        <ClientsExportLink
          isDisabled={exporting.isExporting}
          isLoading={exporting.isExportingClientsInDebtPhone}
          title={'Eksportuoti klientų telefono numerių sąrašą į XLS (skolininkai)'}
          handleExport={handleClientsInDebtPhoneExport}
        />
        <ClientsExportLink
          isDisabled={exporting.isExporting}
          isLoading={exporting.isExportingAllMembersPhone}
          title={'Eksportuoti narių telefono numerių sąrašą į XLS (visi nariai)'}
          handleExport={handleAllMembersPhoneExport}
        />
        <ClientsExportLink
          isDisabled={exporting.isExporting}
          isLoading={exporting.isExportingMembersInDebtPhone}
          title={'Eksportuoti narių telefono numerių sąrašą į XLS (skolininkai)'}
          handleExport={handleMembersInDebtPhoneExport}
        />
        <ClientsExportLink
          isDisabled={exporting.isExporting}
          isLoading={exporting.isExportingAllClientsData}
          title={'Eksportuoti visų klientų sąrašą (vardas, pavarde, garažas)'}
          handleExport={handleAllClientsDataExport}
        />
        <ClientsExportLink
          isDisabled={exporting.isExporting}
          isLoading={exporting.isExportingAllMembersData}
          title={'Eksportuoti visų narių sąrašą (vardas, pavarde, garažas)'}
          handleExport={handleAllMembersDataExport}
        />
      </div>
    </div>
  );
};

export default ClientsExports;
