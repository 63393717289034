import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const UnconfirmedClientsDataLink = () => {
  const { anyUnconfirmedInfo } = useSelector((state) => state.clients);
  const navigate = useNavigate();

  if (anyUnconfirmedInfo) {
    return (
      <div
        className="mt-0 mb-2 cursor-pointer"
        style={{ width: 'fit-content' }}
        onClick={() => navigate('/dashboard/clients/unconfirmed')}
      >
        <span className="text-red-600 font-bold mx-1">SVARBU.</span>
        <span className="mr-1">Yra nepatvirtintos klientų informacijos.</span>
        <span className="text-blue-400 hover:text-blue-700 hover:underline">Spauskite čia.</span>
      </div>
    );
  } else return <></>;
};

export default UnconfirmedClientsDataLink;
