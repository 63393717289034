import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { anyClientsWithUnconfirmedInformation, getAllClients } from '../../api/clientsService';
import { getFormattedDateOnly } from '../../helpers/dateHelpers';
import { round } from '../../helpers/numberHelpers';

const initialState = {
  clients: [],
  currentPage: 1,
  pageSize: 10,
  totalPages: 0,
  anyUnconfirmedInfo: false,
  isLoading: false,
  error: undefined
};

export const getClients = createAsyncThunk('clients/getClients', async (_, thunkAPI) => {
  const currentState = thunkAPI.getState();
  const page = currentState.clients.currentPage;
  const pageSize = currentState.clients.pageSize;
  const filters = currentState.clientsFilter;

  const skip = (page - 1) * pageSize;
  const result = await getAllClients(skip, pageSize, filters);
  const { clients, totalClients } = result;

  const totalPages = Math.ceil(totalClients / pageSize);

  let filteredClients = clients;

  return { clients: filteredClients, totalPages };
});

export const anyClientsWithUnconfirmedInfo = createAsyncThunk('clients/anyUnconfirmedInfo', async () => {
  return await anyClientsWithUnconfirmedInformation();
});

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setPageSize: (state, { payload }) => {
      state.pageSize = payload.pageSize;
      state.currentPage = 1;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload.page;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.totalPages = action.payload.totalPages;
        state.clients = action.payload.clients.map((client) => {
          client.memberSince = getFormattedDateOnly(client.memberSince);
          client.garageDebt = round(client.totalDebt - client.electricityDebt - client.waterDebt, 2);
          client.totalDebt = round(client.totalDebt, 2);
          client.electricityDebt = round(client.electricityDebt, 2);
          client.waterDebt = round(client.waterDebt, 2);

          return client;
        });
      })
      .addCase(getClients.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(anyClientsWithUnconfirmedInfo.fulfilled, (state, action) => {
        state.anyUnconfirmedInfo = action.payload;
      });
  }
});

export const { setPageSize, setCurrentPage } = clientsSlice.actions;
export default clientsSlice.reducer;
