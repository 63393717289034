import React from 'react';

const DeclarationInput = ({ onSubmit, isError, resultMessage, isLoading }) => {
  const handleSubmit = () => {
    const meterValueInput = document.getElementById('meterValue');
    const meterValue = parseFloat(meterValueInput.value || 0);
    onSubmit(meterValue);
  };

  return (
    <div className="container mx-auto mt-6">
      <div className="flex flex-wrap mb-2 justify-center">
        <label htmlFor="meterValue" className="text-gray-700 text-sm">
          Įveskite naują skaitiklio parodymą:
        </label>
      </div>
      {resultMessage && (
        <div className="flex flex-wrap mb-2 justify-center">
          <label className={`text-sm ${isError ? 'text-red-500' : 'text-green-500'}`}>{resultMessage}</label>
        </div>
      )}
      <div className="flex flex-wrap mb-2 justify-center">
        <div className="flex items-center justify-end mr-2">
          <input
            type="number"
            id="meterValue"
            name="meterValue"
            min={0}
            className="px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
          />
        </div>
        <div>
          <button
            className={`text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ${
              isLoading ? 'bg-lightBlue-300' : 'bg-lightBlue-500 active:bg-lightBlue-600'
            }`}
            type="button"
            disabled={isLoading}
            onClick={() => handleSubmit()}
          >
            Saugoti
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeclarationInput;
