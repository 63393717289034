import React from 'react';
import HeaderCell from '../table/HeaderCell';

const TaxesTableHeader = () => {
  return (
    <thead>
      <tr>
        <HeaderCell>Data</HeaderCell>
        <HeaderCell customWrap={'whitespace-normal'}>Paslauga</HeaderCell>
        <HeaderCell>Suma</HeaderCell>
        <HeaderCell customWidth={'w-12'}>Veiksmai</HeaderCell>
      </tr>
    </thead>
  );
};

export default TaxesTableHeader;
