import React from 'react';
import LandingNavbar from '../../components/landing/LandingNavbar';
import Landing from '../../containers/landing/Landing';
import Footer from '../../components/landing/Footer';

const Index = () => {
  return (
    <>
      <LandingNavbar />
      <Landing />
      <Footer />
    </>
  );
};

export default Index;
