import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Reducers
import roleReducer from './slices/roleSlice';
import messagesReducer from './slices/messagesSlice';
import debtReducer from './slices/debtSlice';
import electricityDebtReducer from './slices/electricityDebtSlice';
import waterDebtReducer from './slices/waterDebtSlice';
import otherDebtReducer from './slices/otherDebtSlice';
import utilitiesPricesReducer from './slices/utilitiesPricesSlice';
import clientsReducer from './slices/clientsSlice';
import clientsFilterReducer from './slices/clientsFilterSlice';
import clientsWithUnconfirmedInfoReducer from './slices/clientsWithUnconfirmedInfoSlice';
import paymentsReducer from './slices/paymentsSlice';
import ordersReducer from './slices/ordersSlice';
import taxesReducer from './slices/taxesSlice';
import clientPaymentHistoryReducer from './slices/clientPaymentHistorySlice';
import clientReducer from './slices/clientSlice';
import archiveReducer from './slices/archiveSlice';
import baseTaxesReducer from './slices/baseTaxesSlice';
import latePaymentsReducer from './slices/latePaymentsSlice';
import postsReducer from './slices/postsSlice';

const persistConfig = {
  key: 'root',
  storage
};

export const store = configureStore({
  reducer: {
    role: persistReducer(persistConfig, roleReducer),
    messages: messagesReducer,
    debt: debtReducer,
    electricityDebt: electricityDebtReducer,
    waterDebt: waterDebtReducer,
    otherDebt: otherDebtReducer,
    utilitiesPrices: utilitiesPricesReducer,
    clients: clientsReducer,
    clientsFilter: clientsFilterReducer,
    clientsWithUnconfirmedInfo: clientsWithUnconfirmedInfoReducer,
    payments: paymentsReducer,
    orders: ordersReducer,
    taxes: taxesReducer,
    clientPaymentHistory: clientPaymentHistoryReducer,
    client: clientReducer,
    archive: archiveReducer,
    baseTaxes: baseTaxesReducer,
    latePayments: latePaymentsReducer,
    posts: postsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export const persistor = persistStore(store);
