import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllClientTaxesThisYear } from '../../api/taxesService';
import { round } from '../../helpers/numberHelpers';
import { getFormattedDateOnly } from '../../helpers/dateHelpers';

const initialState = {
  taxes: []
};

export const getTaxes = createAsyncThunk('taxes/getAll', async (id) => {
  return await getAllClientTaxesThisYear(id);
});

const taxesSlice = createSlice({
  name: 'taxes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTaxes.fulfilled, (state, action) => {
      state.taxes = action.payload.map((tax) => {
        tax.total = round(tax.total, 2);
        tax.date = getFormattedDateOnly(tax.date);
        return tax;
      });
    });
  }
});

export default taxesSlice.reducer;
