import React, { useState } from 'react';
import { CURRENT_YEAR } from '../../helpers/dateHelpers';
import AnnualTaxInput from './AnnualTaxInput';

const AnnualTaxes = ({ data, onSubmit }) => {
  const FIRST_YEAR = 2007;
  const years = Array.from({ length: CURRENT_YEAR - FIRST_YEAR + 1 }, (_, i) => FIRST_YEAR + i);

  const [newTax, setNewTax] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (value) => {
    setErrorMessage('');
    if (!value) {
      setErrorMessage('Laukas yra privalomas');
    }
    if (value < 0) {
      setErrorMessage('Mokestis negali būti neigiamas skaičius');
    }

    setNewTax(value);
  };

  if (!data.length) {
    return <></>;
  } else
    return (
      <div className="container mx-auto">
        {years.map((year) => {
          const element = data.find((x) => x.year === year);
          return (
            <AnnualTaxInput
              key={year}
              year={year}
              tax={element?.tax ?? 0}
              disabled={year !== CURRENT_YEAR}
              onChange={handleChange}
            />
          );
        })}
        {errorMessage && (
          <div className="flex flex-wrap mb-2">
            <div className="w-1/6"></div>
            <div className="w-auto px-4 flex-1">
              <label className="text-sm text-red-500">{errorMessage}</label>
            </div>
          </div>
        )}
        <div className="flex flex-wrap mb-2 mt-4">
          <div className="w-1/3 px-4 flex items-center justify-center">
            <button
              className={`hover:shadow-md text-white font-bold uppercase text-xs px-4 py-2 rounded shadow outline-none focus:outline-none mr-2 ${
                errorMessage ? 'bg-lightBlue-300 cursor-not-allowed' : 'bg-lightBlue-500 active:bg-lightBlue-600'
              }`}
              type="button"
              onClick={() => onSubmit(parseFloat(newTax))}
              disabled={errorMessage}
            >
              Saugoti
            </button>
          </div>
        </div>
      </div>
    );
};

export default AnnualTaxes;
