import React, { useState, useEffect } from 'react';
import Lightbox from 'react-18-image-lightbox';

const importAll = (r) => r.keys().map(r);

const PhotoGallery = () => {
  const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const openLightbox = (index) => {
    setSelectedImage(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
    setSelectedImage(0);
  };

  useEffect(() => {
    const importImages = () => {
      try {
        const context = require.context('/public/photoGallery', false, /\.(png|jpe?g|svg)$/);
        const images = importAll(context).slice(0, 10);

        setImages(images);
      } catch (error) {
        console.error("Directory '/public/photoGallery' does not exist.");
      }
    };

    importImages();
  }, []);

  return (
    <div className="gallery-container">
      {images.map((image, index) => (
        <div key={index} className="gallery-item" onClick={() => openLightbox(index)}>
          <img src={image} alt={`Gallery-${index}`} className="cursor-pointer shadow-xl" />
        </div>
      ))}

      {isOpen && (
        <Lightbox
          mainSrc={images[selectedImage]}
          nextSrc={images[(selectedImage + 1) % images.length]}
          prevSrc={images[(selectedImage + images.length - 1) % images.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() => setSelectedImage((selectedImage + images.length - 1) % images.length)}
          onMoveNextRequest={() => setSelectedImage((selectedImage + 1) % images.length)}
        />
      )}
    </div>
  );
};

export default PhotoGallery;
