import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { round } from '../../helpers/numberHelpers';
import { getFormattedDateOnly } from '../../helpers/dateHelpers';
import { getTaxesRange } from '../../api/taxesService';
import { getPaymentsRange } from '../../api/paymentsService';

const initialState = {
  history: [],
  currentPage: 1,
  pageSize: 10,
  totalPages: 0,
  isLoading: false
};

export const getPaymentHistory = createAsyncThunk('clientPaymentHistory/getPaymentHistory', async (id, thunkAPI) => {
  const currentState = thunkAPI.getState();
  const page = currentState.clientPaymentHistory.currentPage;
  const pageSize = currentState.clientPaymentHistory.pageSize;
  const skip = (page - 1) * pageSize;
  const clientDebtPerYear = [...currentState.debt.debtPerYear];

  const totalPages = Math.ceil(clientDebtPerYear.length / pageSize);

  clientDebtPerYear.sort((a, b) => a.year - b.year);
  const pagedResult = clientDebtPerYear.slice(skip, skip + pageSize);

  const from = pagedResult[0].year;
  const to = pagedResult[pagedResult.length - 1].year;
  const taxes = await getTaxesRange(id, from, to);
  const payments = await getPaymentsRange(id, from, to);

  return { pagedResult, totalPages, taxes, payments };
});

const clientPaymentHistorySlice = createSlice({
  name: 'clientPaymentHistory',
  initialState,
  reducers: {
    setPageSize: (state, { payload }) => {
      state.pageSize = payload.pageSize;
      state.currentPage = 1;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload.page;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPaymentHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.totalPages = action.payload.totalPages;
        const taxes = action.payload.taxes.map((tax) => {
          tax.date = getFormattedDateOnly(tax.date);
          tax.total = round(tax.total, 2);
          tax.description = tax.description || 'Skola';

          return tax;
        });

        const payments = action.payload.payments.map((payment) => {
          payment.date = getFormattedDateOnly(payment.date);
          payment.total = round(payment.total, 2);

          return payment;
        });

        state.history = action.payload.pagedResult.map((debt) => ({
          year: debt.year,
          debt: debt.total,
          total: debt.totalNoChange,
          taxes: taxes.filter((tax) => new Date(tax.date).getFullYear() === debt.year),
          payments: payments.filter((payment) => new Date(payment.date).getFullYear() === debt.year)
        }));
      })
      .addCase(getPaymentHistory.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const { setPageSize, setCurrentPage } = clientPaymentHistorySlice.actions;
export default clientPaymentHistorySlice.reducer;
