import React, { useCallback, useEffect, useState } from 'react';
import Modal from '../modal/Modal';
import ModalHeader from '../modal/ModalHeader';
import ModalBody from '../modal/ModalBody';
import ModalFooter from '../modal/ModalFooter';

const NewUtilityRecord = ({ latestReading, onSubmit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState(null);

  const initializeFormData = useCallback(() => {
    setFormData({
      reading: latestReading !== null ? latestReading + 1 : 0,
      isChanged: false
    });
  }, [latestReading]);

  useEffect(() => {
    initializeFormData();
  }, [initializeFormData]);

  const resetForm = () => {
    initializeFormData();
    setErrorMessage('');
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : parseInt(value);

    setErrorMessage('');
    if (name === 'reading') {
      if (latestReading !== null && !formData.isChanged && value <= latestReading) {
        setErrorMessage('Antras parodymas turi būti didensis nei pirmas');
      } else if (value < 0) {
        setErrorMessage('Parodymas turi būti ne mažesnis nei 0');
      }
    }

    setFormData({
      ...formData,
      [name]: newValue
    });
  };

  const handleSubmit = () => {
    onSubmit(formData);
    onClose();
  };

  const onClose = () => {
    setIsOpen(false);
    resetForm();
  };

  return (
    <>
      <button
        className="bg-blue-400 text-white hover:bg-blue-500 active:bg-blue-600 font-bold uppercase text-sm px-2 py-2 rounded outline-none focus:outline-none m-1"
        style={{ width: 'fit-content' }}
        type="button"
        onClick={() => setIsOpen(true)}
      >
        Naujas parodymas
      </button>
      {isOpen && (
        <Modal>
          <ModalHeader>
            {latestReading && !formData.isChanged ? 'Naujas skaitliuko parodymas' : 'Pradinis skaitliuko parodymas'}
          </ModalHeader>
          <ModalBody>
            <div className="container mx-auto">
              {latestReading !== null && !formData.isChanged && (
                <div className="flex flex-wrap mb-2">
                  <div className="w-1/3 px-4 flex items-center justify-end">
                    <label htmlFor="lastReading" className="text-gray-700 text-sm mr-2">
                      Pirmas parodymas
                    </label>
                  </div>
                  <div className="w-full px-4 flex-1">
                    <input
                      type="number"
                      id="lastReading"
                      name="lastReading"
                      defaultValue={latestReading}
                      disabled={true}
                      className="px-2 py-1 text-blueGray-600 relative bg-gray-200 rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                    />
                  </div>
                </div>
              )}
              <div className="flex flex-wrap mb-2">
                <div className="w-1/3 px-4 flex items-center justify-end">
                  <label htmlFor="reading" className="text-gray-700 text-sm mr-2">
                    {latestReading !== null && !formData.isChanged ? 'Antras parodymas' : 'Pirmas parodymas'}
                  </label>
                </div>
                <div className="w-full px-4 flex-1">
                  <input
                    type="number"
                    id="reading"
                    name="reading"
                    value={formData.reading}
                    onChange={(e) => handleChange(e)}
                    min={latestReading !== null && !formData.isChanged ? latestReading + 1 : 0}
                    className={`px-2 py-1 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full ${
                      errorMessage && 'border-red-500'
                    }`}
                  />
                </div>
              </div>
              {errorMessage && (
                <div className="flex flex-wrap mb-2">
                  <div className="w-1/3"></div>
                  <div className="w-full px-4 flex-1">
                    <label className="text-sm text-red-500">{errorMessage}</label>
                  </div>
                </div>
              )}
              {latestReading !== null && (
                <div className="flex flex-wrap mb-2">
                  <div className="w-1/3 px-4 flex items-center justify-end">
                    <label htmlFor="isChanged" className="text-gray-700 text-sm mr-2">
                      Keičiamas skaitliukas?
                    </label>
                  </div>
                  <div className="w-full px-4 flex-1 flex items-center justify-start">
                    <input
                      type="checkbox"
                      id="isChanged"
                      name="isChanged"
                      checked={formData.isChanged}
                      onChange={(e) => handleChange(e)}
                      className="rounded shadow outline-none focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="text-red-500 background-transparent font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
              type="button"
              onClick={onClose}
            >
              Uždaryti
            </button>
            <button
              className={`bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ${
                errorMessage && 'cursor-not-allowed'
              }`}
              type="button"
              onClick={handleSubmit}
              disabled={errorMessage}
            >
              Saugoti
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default NewUtilityRecord;
