import * as XLSX from 'xlsx';

export const exportData = (filename, data) => {
  const blob = createExcelFile(data);
  downloadFile(filename, blob);
};

const createExcelFile = (data) => {
  const ws = XLSX.utils.aoa_to_sheet(data);

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  return blob;
};

const downloadFile = (filename, blob) => {
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = url;
  a.download = filename;
  a.click();

  window.URL.revokeObjectURL(url);
};

export const normalizePhoneNumbers = (phoneNumber) => {
  return phoneNumber.replace(/ /g, '').replace(/\+370/g, '8');
};
