export const round = (num, decimalPlaces) => {
  var p = Math.pow(10, decimalPlaces);
  var n = num * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
};

const TEN = 10;
const ONE_HUNDRED = 100;
const ONE_THOUSAND = 1000;
const ONE_MILLION = 1000000;

const LESS_THAN_TWENTY = [
  'nulis',
  'vienas',
  'du',
  'trys',
  'keturi',
  'penki',
  'šeši',
  'septyni',
  'aštuoni',
  'devyni',
  'dešimt',
  'vienuolika',
  'dvylika',
  'trylika',
  'keturiolika',
  'penkiolika',
  'šešiolika',
  'septyniolika',
  'aštuoniolika',
  'devyniolika'
];

const TENTHS_LESS_THAN_HUNDRED = [
  'nulis',
  'dešimt',
  'dvidešimt',
  'trisdešimt',
  'keturiasdešimt',
  'penkiasdešimt',
  'šešiasdešimt',
  'septyniasdešimt',
  'aštuoniasdešimt',
  'devyniasdešimt'
];

const HUNDREDS = ['šimtas', 'šimtai'];
const THOUSANDS = ['tūkstantis', 'tūkstančiai'];

export const toWords = (number) => {
  var num = parseInt(number, 10);
  return generateWords(num);
};

const generateWords = (number, words) => {
  var remainder, word;

  if (number === 0) {
    return !words ? LESS_THAN_TWENTY[0] : words.join(' ');
  }

  if (!words) {
    words = [];
  }

  if (number < 0) {
    words.push('minus');
    number = Math.abs(number);
  }

  if (number < 20) {
    remainder = 0;
    word = LESS_THAN_TWENTY[number];
  } else if (number < ONE_HUNDRED) {
    remainder = number % TEN;
    word = TENTHS_LESS_THAN_HUNDRED[Math.floor(number / TEN)];
  } else if (number < ONE_THOUSAND) {
    remainder = number % ONE_HUNDRED;
    const hundreds = Math.floor(number / ONE_HUNDRED);
    word = `${generateWords(hundreds)} ${hundreds === 1 ? HUNDREDS[0] : HUNDREDS[1]}`;
  } else if (number < ONE_MILLION) {
    remainder = number % ONE_THOUSAND;
    const thousands = Math.floor(number / ONE_THOUSAND);
    word = `${generateWords(thousands)} ${thousands === 1 ? THOUSANDS[0] : THOUSANDS[1]}`;
  }

  words.push(word);
  return generateWords(remainder, words);
};
