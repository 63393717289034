import React, { useEffect, useState } from 'react';
import PageTab from '../../components/dashboard/PageTab';
import PageTabTitle from '../../components/dashboard/PageTabTitle';
import PageTabBody from '../../components/dashboard/PageTabBody';
import DocumentLink from '../../components/documents/DocumentLink';

const ActivitiesDocuments = () => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    try {
      const context = require.context('/public/activitiesDocuments/activities', false, /\.pdf$/);
      const docs = context.keys();

      setDocuments(docs);
    } catch (error) {
      console.error("Directory '/public/activitiesDocuments/activities' does not exist.");
    }
  }, []);

  return (
    <PageTab>
      <PageTabTitle>Veiklos ataskaitos</PageTabTitle>
      <PageTabBody>
        {documents.map((filename, index) => (
          <DocumentLink key={index} directory={'/activitiesDocuments/activities'} filename={filename} />
        ))}
      </PageTabBody>
    </PageTab>
  );
};

export default ActivitiesDocuments;
