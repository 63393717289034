import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LinkButton from '../dashboard/LinkButton';
import BodyCell from '../table/BodyCell';

const ClientsTableBody = ({ onEditClick, onDeleteClick }) => {
  const { clients } = useSelector((state) => state.clients);
  const navigate = useNavigate();

  return (
    <tbody>
      {clients.map((c) => {
        return (
          <tr
            key={c.id}
            className={`border-b ${
              !c.address || !c.phone || !c.email ? 'bg-red-200 border border-solid border-red-200' : ''
            }`}
          >
            <BodyCell customWrap={'whitespace-normal'}>{c.garage}</BodyCell>
            <BodyCell customWrap={'whitespace-normal'}>
              {c.owner}
              {c.memberSince && (
                <p className="flex justify-center text-[12px]">
                  Narys nuo:<span className="font-bold ml-1">{c.memberSince}</span>
                </p>
              )}
            </BodyCell>
            <BodyCell customWrap={'whitespace-normal'}>{c.address}</BodyCell>
            <BodyCell customWrap={'whitespace-normal'}>
              {c.phone}
              {c.email && (
                <p className="flex justify-center text-[12px]">
                  <span className="font-bold ml-1">{c.email}</span>
                </p>
              )}
            </BodyCell>
            <BodyCell>
              {`${c.squares} m`}
              <sup>2</sup>
            </BodyCell>
            <BodyCell>
              <p>
                <strong>{`Garažo: ${c.garageDebt < 0 ? 0 : c.garageDebt}€`}</strong>
              </p>
              {c.electricityDebt > 0 && (
                <p>
                  <strong>{`Elektros: ${c.electricityDebt}€`}</strong>
                </p>
              )}
              {c.waterDebt > 0 && (
                <p>
                  <strong>{`Vandens: ${c.waterDebt}€`}</strong>
                </p>
              )}
              {c.garageDebt < 0 && (
                <p>
                  <strong>{'Reikia sutikrinti elektros ir vandens mokėjimus'}</strong>
                </p>
              )}
            </BodyCell>
            <BodyCell customBackground={'bg-blueGray-200 border border-solid border-blueGray-400'}>
              <div>
                <LinkButton
                  additionalClasses={'text-lightBlue-500 hover:underline hover:text-black'}
                  onClick={() => navigate(`/dashboard/clients/${c.id}/debt-and-payments`)}
                >
                  Mokėjimų istorija
                </LinkButton>
              </div>
              <div>
                <LinkButton
                  additionalClasses={'text-lightBlue-500 hover:underline hover:text-black'}
                  onClick={() => navigate(`/dashboard/clients/${c.id}/electricity-records`)}
                >
                  Elektros apskaita
                </LinkButton>
              </div>
              {c.doesNotHaveMeter || (
                <div>
                  <LinkButton
                    additionalClasses={'text-lightBlue-500 hover:underline hover:text-black'}
                    onClick={() => navigate(`/dashboard/clients/${c.id}/water-records`)}
                  >
                    Vandens apskaita
                  </LinkButton>
                </div>
              )}
              <div>
                <LinkButton
                  additionalClasses={'text-lightBlue-500 hover:underline hover:text-black'}
                  onClick={() => onEditClick(c.id)}
                >
                  Redaguoti
                </LinkButton>
              </div>
              <div>
                <LinkButton
                  additionalClasses={'text-lightBlue-500 hover:underline hover:text-black'}
                  onClick={() => onDeleteClick({ id: c.id, garage: c.garage })}
                >
                  Trinti
                </LinkButton>
              </div>
            </BodyCell>
          </tr>
        );
      })}
    </tbody>
  );
};

export default ClientsTableBody;
