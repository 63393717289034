import React, { useEffect, useState } from 'react';
import PageTab from '../../components/dashboard/PageTab';
import PageTabTitle from '../../components/dashboard/PageTabTitle';
import PageTabBody from '../../components/dashboard/PageTabBody';
import { getFileContent, upsertFile } from '../../api/fileService';
import { EMAIL_SIGNATURE_FILE } from '../../consts/filenames';
import EmailSignature from '../../components/settings/EmailSignature';

const EmailSignatureSetting = () => {
  const [signature, setSignature] = useState('');

  useEffect(() => {
    const getSignature = async () => {
      return await getFileContent(EMAIL_SIGNATURE_FILE);
    };

    getSignature().then((signature) => {
      setSignature(signature);
    });
  }, []);

  const handleChange = (e) => {
    const text = e.target.value;
    setSignature(text);
  };

  const changeEmailSignature = async () => {
    await upsertFile(EMAIL_SIGNATURE_FILE, signature);
  };

  return (
    <PageTab>
      <PageTabTitle>Prierašas laiško apačioje</PageTabTitle>
      <PageTabBody>
        <EmailSignature signature={signature} handleChange={handleChange} handleSubmit={changeEmailSignature} />
      </PageTabBody>
    </PageTab>
  );
};

export default EmailSignatureSetting;
