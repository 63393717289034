import React from 'react';
import DebtTableHeader from './DebtTableHeader';
import DebtTableBody from './DebtTableBody';

const DebtTable = ({ addLateInterestToDebt }) => {
  return (
    <table className="items-center w-full bg-transparent border-collapse">
      <DebtTableHeader />
      <DebtTableBody addLateInterestToDebt={addLateInterestToDebt} />
    </table>
  );
};

export default DebtTable;
