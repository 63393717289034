import React from 'react';
import ClientsWithUnconfirmedInfo from '../../containers/dashboard/ClientsWithUnconfirmedInfo';

const ClientsWithUnconfirmedInfoPage = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:12 mb-12 xl:mb-0 px-4">
        <ClientsWithUnconfirmedInfo />
      </div>
    </div>
  );
};

export default ClientsWithUnconfirmedInfoPage;
