import React from 'react';
import ArchiveTableHeader from './ArchiveTableHeader';
import ArchiveTableBody from './ArchiveTableBody';

const ArchiveTable = () => {
  return (
    <table className="items-center w-full bg-transparent border-collapse">
      <ArchiveTableHeader />
      <ArchiveTableBody />
    </table>
  );
};

export default ArchiveTable;
