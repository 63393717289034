import React from 'react';
import { useSelector } from 'react-redux';
import BodyCell from '../table/BodyCell';

const TaxesTableBody = ({ handleDeleteClick }) => {
  const { taxes } = useSelector((state) => state.taxes);

  return (
    <tbody>
      {taxes.map((tax) => {
        return (
          <tr key={tax.id} className="odd:bg-white even:bg-gray-50 border-b">
            <BodyCell>{tax.date}</BodyCell>
            <BodyCell>{tax.information}</BodyCell>
            <BodyCell>{`${tax.total}€`}</BodyCell>
            <BodyCell>
              <img
                className="mx-auto w-7 hover:cursor-pointer"
                alt="Naikinti"
                src="/delete-image.png"
                onClick={() => handleDeleteClick(tax.id)}
              />
            </BodyCell>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TaxesTableBody;
