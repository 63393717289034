import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faMessage,
  faTimes,
  faUsers,
  faUser,
  faScaleBalanced,
  faFileLines,
  faHandshake,
  faMoneyCheck,
  faBoltLightning,
  faFaucetDrip,
  faBoxArchive,
  faGear,
  faClipboardList
} from '@fortawesome/free-solid-svg-icons';
import UserDropdown from '../../components/dashboard/UserDropdown';
import { useSelector } from 'react-redux';

const Sidebar = () => {
  const { isAdmin } = useSelector((state) => state.role);
  const [collapseShow, setCollapseShow] = useState('hidden');

  const adminPages = [
    {
      title: 'KLIENTAI',
      link: '/dashboard',
      icon: <FontAwesomeIcon icon={faUsers} />
    },
    {
      title: 'ARCHYVAS',
      link: '/dashboard/archive',
      icon: <FontAwesomeIcon icon={faBoxArchive} />
    },
    {
      title: 'PRANEŠIMAS',
      link: '/dashboard/message',
      icon: <FontAwesomeIcon icon={faMessage} />
    },
    {
      title: 'SKELBIMAI',
      link: '/dashboard/posts',
      icon: <FontAwesomeIcon icon={faClipboardList} />
    },
    {
      title: 'NUSTATYMAI',
      link: '/dashboard/settings',
      icon: <FontAwesomeIcon icon={faGear} />
    }
  ];

  const clientPages = [
    {
      title: 'PRADŽIA',
      link: '/dashboard',
      icon: <FontAwesomeIcon icon={faUsers} />
    },
    {
      title: 'PRANEŠIMAI',
      link: '/dashboard/messages',
      icon: <FontAwesomeIcon icon={faMessage} />
    },
    {
      title: 'MOKĖJIMŲ ISTORIJA',
      link: '/dashboard/payments',
      icon: <FontAwesomeIcon icon={faMoneyCheck} />
    },
    {
      title: 'PARODYMŲ DEKLARAVIMAS',
      link: '/dashboard/declarations',
      icon: (
        <>
          <FontAwesomeIcon icon={faBoltLightning} />
          <FontAwesomeIcon icon={faFaucetDrip} />
        </>
      )
    },
    { title: 'DUOMENŲ KEITIMAS', link: '/dashboard/profile', icon: <FontAwesomeIcon icon={faUser} /> },
    { title: 'TEISINĖ INFORMACIJA', link: '/dashboard/legal', icon: <FontAwesomeIcon icon={faScaleBalanced} /> },
    {
      title: 'VEIKLOS DOKUMENTAI',
      link: '/dashboard/activities',
      icon: <FontAwesomeIcon icon={faFileLines} />
    },
    {
      title: 'SUSIRINKIMAI',
      link: '/dashboard/meetings',
      icon: <FontAwesomeIcon icon={faHandshake} />
    }
  ];

  const displayPages = (pages) => {
    return pages.map((page, i) => {
      return (
        <li key={i} className="items-center">
          <Link
            className={
              'text-xs uppercase py-3 font-bold block ' +
              (window.location.pathname === page.link
                ? 'text-lightBlue-500 hover:text-lightBlue-600'
                : 'text-black hover:text-blueGray-500')
            }
            to={page.link}
          >
            <div className="flex flex-row">
              <div className="flex justify-center self-center w-6">{page.icon}</div>
              <span className="ml-2">{page.title}</span>
            </div>
          </Link>
        </li>
      );
    });
  };

  return (
    <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
      <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
        {/* Toggler */}
        <button
          className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
          type="button"
          onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>

        {/* Brand */}
        <Link
          className="flex justify-center md:pb-2 text-blueGray-600 mr-0 whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
          to="/"
        >
          <img className="h-10 max-w-full" src="/eismas.png" alt="EISMAS" />
        </Link>

        {/* User */}
        <ul className="md:hidden items-center flex flex-wrap list-none">
          <li className="inline-block relative">
            <UserDropdown />
          </li>
        </ul>

        {/* Collapse */}
        <div
          className={
            'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
            collapseShow
          }
        >
          {/* Collapse header */}
          <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
            <div className="flex flex-wrap">
              <div className="w-6/12">
                <Link
                  className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                  to="/"
                >
                  <img className="h-10 max-w-full" src="/eismas.png" alt="EISMAS" />
                </Link>
              </div>
              <div className="w-6/12 flex justify-end">
                <button
                  type="button"
                  className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                  onClick={() => setCollapseShow('hidden')}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            </div>
          </div>

          {/* Form */}
          <form className="mt-6 mb-4 md:hidden">
            <div className="mb-3 pt-0">
              <input
                type="text"
                placeholder="Search"
                className="px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
              />
            </div>
          </form>

          {/* Divider */}
          <hr className="my-4 md:min-w-full" />

          {/* Navigation */}
          <ul className="md:flex-col md:min-w-full flex flex-col list-none">
            {isAdmin === true ? displayPages(adminPages) : displayPages(clientPages)}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
